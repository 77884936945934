import React from "react"
import { Modal as BSModal, Button } from "react-bootstrap"
import { useIntl } from "react-intl"

type OwnProps = {
  isDisplayed: boolean
  onClose: () => void
  title?: string
  body?: string
}

const Modal: React.FC<OwnProps> = ({ isDisplayed, onClose, title, body }) => {
  const intl = useIntl()

  return (
    <BSModal show={isDisplayed} onHide={onClose}>
      {title && (
        <BSModal.Header>
          <BSModal.Title>{intl.formatMessage({ id: title })}</BSModal.Title>
        </BSModal.Header>
      )}

      {body && (
        <BSModal.Body>{<p>{intl.formatMessage({ id: body })}</p>}</BSModal.Body>
      )}

      <BSModal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {intl.formatMessage({ id: "close-button" })}
        </Button>
      </BSModal.Footer>
    </BSModal>
  )
}

export default Modal
