import { reducerWithInitialState } from "typescript-fsa-reducers"
import {
  clearCertsAction,
  fetchCertAction,
  fetchCertsAction,
  fetchCertsHistoryAction,
  fetchHistoryCountAction
} from "./actions"
import { Cert, Certs, Histories, CertsData, HistoriesData, CountTypes } from "./types"

export type CertsState = {
  isLoaded: boolean
  cert: Cert | null
  data: CertsData
} & {
  history: HistoriesState
} & {
  historyCounts: HistoryCountState
}

type HistoriesState = {
  prevHistories: Histories
} & (
  | {
      isLoaded: false
      prevHistories: Histories
    }
  | {
      isLoaded: true
      data: HistoriesData
    }
)

type HistoryCountState = {
  isLoaded: boolean
  counts: CountTypes
}

const initialState = {
  isLoaded: false,
  cert: null,
  data: {
    certs: [] as Certs,
    count: 0
  },
  history: {
    isLoaded: false,
    prevHistories: [],
    count: 0
  },
  historyCounts: {
    isLoaded: false,
    counts: {
      allHistoriesCounts: {},
      thisMonthCount: {},
      lastWeekCount: {},
      lastSixMonthCount: {}
    }
  } 
} as CertsState

const fetchCertStarted = (
  state: CertsState,
  action: ReturnType<typeof fetchCertAction.started>
): CertsState => {
  return {
    ...state,
    isLoaded: false
  }
}

const fetchCertDone = (
  state: CertsState,
  action: ReturnType<typeof fetchCertAction.done>
): CertsState => {
  return {
    ...state,
    isLoaded: true,
    cert: action.payload.result
  }
}

const fetchCertsStarted = (
  state: CertsState,
  action: ReturnType<typeof fetchCertsAction.started>
): CertsState => {
  return {
    ...state,
    isLoaded: false
  }
}

const fetchCertsDone = (
  state: CertsState,
  action: ReturnType<typeof fetchCertsAction.done>
): CertsState => {
  return {
    ...state,
    isLoaded: true,
    data: action.payload.result
  }
}

const fetchCertsHistoryStarted = (
  state: CertsState,
  action: ReturnType<typeof fetchCertsHistoryAction.started>
): CertsState => {
  return {
    ...state,
    history: {
      ...state.history,
      isLoaded: false
    }
  }
}

const fetchCertsHistoryDone = (
  state: CertsState,
  action: ReturnType<typeof fetchCertsHistoryAction.done>
): CertsState => {
  return {
    ...state,
    history: {
      ...state.history,
      isLoaded: true,
      prevHistories: action.payload.result.histories,
      data: action.payload.result
    }
  }
}

const clearCerts = (
  state: CertsState,
  action: ReturnType<typeof clearCertsAction>
): CertsState => {
  return {
    ...state,
    isLoaded: false,
    data: {
      certs: [] as Certs,
      count: 0
    }
  }
}

const fetchHistoryCountStarted = (
  state: CertsState,
  action: ReturnType<typeof fetchHistoryCountAction.started>
): CertsState => {
  return {
    ...state,
    historyCounts: {
      isLoaded: false,
      counts: {
        allHistoriesCounts: {},
        thisMonthCount: {},
        lastWeekCount: {},
        lastSixMonthCount: {}
      }
    }
  }
}

const fetchHistoryCountDone = (
  state: CertsState,
  action: ReturnType<typeof fetchHistoryCountAction.done>
): CertsState => {
  return {
    ...state,
    historyCounts: {
      isLoaded: true,
      counts: action.payload.result
    }
  }
}

const reducer = reducerWithInitialState(initialState)
  .caseWithAction(fetchCertAction.started, fetchCertStarted)
  .caseWithAction(fetchCertAction.done, fetchCertDone)
  .caseWithAction(fetchCertsAction.started, fetchCertsStarted)
  .caseWithAction(fetchCertsAction.done, fetchCertsDone)
  .caseWithAction(fetchCertsHistoryAction.started, fetchCertsHistoryStarted)
  .caseWithAction(fetchCertsHistoryAction.done, fetchCertsHistoryDone)
  .caseWithAction(clearCertsAction, clearCerts)
  .caseWithAction(fetchHistoryCountAction.started, fetchHistoryCountStarted)
  .caseWithAction(fetchHistoryCountAction.done, fetchHistoryCountDone)

export default reducer
