/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react"
import { css } from "@emotion/react"
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, RouteComponentProps } from "react-router-dom"
import { State } from "../state/store"
import {
  deleteApiKeyAction,
  fetchApiKeyAction,
  fetchWebhookAction,
  issueApiKeyAction,
  registerWebhookAction,
  updateWebhookAction,
  sendPingAction
} from "../state/ducks/webhooks/actions"
import Spinner from "../components/Spinner"
import { useIntl } from "react-intl"
import { appSelector, isLoadedSelector } from "../state/ducks/apps/selectors"
import { fetchAppsOps } from "../state/ducks/apps/operations"
import PrevLink from "../components/PrevLink"

const DetailAppPage: React.FC<RouteComponentProps<{ appCode: string }>> = ({
  match,
  history
}) => {
  const appCode = match.params.appCode
  const isLoaded = useSelector(isLoadedSelector)
  const app = useSelector(appSelector(appCode))
  const dispatch = useDispatch()
  const intl = useIntl()
  const token = useSelector((state: State) => state.authReducer.token)
  const webhookState = useSelector((state: State) => state.webhookReducer)
  const [issued, setIssued] = useState<boolean>(false)
  const [transferred, setTransferred] = useState<boolean>(false)
  const [url, setUrl] = useState<string>("")
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    dispatch(fetchAppsOps({}))
    dispatch(fetchApiKeyAction.started(appCode))
    dispatch(fetchWebhookAction.started(appCode))

    // TODO: data structure
    // eslint-disable-next-line
  }, [dispatch, appCode, token])

  useEffect(() => {
    if (webhookState.isLoaded && webhookState.webhook) {
      setIssued(webhookState.webhook.eventList.issued)
      setTransferred(webhookState.webhook.eventList.transferred)
      setUrl(webhookState.webhook.url)
    }

    // eslint-disable-next-line
  }, [webhookState.isLoaded && webhookState.webhook])

  const submit = () => {
    if (webhookState.isLoaded && webhookState.webhook.secretToken) {
      dispatch(
        updateWebhookAction.started({
          appCode,
          url,
          eventList: [
            issued ? "cert.issued" : "",
            transferred ? "cert.transferred" : ""
          ]
        })
      )
      return
    }
    dispatch(
      registerWebhookAction.started({
        appCode,
        url,
        eventList: [
          issued ? "cert.issued" : "",
          transferred ? "cert.transferred" : ""
        ]
      })
    )
  }

  const sendPing = () => {
    dispatch(sendPingAction.started(appCode))
  }

  if (!isLoaded) {
    return <Spinner />
  }

  if (!app) {
    return <h1>{intl.formatMessage({ id: "app-notfound" })}</h1>
  }

  if (!webhookState.isLoaded || webhookState.webhook == null) {
    return <Spinner />
  }

  return (
    <React.Fragment>
      <PrevLink to={`/apps/${appCode}`} label={app.appName} />
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "detail-app.title" })}: {app.appCode}
          </h2>
        </Col>
        <Col>
          <Link to={`/apps/${app.appCode}/assets`} className="float-right">
            <Button className="btn-info">
              {intl.formatMessage({ id: "detail-app.asset-list" })}
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header>
              <span>{intl.formatMessage({ id: "detail-app.api-key" })}</span>
            </Card.Header>
            <Card.Body>
              {webhookState.apiKey && (
                <Row>
                  <Col>
                    <span>{webhookState.isLoaded && webhookState.apiKey}</span>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        dispatch(issueApiKeyAction.started(appCode))
                      }}>
                      {intl.formatMessage({ id: "detail-app.re-issue" })}
                    </Button>
                    <Button
                      variant="danger"
                      className="ml-3"
                      onClick={() => setIsOpen(true)}>
                      {intl.formatMessage({ id: "delete-button" })}
                    </Button>
                  </Col>
                </Row>
              )}
              {!webhookState.apiKey && (
                <Row>
                  <Col>
                    <Button
                      onClick={() => {
                        dispatch(issueApiKeyAction.started(appCode))
                      }}>
                      {intl.formatMessage({ id: "detail-app.issue" })}
                    </Button>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <React.Fragment>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <span>Webhook</span>
              </Card.Header>
              <Card.Body>
                <Row className="my-2">
                  <Col sm={2}>
                    <span>
                      {intl.formatMessage({ id: "detail-app.event" })}
                    </span>
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      type="checkbox"
                      id="cert issue"
                      label={intl.formatMessage({
                        id: "detail-app.issue-cert"
                      })}
                      checked={issued}
                      onChange={(e: any) => setIssued(e.target.checked)}
                    />
                  </Col>
                  <Col sm={3}>
                    <Form.Check
                      type="checkbox"
                      id="cert transfer"
                      label={intl.formatMessage({
                        id: "detail-app.transfer-cert"
                      })}
                      checked={transferred}
                      onChange={(e: any) => setTransferred(e.target.checked)}
                    />
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col sm={2}>
                    <span>
                      {intl.formatMessage({ id: "detail-app.callback-url" })}
                    </span>
                  </Col>
                  <Col sm={{ span: 5 }}>
                    <Form.Control
                      type="text"
                      value={url}
                      placeholder={intl.formatMessage({
                        id: "detail-app.webhook-placeholder"
                      })}
                      onChange={(e: any) => setUrl(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row
                  className="my-2"
                  css={css`
                    height: 33.5px;
                  `}>
                  <Col sm={2}>
                    <span>
                      {intl.formatMessage({ id: "detail-app.secret-token" })}
                    </span>
                  </Col>
                  <Col>
                    <span>{webhookState.webhook.secretToken}</span>
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ offset: 2 }}>
                    <Button variant="outline-secondary" onClick={submit}>
                      {intl.formatMessage({ id: "detail-app.store-button" })}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Button onClick={sendPing}>
                  {intl.formatMessage({ id: "detail-app.test-submit" })}
                </Button>
                <Link
                  to={`/apps/${app.appCode}/events`}
                  className="float-right">
                  <Button className="btn-info">
                    {intl.formatMessage({ id: "detail-app.event-list" })}
                  </Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
      <Button
        className="my-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}>
        {intl.formatMessage({ id: "back-button" })}
      </Button>
      <Modal show={isOpen} onHide={() => setIsOpen(false)}>
        <Modal.Body>
          {intl.formatMessage({ id: "detail-app.delete-api-key" })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            {intl.formatMessage({ id: "detail-app.cancel" })}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              dispatch(deleteApiKeyAction.started(appCode))
              setIsOpen(false)
            }}>
            {intl.formatMessage({ id: "delete-button" })}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default DetailAppPage
