import { reducerWithInitialState } from "typescript-fsa-reducers"
import { fetchImagesAction, deleteImageAction } from "./actions"
import { Images } from "./types"

export type ImagesState = {
  isLoaded: boolean
  images: Images
}

const initialState = {
  isLoaded: false,
  images: [] as Images
}

const fetchImagesStarted = (
  state: ImagesState,
  action: ReturnType<typeof fetchImagesAction.started>
): ImagesState => {
  return {
    ...state,
    isLoaded: false
  }
}

const fetchImagesDone = (
  state: ImagesState,
  action: ReturnType<typeof fetchImagesAction.done>
): ImagesState => {
  return {
    ...state,
    isLoaded: true,
    images: action.payload.result
  }
}

const deleteImageDone = (
  state: ImagesState,
  action: ReturnType<typeof deleteImageAction.done>
): ImagesState => {
  return {
    ...state,
    isLoaded: true,
    images: action.payload.result
  }
}

const reducer = reducerWithInitialState(initialState)
  .caseWithAction(fetchImagesAction.started, fetchImagesStarted)
  .caseWithAction(fetchImagesAction.done, fetchImagesDone)
  .caseWithAction(deleteImageAction.done, deleteImageDone)

export default reducer
