import {
  RegisterWebhookParams,
  deleteApiKeyAction,
  fetchApiKeyAction,
  fetchWebhookAction,
  issueApiKeyAction,
  registerWebhookAction,
  sendPingAction,
  UpdateWebhookParams,
  updateWebhookAction
} from "./actions"
import { call, put, select, takeEvery } from "redux-saga/effects"
import { Action } from "typescript-fsa"
import {
  deleteApiKeyUsecase,
  fetchApiKeyUsecase,
  fetchWebhookUsecase,
  issueApiKeyUsecase,
  registerWebhookUsecase,
  sendPingUsecase,
  updateWebhookUsecase
} from "./usecases"
import { displayToastAction } from "../notifications/actions"
import { invalidTokenAction } from "../error/actions"

function* fetchApiKeySaga(action: Action<string>) {
  try {
    const token: string = yield select(state => state.authReducer.token)

    const apiKey = yield call(fetchApiKeyUsecase, token, action.payload)

    yield put(
      fetchApiKeyAction.done({
        params: action.payload,
        result: apiKey
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(fetchApiKeyAction.failed(e))
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

function* issueApiKeySaga(action: Action<string>) {
  try {
    const token: string = yield select(state => state.authReducer.token)

    const apiKey = yield call(issueApiKeyUsecase, token, action.payload)

    yield put(
      issueApiKeyAction.done({
        params: action.payload,
        result: apiKey
      })
    )
    yield put(
      displayToastAction({
        type: "success",
        code: "issued"
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(issueApiKeyAction.failed(e))
  }
}

function* deleteApiKeySaga(action: Action<string>) {
  try {
    const token: string = yield select(state => state.authReducer.token)

    yield call(deleteApiKeyUsecase, token, action.payload)

    yield put(
      deleteApiKeyAction.done({
        params: action.payload
      })
    )
    yield put(
      displayToastAction({
        type: "error",
        code: "deleted"
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(deleteApiKeyAction.failed(e))
  }
}

function* fetchWebhookSaga(action: Action<string>) {
  try {
    const token: string = yield select(state => state.authReducer.token)

    const webhook = yield call(fetchWebhookUsecase, token, action.payload)

    yield put(
      fetchWebhookAction.done({
        params: action.payload,
        result: webhook
      })
    )
  } catch (e: any) {
    yield put(fetchWebhookAction.failed(e))
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

function* registerWebhookSaga(action: Action<RegisterWebhookParams>) {
  const token: string = yield select(state => state.authReducer.token)
  const { appCode, url, eventList } = action.payload

  try {
    yield call(registerWebhookUsecase, token, appCode, url, eventList)
    yield put(
      registerWebhookAction.done({
        params: action.payload
      })
    )
    yield put(fetchWebhookAction.started(appCode))
    yield put(
      displayToastAction({
        type: "success",
        code: "saved"
      })
    )
  } catch (e) {
    yield put(
      displayToastAction({
        type: "error",
        code: "save-failed"
      })
    )
  }
}

function* updateWebhookSaga(action: Action<UpdateWebhookParams>) {
  const token: string = yield select(state => state.authReducer.token)
  const { appCode, url, eventList } = action.payload

  try {
    yield call(updateWebhookUsecase, token, appCode, url, eventList)
    yield put(
      displayToastAction({
        type: "success",
        code: "saved"
      })
    )
  } catch (e) {
    yield put(
      displayToastAction({
        type: "error",
        code: "save-failed"
      })
    )
  }
}

function* sendPingSaga(action: Action<string>) {
  const token: string = yield select(state => state.authReducer.token)

  try {
    yield call(sendPingUsecase, token, action.payload)
    yield put(
      displayToastAction({
        type: "success",
        code: "saved"
      })
    )
  } catch (e) {
    yield put(
      displayToastAction({
        type: "error",
        code: "save-failed"
      })
    )
  }
}

const sagas = [
  takeEvery(fetchApiKeyAction.started, fetchApiKeySaga),
  takeEvery(issueApiKeyAction.started, issueApiKeySaga),
  takeEvery(deleteApiKeyAction.started, deleteApiKeySaga),
  takeEvery(fetchWebhookAction.started, fetchWebhookSaga),
  takeEvery(registerWebhookAction.started, registerWebhookSaga),
  takeEvery(updateWebhookAction.started, updateWebhookSaga),
  takeEvery(sendPingAction.started, sendPingSaga)
]

export default sagas
