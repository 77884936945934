import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { RouteComponentProps } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import AppTable from "../components/AppTable"
import { fetchAppsOps } from "../state/ducks/apps/operations"
import { appsSelector, isLoadedSelector } from "../state/ducks/apps/selectors"
import { LoadFunction } from "../types"

const ListAppPage: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const isLoaded = useSelector(isLoadedSelector)
  const data = useSelector(appsSelector)

  useEffect(() => {
    dispatch(fetchAppsOps({pageNum: 10}))
  }, [dispatch])

  const handleLoad: LoadFunction = params => dispatch(fetchAppsOps(params))

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "list-app.title" })}
          </h2>
        </Col>
      </Row>
      <AppTable
        apps={data.apps}
        count={data.count}
        isLoaded={isLoaded}
        handleLoad={handleLoad}
      />
    </>
  )
}

export default ListAppPage
