import { call, put, takeEvery, select } from "redux-saga/effects"
import { User } from "./types"
import { Action } from "typescript-fsa"
import {
  changePasswordAction,
  CreateManagerParams,
  createManagerAction,
  UpdateManagerParams,
  updateManagerAction,
  setUserAction
} from "./actions"
import { changePasswordUsecase, createManagerUsecase, updateManagerUsecase } from "./usecase"
import { displayModalAction } from "../notifications/actions"

function* changePasswordSaga(action: Action<User>) {
  try {
    yield call(changePasswordUsecase, action.payload.email)

    yield put(
      displayModalAction({
        title: "password-change-email-sent"
      })
    )
  } catch (e) {
    yield put(
      displayModalAction({
        title: e.description
      })
    )
  }
}

function* createManagerSaga(action: Action<CreateManagerParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    yield call(createManagerUsecase, token, action.payload)

    yield put(
      createManagerAction.done({
        params: action.payload
      })
    )

    yield put(
      displayModalAction({
        title: "registration-completed"
      })
    )
    // TODO: Change to use push
    //yield put(push("/"))
    window.location.href = "/"
  } catch (e) {
    yield put(createManagerAction.failed(e))
    yield put(
      displayModalAction({
        title: "registration-failed"
      })
    )
  }
}

function* updateManagerSaga(action: Action<UpdateManagerParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)

    const { user, lang } = action.payload

    const data = {
      auth0Id: user.auth0Id,
      lang
    }
    yield call(updateManagerUsecase, token, data)

    yield put(
      updateManagerAction.done({
        params: action.payload
      })
    )

    yield put(
      displayModalAction({
        title: "update-completed"
      })
    )

    yield put(
      setUserAction({
        ...user,
        lang
      })
    )
  } catch (e) {
    yield put(updateManagerAction.failed(e))
    yield put(
      displayModalAction({
        title: "update-failed"
      })
    )
  }
}

const sagas = [
  takeEvery(changePasswordAction, changePasswordSaga),
  takeEvery(createManagerAction.started, createManagerSaga),
  takeEvery(updateManagerAction.started, updateManagerSaga)
]

export default sagas
