import axios from "axios"
import { QueryParams } from "./types"
import { FetchCertsHistoryParams } from "./state/ducks/certs/actions"
import moment from "moment"

type AxiosResponseType = "json" | "arraybuffer" | "blob" | "document" | "text" | "stream" | undefined

export const axiosWithToken = (token: string, responseType?: AxiosResponseType) =>
  axios.create({
    url: "/",
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    responseType: responseType || "json"
  })

export const toJST = (iso: string) => {
  const date = new Date(iso)
  date.setTime(date.getTime())
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const monthStr = month < 10 ? "0" + month : month
  const day = date.getDate()
  const dayStr = day < 10 ? "0" + day : day
  const h = date.getHours()
  const hStr = h < 10 ? "0" + h : h
  const i = date.getMinutes()
  const iStr = i < 10 ? "0" + i : i
  const jst = year + "/" + monthStr + "/" + dayStr + " " + hStr + ":" + iStr
  return jst
}

export const convertQueryParams = (params: QueryParams) => {
  const { pageNum, page, item, order } = params
  let queryParams = `pageNum=${pageNum ?? 100}&p=${page ?? 0}`
  if(item) {
    queryParams += "&item=" + (item === "registeredDate" ? "id" : item)
    if(order) queryParams += "&order=" + order
  }

  return queryParams
}

export const convertFilterParams = (params: FetchCertsHistoryParams) => {
  const { from, to, type, appCode, assetCode, group } = params

  const appCodeParam = `appCode=${appCode || "*"}`
  const fromParam = from ? `&from=${fmtDate(from)}` : ""
  const toParam = to ? `&to=${fmtDate(to)}` : ""
  const typeParam = type ? `&type=${type}` : ""
  const assetCodeParam = assetCode ? `&assetCode=${assetCode}` : ""
  const groupParam = group ? `&group=${group}` : ""
  
  const historyParams = appCodeParam + fromParam + toParam + typeParam + assetCodeParam + groupParam

  return historyParams
}

export const fmtDate = (date: Date) => moment(date).format("YYYY-MM-DD")

export const getMonthRange = (d: Date) => {
  const date = moment(d)
  const days = date.date()
  const from = date.subtract(days - 1 , "days").toDate()
  const to = moment(d).add(1, "days").toDate()
  return {from, to}
}

export const getLastWeekRange = (d: Date) => {
  const date = moment(d)
  const from = date.subtract(6, "days").toDate()
  const to = moment(d).add(1, "days").toDate()
  return {from, to}
}

export const getLastSixMonthRange = (d: Date) => {
  const date = moment(d)
  const f = date.subtract(5, "months")
  const from = f.subtract(f.date() - 1 , "days").toDate()
  const to = moment(d).add(1, "days").toDate()
  return {from, to}
}

export const getMonthRangeStr = (d: Date) => {
  const date = moment(d)
  const days = date.date()
  const from = date.subtract(days - 1 , "days").toDate()
  const to = moment(d).toDate()
  return {from: fmtDate(from), to: fmtDate(to) }
}

export const marshalQueryParams = (qs: string) => {
  const params: {[key: string]: string | Date | null} = {from: null, to: null}
  if(!qs) return params as unknown as FetchCertsHistoryParams

  const queryString = qs.split("?")[1]
  const queryParams = queryString.split("&")

  queryParams.forEach(p => {
    const keyValue = p.split("=")
    params[keyValue[0]] = ["from", "to"].includes(keyValue[0]) ? new Date(keyValue[1]) : keyValue[1]
  })

  return params as unknown as FetchCertsHistoryParams
}

// DNP京都企画第二弾かどうかをチェック
export const isDnpKyoto2 = (vendorId: string, appCode: string) => {
  return (((vendorId === "ial_dnp") &&
    ((appCode === "ial_dnp_app_GGTuM3FG") || (appCode === "ial_dnp_app_KhTx9mtj"))) ||
    ((vendorId === "es_dnp") &&
    ((appCode === "kyoto2") || (appCode === "kyoto21"))))
}

// 小数点を返す
export const checkDecimalLength = (value: number) => {
  // 文字列に一度直し、誤差をなくす
  const d = String(value).split(".")[1]
  return d ? d.length : 0
}