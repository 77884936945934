import { VendorState } from "./../vendor/reducer"
import { fetchAssetUsecase } from "./../assets/usecase"
import { call, put, select, takeEvery } from "redux-saga/effects"
import { Cert, CertsData, HistoriesData, HistoryFilter, CountTypes } from "./types"
import {
  addCertUsecase,
  fetchCertUsecase,
  fetchCertsUsecase,
  transferCertUsecase,
  fetchCertsHistoryUsecase,
  updateSealUsecase,
  downloadCSVUsecase,
  fetchHistoryCountUsecase
} from "./usecase"
import { Action } from "typescript-fsa"
import {
  AddCertParams,
  FetchCertParams,
  FetchCertsParams,
  TransferCertParams,
  addCertAction,
  fetchCertAction,
  fetchCertsAction,
  transferCertAction,
  FetchCertsHistoryParams,
  fetchCertsHistoryAction,
  UpdateSealParams,
  updateSealAction,
  downloadCSVAction,
  fetchHistoryCountAction,
  FetchHistoryCountParam
} from "./actions"
import { Asset } from "../assets/types"
import { push } from "connected-react-router"
import {
  displayToastAction,
  setProcessingAction
} from "../notifications/actions"
import { fmtDate } from "../../../util"
import { invalidTokenAction } from "../error/actions"

function* fetchCertSaga(action: Action<FetchCertParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)

    const cert: Cert = yield call(fetchCertUsecase, token, action.payload)

    yield put(
      fetchCertAction.done({
        params: action.payload,
        result: cert
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(fetchCertsAction.failed(e))
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

function* fetchCertsSaga(action: Action<FetchCertsParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)

    const { appCode, assetCode, params } = action.payload
    const data: CertsData = yield call(
      fetchCertsUsecase,
      token,
      appCode,
      assetCode,
      params
    )

    const { certs } = data

    yield put(
      fetchCertsAction.done({
        params: action.payload,
        result: {
          certs,
          count: data.count
        }
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(fetchCertsAction.failed(e))
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

function* fetchCertsHistorySaga(action: Action<FetchCertsHistoryParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)

    const result: HistoriesData = yield call(
      fetchCertsHistoryUsecase,
      token,
      action.payload
    )

    yield put(
      fetchCertsHistoryAction.done({
        params: action.payload,
        result
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(fetchCertsAction.failed(e))
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

/**
 * 証明書の追加
 * @param action アクション
 */
function* addCertSaga(action: Action<AddCertParams>) {
  try {
    yield put(setProcessingAction(true))
    const token: string = yield select(state => state.authReducer.token)
    const vendorState: VendorState = yield select(state => state.vendorReducer)

    const vendorName = vendorState.isLoaded ? vendorState.vendor.vendorName : ""

    const {
      vendorId,
      appCode,
      assetCode,
      assetSeq,
      userId,
      sealId,
      price,
      currency,
      info1,
      info2,
      info3,
      info4,
      seqFlag,
      priceFlag,
      sealFlag
    } = action.payload
    const asset: Asset = yield call(
      fetchAssetUsecase,
      token,
      appCode,
      assetCode
    )

    const data = {
      ...asset,
      userId,
      sealId,
      vendorName,
      assetSeq,
      price,
      currency,
      info1,
      info2,
      info3,
      info4,
      seqFlag,
      priceFlag,
      sealFlag
    }
//    console.log(data)

    // 証明書追加
    const cert: Cert = yield call(addCertUsecase, token, vendorId, appCode, data)
    yield put(
      addCertAction.done({
        params: action.payload,
        result: cert
      })
    )
    yield put(push(`/apps/${appCode}/assets/${assetCode}`))
    yield put(
      displayToastAction({
        type: "success",
        code: "issued"
      })
    )
  } catch (e) {
    // TODO: error handling
    //         code: "api-error.sealToUser",
    console.log(e)

    let errorCode = "api-error.sealToUser"
    if (e.response.data.resource !== "sealToUser") {
      errorCode = `api-error.${e.response.data.code}` +
      `${e.response.data.subCode ? "_" + e.response.data.subCode : ""}`
    }

    yield put(addCertAction.failed(e))
    yield put(
      displayToastAction({
        type: "error",
        code:
          errorCode,
        param: e.response.data.param,
        resource: e.response.data.resource
      })
    )
  } finally {
    yield put(setProcessingAction(false))
  }
}

function* transferCertSaga(action: Action<TransferCertParams>) {
  try {
    yield put(setProcessingAction(true))
    const token: string = yield select(state => state.authReducer.token)

    const {
      appCode,
      assetCode,
      certId,
      oldOwnerId,
      newOwnerId,
      price,
      currency,
      priceFlag
    } = action.payload
    const data = {
      oldOwnerId,
      newOwnerId,
      price,
      currency,
      priceFlag
    }

    yield call(transferCertUsecase, token, appCode, certId, data)
    yield put(push(`/apps/${appCode}/assets/${assetCode}/certs/${certId}`))
    yield put(
      displayToastAction({
        type: "success",
        code: "transferred"
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(transferCertAction.failed(e))
    yield put(
      displayToastAction({
        type: "error",
        code:
          `api-error.${e.response.data.code}` +
          `${e.response.data.subCode ? "_" + e.response.data.subCode : ""}`,
        param: e.response.data.param,
        resource: e.response.data.resource
      })
    )
  }
}

function* updateSealSaga(action: Action<UpdateSealParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)

    const { sealId, appCode, assetCode, certId } = action.payload

    yield call(updateSealUsecase, token, appCode, certId, sealId)
    yield put(push(`/apps/${appCode}/assets/${assetCode}/certs/${certId}`))
    yield put(
      displayToastAction({
        type: "success",
        code: "updated"
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(updateSealAction.failed(e))
    yield put(
      displayToastAction({
        type: "error",
        code:
          `api-error.${e.response.data.code}` +
          `${e.response.data.subCode ? "_" + e.response.data.subCode : ""}`,
        param: e.response.data.param,
        resource: e.response.data.resource
      })
    )
  } finally {
    yield put(setProcessingAction(false))
  }
}

function* downloadCSVSaga(action: Action<HistoryFilter>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    const csvBuf: Buffer = yield call(downloadCSVUsecase, token, action.payload)

    const date = fmtDate(new Date())
    const url = URL.createObjectURL(new Blob([csvBuf]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `${date}.csv`) 
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  } catch (e) {
    // TODO: error handling
    yield put(downloadCSVAction.failed(e))
  }
}

function* fetchHistoryCountsSaga(action: Action<FetchHistoryCountParam>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    const historyCount: CountTypes = yield call(fetchHistoryCountUsecase, token, action.payload.appCode)
    yield put(fetchHistoryCountAction.done({
      params: action.payload,
      result: historyCount
    }))

  } catch(e) {
    yield put(fetchHistoryCountAction.failed(e))
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

const sagas = [
  takeEvery(fetchCertAction.started, fetchCertSaga),
  takeEvery(fetchCertsAction.started, fetchCertsSaga),
  takeEvery(addCertAction.started, addCertSaga),
  takeEvery(transferCertAction.started, transferCertSaga),
  takeEvery(fetchCertsHistoryAction.started, fetchCertsHistorySaga),
  takeEvery(updateSealAction.started, updateSealSaga),
  takeEvery(downloadCSVAction.started, downloadCSVSaga),
  takeEvery(fetchHistoryCountAction.started, fetchHistoryCountsSaga)
]

export default sagas
