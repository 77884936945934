import { actionCreatorFactory } from "typescript-fsa"
import { Images } from "./types"

export type FetchImagesParams = {
  appCode: string
  assetCode: string
}

export type AddImageParams = {
  appCode: string
  assetCode: string
  imageIndex: string
  file: Blob
}

export type DeleteImageParams = {
  appCode: string
  assetCode: string
  imageIndex: string
}

const actionCreator = actionCreatorFactory()

export const fetchImagesAction = actionCreator.async<
  FetchImagesParams,
  Images,
  Error
>("FETCH_IMAGES")

export const addImageAction = actionCreator.async<AddImageParams, void, Error>(
  "ADD_IMAGE"
)

export const deleteImageAction = actionCreator.async<
  DeleteImageParams,
  Images,
  Error
>("DELETE_IMAGE")
