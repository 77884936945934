import { reducerWithInitialState } from "typescript-fsa-reducers"
import { setUserAction, setManagerAction, createManagerAction, updateManagerAction } from "./actions"
import { User } from "./types"

type UserState = (
  | {
      isLoaded: false
    }
  | {
      isLoaded: true
      user: User
    }
) &
  ManagerState

type ManagerState =
  | {
      isSet: false
    }
  | {
      isSet: true
      manager: {
        auth0Id: string
        vendorId: string
        vendorName: string
        email: string
      } | null
    }
const initialState = {
  isLoaded: false,
  isSet: false
} as UserState

const setUser = (
  state: UserState,
  action: ReturnType<typeof setUserAction>
): UserState => {
  return {
    ...state,
    isLoaded: true,
    user: action.payload
  }
}

const setManager = (
  state: UserState,
  action: ReturnType<typeof setManagerAction>
): UserState => {
  return {
    ...state,
    isSet: true,
    manager: action.payload
  }
}

const createManagerDone = (
  state: UserState,
  action: ReturnType<typeof createManagerAction.done>
): UserState => {
  return {
    ...state,
    isSet: true,
    manager: null
  }
}

const updateManagerDone = (
  state: UserState,
  action: ReturnType<typeof updateManagerAction.done>
): UserState => {
  return {
    ...state,
    isSet: true,
    manager: null
  }
}

const reducer = reducerWithInitialState(initialState)
  .caseWithAction(setUserAction, setUser)
  .caseWithAction(setManagerAction, setManager)
  .caseWithAction(createManagerAction.done, createManagerDone)
  .caseWithAction(updateManagerAction.done, updateManagerDone)

export default reducer
