import { call, put, select, takeEvery } from "redux-saga/effects"
import { Images } from "./types"
import {
  addImageUsecase,
  deleteImageUsecase,
  fetchImagesUsecase
} from "./usecase"
import { Action } from "typescript-fsa"
import {
  AddImageParams,
  DeleteImageParams,
  FetchImagesParams,
  addImageAction,
  deleteImageAction,
  fetchImagesAction
} from "./actions"
import { displayToastAction } from "../notifications/actions"
import { invalidTokenAction } from "../error/actions"

function* fetchImagesSaga(action: Action<FetchImagesParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    const { appCode, assetCode } = action.payload
    const images: Images = yield call(
      fetchImagesUsecase,
      token,
      appCode,
      assetCode
    )

    yield put(
      fetchImagesAction.done({
        params: action.payload,
        result: images
      })
    )
  } catch (e) {
    yield put(fetchImagesAction.failed(e))
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

function* addImageSaga(action: Action<AddImageParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    const vendorId: string = yield select(
      state => state.vendorReducer.vendor.vendorId
    )

    const { appCode, assetCode, imageIndex, file } = action.payload
    yield call(
      addImageUsecase,
      token,
      vendorId,
      appCode,
      assetCode,
      imageIndex,
      file
    )

    yield put(
      addImageAction.done({
        params: action.payload
      })
    )
    yield put(
      displayToastAction({
        type: "success",
        code: "saved"
      })
    )
    yield put(fetchImagesAction.started({ appCode, assetCode }))
  } catch (e) {
    // TODO: error handling
    yield put(addImageAction.failed(e))
  }
}

function* deleteImageSaga(action: Action<DeleteImageParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    const vendorId: string = yield select(
      state => state.vendorReducer.vendor.vendorId
    )

    const { appCode, assetCode, imageIndex } = action.payload
    yield call(
      deleteImageUsecase,
      token,
      vendorId,
      appCode,
      assetCode,
      imageIndex
    )

    const images: Images = yield select(state => state.imagesReducer.images)
    images[Number(imageIndex) - 1] = ""

    yield put(
      deleteImageAction.done({
        params: action.payload,
        result: images
      })
    )
    yield put(
      displayToastAction({
        type: "success",
        code: "deleted"
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(addImageAction.failed(e))
  }
}
const sagas = [
  takeEvery(fetchImagesAction.started, fetchImagesSaga),
  takeEvery(addImageAction.started, addImageSaga),
  takeEvery(deleteImageAction.started, deleteImageSaga)
]

export default sagas
