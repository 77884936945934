/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React, { useEffect, useState } from "react"
import { Image, Nav, NavDropdown, Navbar } from "react-bootstrap"
import { useIntl } from "react-intl"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import Logo from "./atrust-logo.png"
import { useAuth } from "../Auth"
import { State } from "../state/store"

const Header: React.FC = () => {
  const intl = useIntl()
  const { auth0Client } = useAuth()
  const [userName, setUserName] = useState("")
  const userState = useSelector((state: State) => state.userReducer)
  const vendorState = useSelector((state: State) => state.vendorReducer)

  useEffect(() => {
    if (userState.isLoaded) {
      setUserName(userState.user.name)
    }
  }, [userState])
  const logout = () => {
    if (auth0Client !== null) {
      auth0Client.logout({
        returnTo: process.env.REACT_APP_AUTH0_RETURN_URL
      })
    }
  }

  return (
    <header
      className="mb-4"
      css={css`
        background-color: #044cb0;
      `}>
      <Navbar collapseOnSelect expand="lg" variant="dark">
        <Navbar.Brand>
          <Link to="/">
            <Image
              src={Logo}
              css={css`
                height: 20px;
              `}
            />
          </Link>
          {vendorState.isLoaded && (
            <span className="badge badge-primary ml-3">
              {vendorState.vendor.vendorName}
            </span>
          )}
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="pt-2">
            <Link className="text-white nav-link" to="/">
              {intl.formatMessage({ id: "list-app.title" })}
            </Link>
            <Link className="text-white nav-link" to="/find_cert">
              {intl.formatMessage({ id: "find-cert.title" })}
            </Link>
            <Link className="text-white nav-link" to="/history">
              {intl.formatMessage({ id: "transaction-history" })}
            </Link>
          </Nav>
          <NavDropdown
            drop="left"
            title={userName}
            id="nav-drojpdown"
            className="ml-auto"
            css={css`
              .dropdown-toggle {
                color: white;
              }
              .dropdown-toggle::before {
                display: inline-block;
                margin-right: 0.255em;
                vertical-align: 0rem;
                content: "";
                border: 0.3em solid transparent;
                border-top: 0.3em solid;
              }
              .dropdown-menu {
                position: absolute;
                top: 40px;
                right: 20px;
              }
            `}>
            <NavDropdown.Item as={Link} to="/profile">
              {intl.formatMessage({ id: "profile.title" })}
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => logout()}>
              {intl.formatMessage({ id: "logout-button" })}
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Header
