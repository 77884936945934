import React from "react"
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import BaseTable from "../components/BaseTable"
import { CertsData } from "../state/ducks/certs/types"
import { toJST } from "../util"
import { useIntl } from "react-intl"
import { LoadFunction } from "../types"
import { TableColumn } from "react-data-table-component"

interface CertDataRow {
  certId: string
  assetSeq: number
  registeredDate: string
  appCode: string
  assetCode: string
}

const CertTable: React.FC<CertsData & {
  handleLoad: LoadFunction
  isLoaded: boolean
}> = ({ certs, count, isLoaded, handleLoad }) => {
  const intl = useIntl()
  const rows = certs.map(cert => {
    const { certId, assetSeq, registeredDate } = cert

    return {
      certId,
      assetSeq,
      registeredDate: toJST(registeredDate),
      appCode: cert.appCode,
      assetCode: cert.assetCode
    }
  })

  const detailCert = (row: CertDataRow) => (
    <Link
      to={`/apps/${row.appCode}/assets/${row.assetCode}/certs/${row.certId}`}
      className="mr-3"
    >
      <Button size="sm" variant="success">
        {intl.formatMessage({ id: "detail-button" })}
      </Button>
    </Link>
  )

  const columns: TableColumn<CertDataRow>[] = [
    {
      name: intl.formatMessage({ id: "cert-id" }),
      selector: row => row.certId
    },
    {
      name: intl.formatMessage({ id: "list-cert.asset-seq" }),
      selector: row => row.assetSeq
    },
    {
      name: intl.formatMessage({ id: "registeredDate" }),
      selector:row => row.registeredDate,
      sortable: true
    },
    {
      button: true,
      cell: (row) => detailCert(row),
    }
  ]

  return (
    <BaseTable<CertDataRow>
      tableProps={{
        columns: columns,
        data: rows
      }}
      count={count}
      isLoaded={isLoaded}
      handleLoad={handleLoad}
    />
  )
}

export default CertTable
