import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { RouteComponentProps } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  Col,
  Form,
  Row
} from "react-bootstrap"
import { Formik } from "formik"
import * as Yup from "yup"
import * as ja from "yup-locale-ja"
import {
  fetchCertAction,
  transferCertAction
} from "../state/ducks/certs/actions"
import { Link } from "react-router-dom"
import { State } from "../state/store"
import { fetchAssetsAction } from "../state/ducks/assets/actions"
import FormTextField from "../components/FormTextField"
import { appSelector, appInfoSelector } from "../state/ducks/apps/selectors"
import { fetchAppsOps, fetchAppInfoOps } from "../state/ducks/apps/operations"
//import { isProcessingSelector } from "../state/ducks/notifications/selectors"
import Spinner from "../components/Spinner"
import PrevLink from "../components/PrevLink"
import * as Util from "../util"
import FormSelect from "../components/FormSelect"
import { checkDecimalLength } from "../util"

Yup.setLocale(ja.suggestive)

const TransferCert: React.FC<RouteComponentProps<{
  appCode: string
  assetCode: string
  certId: string
}>> = ({
  match,
  history
}) => {
  const { appCode, assetCode, certId } = match.params
  const dispatch = useDispatch()
  const intl = useIntl()

//  const isProcessing = useSelector(isProcessingSelector)

  const app = useSelector(appSelector(appCode))
  const appInfo = useSelector(appInfoSelector)

  const assetState = useSelector((state: State) => state.assetsReducer)
  const assetInfo = assetState.data.assets.find((asset: any) => asset.assetCode === assetCode)

  const cert = useSelector((state: State) => state.certsReducer.cert)

  const vendorState = useSelector((state: State) => state.vendorReducer)
  const vendorId = (vendorState.isLoaded && vendorState.vendor.vendorId) as string
  const isDnpKyoto2 = Util.isDnpKyoto2(vendorId, appCode)

  useEffect(() => {
    dispatch(fetchCertAction.started(certId))
    dispatch(fetchAppsOps({}))
    dispatch(fetchAssetsAction.started({ appCode, params: {} }))
    dispatch(fetchAppInfoOps(appCode))
  }, [certId, dispatch, appCode])

  let schema
  schema = Yup.object({
    newOwnerId: Yup.string().required().max(100),
    oldOwnerId: Yup.string().required().max(100),
    price: Yup.number().min(0.00).max(100000000.00),
    currency: Yup.string().required().oneOf(
      ["JPY", "USD", "CNY", "KRW", "EUR"],
      intl.formatMessage({ id: "yup-cert-currency" })
    ),
  })
  if (appInfo.isLoaded && appInfo.data.priceFlag) {
    schema = schema.concat(
      Yup.object({
        price: Yup.number().required().min(0.00).max(10000000.00)
        .test("price", intl.formatMessage({ id: "yup-cert-price" }), function(value) {
          const length = checkDecimalLength(value)
          if (length > 2) {
            return false
          }
          return true
        }),
      }),
    )
  }

  const submit = (values: any) => {
    dispatch(
      transferCertAction.started({
        appCode,
        certId,
        oldOwnerId: values.oldOwnerId,
        newOwnerId: values.newOwnerId,
        price: values.price,
        currency: values.currency,
        assetCode,
        priceFlag: appInfo.isLoaded && appInfo.data.priceFlag
      })
    )
  }

  console.log("appInfo.isLoaded", appInfo.isLoaded, "assetInfo", assetInfo)
  if (!appInfo.isLoaded || !assetInfo) {
    return <Spinner />
  }

  const currencyOptions = (currencies: string[]) => {
    return (
      <React.Fragment>
        {currencies.map(currency => (
          <option key={currency} value={currency}>
            {currency}
          </option>
        ))}
      </React.Fragment>
    )
  }
  
  return (
    <>
      {cert && (
        <PrevLink to={`/apps/${cert.appCode}/assets/${cert.assetCode}/certs/${cert.certId}`} label={intl.formatMessage({ id: "certification" })} />
      )}
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "transfer-cert.title" })}
          </h2>
        </Col>
      </Row>
      {cert && (
        <Row className="mb-4 mt-3">
          <Col>
            <Row>
              <Col>
                <Card>
                  <Card.Header>
                    {app && <Link to={`/apps/${appCode}`}>{app.appName}</Link>}
                    <span> / </span>
                    {assetInfo && (
                      <Link to={`/apps/${appCode}/assets/${assetCode}`}>
                        {assetInfo.assetName}
                      </Link>
                    )}
                  </Card.Header>
                  <Card.Body className="profile">
                    <Row>
                      <Col md={2}>{intl.formatMessage({ id: "cert-id" })}:</Col>
                      <Col>
                        <span>{cert.certId}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        {intl.formatMessage({ id: "asset-sequence" })}:
                      </Col>
                      <Col>
                        <span>{cert.assetSeq}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>{intl.formatMessage({ id: "creator" })}:</Col>
                      <Col>
                        <span>{cert.creator}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        {intl.formatMessage({ id: "copyright" })}:
                      </Col>
                      <Col>
                        <span>{cert.copyright}</span>
                      </Col>
                    </Row>
                    {isDnpKyoto2 && (
                      <>
                        <Row>
                          <Col md={2}>
                            {intl.formatMessage({ id: "dnp_cert_info1" })}:
                          </Col>
                          <Col>
                            <span>{cert.info1}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={2}>
                            {intl.formatMessage({ id: "dnp_cert_info2" })}:
                          </Col>
                          <Col>
                            <span>{cert.info1}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={2}>
                            {intl.formatMessage({ id: "dnp_cert_info3" })}:
                          </Col>
                          <Col>
                            <span>{cert.info1}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={2}>
                            {intl.formatMessage({ id: "dnp_cert_info4" })}:
                          </Col>
                          <Col>
                            <span>{cert.info1}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={2}>
                            {intl.formatMessage({ id: "dnp_asset_memo" })}:
                          </Col>
                          <Col>
                            <span>{assetInfo.memo}</span>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Card>
        <Card.Body>
          <Formik
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              submit(values)
            }}
            initialValues={{
              oldOwnerId: "",
              newOwnerId: "",
              price: "",
              currency: "JPY"
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              isValid,
              errors
            }: any) => (
              <Form noValidate onSubmit={handleSubmit}>
                <FormTextField
                  controlId="oldOwnerId"
                  label={intl.formatMessage({ id: "transfer-cert.owner-id" })}
                  name="oldOwnerId"
                  required
                />
                <FormTextField
                  controlId="newOwnerId"
                  label={intl.formatMessage({ id: "transfer-cert.destination-id" })}
                  name="newOwnerId"
                  required
                />

                {appInfo.data.priceFlag && (
                  <>
                    <FormTextField
                      controlId="price"
                      label={intl.formatMessage({ id: "price" })}
                      type="number"
                      name="price"
                      required
                    />

                    <FormSelect
                      controlId="currency"
                      label={intl.formatMessage({ id: "currency" })}
                      name="currency"
                      children={currencyOptions(["JPY", "USD", "CNY", "KRW", "EUR"])}
                      required
                    />
                  </>
                )}
                <Row>
                  <Col sm={{ offset: 2 }}>
                    {/*isProcessing ? (
                      <Spinner />
                    ) : (
                      <Button type="submit" variant="success">
                        {intl.formatMessage({ id: "transfer-cert.transfer-button" })}
                      </Button>
                    ) */}
                      <Button type="submit" variant="success">
                        {intl.formatMessage({ id: "transfer-cert.transfer-button" })}
                      </Button>
                  </Col>
                </Row>
              </Form>
            )}
            </Formik>
          </Card.Body>
      </Card>
      <Row className="mt-4">
        <Col sm={{ span: 2 }}>
          <Button variant="outline-secondary" onClick={() => history.goBack()}>
            {intl.formatMessage({ id: "back-button" })}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default TransferCert
