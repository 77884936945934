import React from "react"
import { useIntl } from "react-intl"
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import BaseTable from "./BaseTable"
import { AssetsData } from "../state/ducks/assets/types"
import { toJST } from "../util"
import { LoadFunction } from "../types"
import { TableColumn } from "react-data-table-component"

interface AssetDataRow {
  assetName: string
  registeredDate: string
  appCode: string
  assetCode: string
}

const AssetTable: React.FC<AssetsData & {
  handleLoad: LoadFunction
  isLoaded: boolean
}> = ({ assets, count, isLoaded, handleLoad }) => {
  const intl = useIntl()

  const rows = assets.map(asset => {
    const { assetName, registeredDate, assetCode } = asset

    return {
      assetName: assetName || " ",
      registeredDate: toJST(registeredDate),
      appCode: asset.appCode,
      assetCode: assetCode      
    }
  })

  const buttons = (row: AssetDataRow) => (
    <>
      <Link
        key="listAsset"
        to={`/apps/${row.appCode}/assets/${row.assetCode}`}
        className="mr-3"
      >
        <Button size="sm" variant="primary">
          {intl.formatMessage({ id: "list-asset.upload" })}
        </Button>
      </Link>
      <Link
        key="updateAsset"
        to={`/apps/${row.appCode}/assets/${row.assetCode}/edit`}
        className="mr-3"
      >
        <Button size="sm" variant="info">
          {intl.formatMessage({ id: "edit-button" })}
        </Button>
      </Link>
    </>
  )

  const columns: TableColumn<AssetDataRow>[] = [
    {
      name: intl.formatMessage({ id: "asset-name" }),
      selector: row => row.assetName,
      sortable: true
    },
    {
      name: intl.formatMessage({ id: "registeredDate" }),
      selector: row => row.registeredDate,
      sortable: true
    },
    {
      ignoreRowClick: true,
      cell: (row) => buttons(row)
    }
  ]

  return (
    <BaseTable<AssetDataRow>
      tableProps={{
        columns: columns,
        data: rows
      }}
      count={count}
      isLoaded={isLoaded}
      handleLoad={handleLoad}
    />
  )
}

export default AssetTable
