import { push } from "connected-react-router"
import { call, put, select, takeEvery } from "redux-saga/effects"
import { Asset, AssetsData } from "./types"
import {
  addAssetUsecase,
  fetchAssetsUsecase,
  updateAssetUsecase
} from "./usecase"
import { Action } from "typescript-fsa"
import {
  AddAssetParams,
  FetchAssetsParams,
  UpdateAssetParams,
  addAssetAction,
  fetchAssetsAction,
  updateAssetAction
} from "./actions"
import { displayToastAction } from "../notifications/actions"
import { invalidTokenAction } from "../error/actions"

function* fetchAssetsSaga(action: Action<FetchAssetsParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    const assets: AssetsData = yield call(fetchAssetsUsecase, token, action.payload.appCode, action.payload.params)

    yield put(
      fetchAssetsAction.done({
        params: action.payload,
        result: assets
      })
    )
  } catch (e) {
    yield put(fetchAssetsAction.failed(e))
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

function* addAssetSaga(action: Action<AddAssetParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    const asset: Asset = yield call(
      addAssetUsecase,
      token,
      action.payload.appCode,
      action.payload.data
    )

    yield put(
      addAssetAction.done({
        params: action.payload,
        result: {
          ...asset,
          appCode: action.payload.appCode
        }
      })
    )
    yield put(push(`/apps/${action.payload.appCode}/assets`))
    yield put(
      displayToastAction({
        type: "success",
        code: "saved"
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(addAssetAction.failed(e))
    yield put(
      displayToastAction({
        type: "error",
        code: `api-error.${e.response.data.code}${
          e.response.data.subCode ? "_" + e.response.data.subCode : ""
        }`,
        param: e.response.data.param,
        resource: e.response.data.resource
      })
    )
  }
}

function* updateAssetSaga(action: Action<UpdateAssetParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    const asset: Asset = yield call(
      updateAssetUsecase,
      token,
      action.payload.appCode,
      action.payload.data
    )

    yield put(
      updateAssetAction.done({
        params: action.payload,
        result: {
          ...asset,
          appCode: action.payload.appCode
        }
      })
    )
    yield put(push(`/apps/${action.payload.appCode}/assets/${action.payload.data.assetCode}`))
    yield put(
      displayToastAction({
        type: "success",
        code: "saved"
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(updateAssetAction.failed(e))
    yield put(
      displayToastAction({
        type: "error",
        code: `api-error.${e.response.data.code}${
          e.response.data.subCode ? "_" + e.response.data.subCode : ""
        }`,
        param: e.response.data.param,
        resource: e.response.data.resource
      })
    )
  }
}

const sagas = [
  takeEvery(fetchAssetsAction.started, fetchAssetsSaga),
  takeEvery(addAssetAction.started, addAssetSaga),
  takeEvery(updateAssetAction.started, updateAssetSaga)
]

export default sagas
