import { select, takeEvery } from "redux-saga/effects"
import { Action } from "typescript-fsa"
import {
  invalidTokenAction
} from "./actions"

function* reloadSaga(action: Action<void>) {
  const path: string = yield select(state => state.router.location.pathname)
  window.location.href = path
}

const sagas = [
  takeEvery(invalidTokenAction, reloadSaga),
]

export default sagas
