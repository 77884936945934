import actionCreatorFactory from "typescript-fsa"

const actionCreator = actionCreatorFactory()

type DisplayToastParams = {
  type: "success" | "error"
  code: string
  param?: string
  resource?: string
}

export const displayToastAction = actionCreator<DisplayToastParams>(
  "DISPLAY_TOAST"
)
export const hideToastAction = actionCreator("HIDE_TOAST")

type DisplayModalParams = {
  title?: string
  body?: string
}

export const displayModalAction = actionCreator<DisplayModalParams>(
  "DISPLAY_MODAL"
)

type DisplayErrorModalParams = {
  title?: string
  body?: string
  count?: number
}

export const displayErrorModalAction = actionCreator<DisplayErrorModalParams>(
  "DISPLAY_ERROR_MODAL"
)
export const hideModalAction = actionCreator("HIDE_MODAL")

export const setProcessingAction = actionCreator<boolean>("SET_PROCESSING")
