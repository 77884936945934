import React from "react"
import { Col, Form, InputGroup, Row } from "react-bootstrap"
import { Field } from "formik"
import { useIntl } from "react-intl"

type ownProps = {
  controlId: string
  label: string
  name: string
  type?: string
  required?: boolean
  disabled?: boolean
  inputGroupPrepend?: string
}

const FormTextField: React.FC<ownProps> = ({
  controlId,
  label,
  name,
  type,
  required,
  disabled,
  inputGroupPrepend
}) => {
  const intl = useIntl()
  
  return (
    <Field
      name={name}
      render={({ field, form }: any) => {
        const isValid = !form.errors[field.name]
        const isInvalid = form.touched[field.name] && !isValid
        return (
          <Form.Group as={Row} controlId={controlId}>
            <Form.Label column sm={2}>
              {label}
              {required && (
                <span className="text-danger float-right mr-5">
                  ({intl.formatMessage({ id: "required" })})
                </span>
              )}
            </Form.Label>
            <Col sm={5}>
              <InputGroup>
                {inputGroupPrepend}
                <Form.Control
                  {...field}
                  type={type ?? "text"}
                  isValid={form.touched[field.name] && isValid}
                  isInvalid={isInvalid}
                  feedback={form.errors[field.name]}
                />

                <Form.Control.Feedback type="invalid">
                  {form.errors[field.name]}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Form.Group>
        )
      }}
    />
  )
}

export default FormTextField
