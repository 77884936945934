import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { RouteComponentProps } from "react-router"
import {
  Button,
  Card,
  Row,
  Col,
  Form,
  InputGroup
} from "react-bootstrap"
import { Field, Formik } from "formik"
import * as Yup from "yup"
import * as ja from "yup-locale-ja"

import { fetchAppsOps } from "../state/ducks/apps/operations"
import {
  fetchAssetsAction,
  updateAssetAction
} from "../state/ducks/assets/actions"
import { appSelector, isLoadedSelector } from "../state/ducks/apps/selectors"
import { State } from "../state/store"

import Spinner from "../components/Spinner"
import PrevLink from "../components/PrevLink"
import FormTextField from "../components/FormTextField"
import FormTextArea from "../components/FormTextArea"
import ReadOnlyTextField from "../components/ReadOnlyTextField"
import * as Util from "../util"

Yup.setLocale(ja.suggestive)

const UpdateAssetPage: React.FC<RouteComponentProps<{
  appCode: string
  assetCode: string
}>> = ({ history, match }) => {
  const appCode = match.params.appCode
  const dispatch = useDispatch()
  const intl = useIntl()
  const isLoaded = useSelector(isLoadedSelector)
  const app = useSelector(appSelector(appCode))
  const assetState = useSelector((state: State) => state.assetsReducer)

  const [assetCode, setAssetCode] = useState("")
  const [assetName, setAssetName] = useState("")
  const [assetDesc, setAssetDesc] = useState("")
  const [assetHash, setAssetHash] = useState("")
  const [limit, setLimit] = useState("")
  const [noLimit, setNoLimit] = useState(false)
  const [creator, setCreator] = useState("")
  const [series, setSeries] = useState("")
  const [copyright, setCopyright] = useState("")
  //const [validated, setValidated] = useState(false)
  // From dnp_202306
  const [detail, setDetail] = useState("")
  const [memo, setMemo] = useState("")
  // To dnp_202306

  const assets = assetState.data.assets.filter((asset: any) => asset.appCode === appCode)
  const asset = assetState.data.assets.find(
    (asset: any) => asset.assetCode === match.params.assetCode
  )
  // For dnp_202306
  const vendorState = useSelector((state: State) => state.vendorReducer)
  const vendorId = (vendorState.isLoaded && vendorState.vendor.vendorId) as string
  const isDnpKyoto2 = Util.isDnpKyoto2(vendorId, appCode)
 
  useEffect(() => {
    dispatch(fetchAppsOps({}))

    if (!assets.length) {
      dispatch(fetchAssetsAction.started({ appCode, params: {} }))
    }

    if (!asset) {
      return
    }

    setAssetCode(asset.assetCode)
    setAssetName(asset.assetName)
    setAssetDesc(asset.assetDesc || "")
    setAssetHash(asset.assetHash || "")
    setSeries(asset.series || "")
    setCreator(asset.creator || "")
    setCopyright(asset.copyright || "")
    setDetail(asset.detail || "")
    setMemo(asset.memo || "")

    if (asset.limit === -1) {
      setNoLimit(true)
      setLimit("")
      return
    }
    setLimit(String(asset.limit))
  }, [appCode, asset, assets.length, dispatch])


  let schema = Yup.object({
    assetName: Yup.string().required().max(200),
    assetDesc: Yup.string().max(1000),
    assetHash: Yup.string().max(514),
    limit: Yup.number().min(1).max(1000000),
    noLimit: Yup.boolean().required(),
    creator: Yup.string().max(200),
    copyright: Yup.string().max(200),
    detail: Yup.string().max(200),
    memo: Yup.string().max(200),
  })
  if (app?.seriesFlag) {
    schema = schema.concat(
      Yup.object({
        series: Yup.string().required().max(200),
      }),
    )
  }

  const submit = (values: any) => {
    //console.log(values)
    //console.log(`noLimit=${noLimit}`)
    const data = {
      assetCode: values.assetCode,
      assetName: values.assetName,
      assetDesc: values.assetDesc,
      assetHash: values.assetHash,
      limit: noLimit ? -1 : values.limit,
      series: values.series,
      creator: values.creator,
      copyright: values.copyright,
      detail: values.detail,
      memo: values.memo,
    }

    if (app?.seriesFlag !== 1) {
      delete data.series
    }

    dispatch(
      updateAssetAction.started({
        title: intl.formatMessage({ id: "update-asset.title" }),
        appCode,
        data
      })
    )
    // TODO: 成功後ページ遷移 and 通知
  }

  if (!isLoaded || !assetState.isLoaded) {
    return <Spinner />
  }

  if (!app) {
    // TODO: 見せ方を考える
    return <h1>{intl.formatMessage({ id: "app-notfound" })}</h1>
  }

  if (!asset) {
    // TODO: 見せ方を考える
    return <h1>{intl.formatMessage({ id: "asset-notfound" })}</h1>
  }

  return (
    <>
      <PrevLink to={`/apps/${appCode}/assets/${assetCode}`} label={asset ? asset.assetName : ""} />
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "update-asset.title" })}
          </h2>
        </Col>
      </Row>
      <Card>
        <Card.Body>
        <Formik
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              submit(values)
            }}
            initialValues={{
              assetCode: assetCode,
              assetName: assetName,
              assetDesc: assetDesc,
              assetHash: assetHash,
              limit: limit,
              noLimit: (limit === "-1") ? true: false,
              series: series,
              creator: creator,
              copyright: copyright,
              detail: detail,
              memo: memo
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              isValid,
              errors
            }: any) => (
              <Form noValidate onSubmit={handleSubmit}>
                <ReadOnlyTextField
                  controlId="app-name"
                  label={intl.formatMessage({ id: "app-name" })}
                  value={appCode}
                />
                <ReadOnlyTextField
                  controlId="asset-code"
                  label={intl.formatMessage({ id: "asset-code" })}
                  value={assetCode}
                />

                <FormTextField
                  controlId="asset-name"
                  label={intl.formatMessage({ id: "asset-name" })}
                  name="assetName"
                  required
                />

                <FormTextArea
                  controlId="assetDesc"
                  label={intl.formatMessage({ id: "asset-description" })}
                  name="assetDesc"
                />

                <FormTextField
                  controlId="asset-hash"
                  label={intl.formatMessage({ id: "asset-hash" })}
                  name="assetHash"
                />

                <Field
                  name="limit"
                  render={({ field, form }: any) => {
                    const isValid = !form.errors[field.name]
                    const isInvalid = form.touched[field.name] && !isValid
                    return (
                      <Form.Group as={Row}>
                        <InputGroup>
                          <div className="d-flex align-items-center w-100">
                            <Form.Label column sm={2}>
                              {intl.formatMessage({ id: "limit" })}
                              {
                                <span className="text-danger float-right mr-5">
                                  ({intl.formatMessage({ id: "required" })})
                                </span>
                              }
                            </Form.Label>
                            <Col sm={{ span: 1 }}>
                              <Form.Check
                                required
                                label={intl.formatMessage({ id: "unspecified" })}
                                name="noLimit"
                                checked={noLimit}
                                onChange={(e: any) => {
                                  //console.log(`values.noLimit=${values.noLimit}`)
                                  //console.log(`noLimit=${noLimit}`)
                                  setNoLimit(!noLimit)
                                  values.limit = ""
                                }}
                              />
                            </Col>
                            {!noLimit &&
                              <Col sm={{ span: 3 }}>
                                <Form.Control
                                  {...field}
                                  type="number"
                                  name="limit"
                                  min="0"
                                  disabled={noLimit}
                                  isValid={form.touched[field.name] && isValid}
                                  isInvalid={isInvalid}
                                  feedback={form.errors[field.name]}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {form.errors[field.name]}
                                </Form.Control.Feedback>
                              </Col>
                            }
                          </div>
                        </InputGroup>
                      </Form.Group>
                    )
                  }}
                />
                {!!app.seriesFlag && (
                  <FormTextField
                    controlId="series"
                    type="text"
                    label={isDnpKyoto2? intl.formatMessage({ id: "dnp_asset_series" }) : intl.formatMessage({ id: "series" })}
                    required
                    name="series"
                  />
                )}

                {!isDnpKyoto2 && (
                  <FormTextField
                    controlId="creator"
                    type="text"
                    label={intl.formatMessage({ id: "creator" })}
                    name="creator"
                  />
                )}

                <FormTextField
                  controlId="copyright"
                  type="text"
                  label={isDnpKyoto2? intl.formatMessage({ id: "dnp_asset_copyright" }) : intl.formatMessage({ id: "copyright" })}
                  name="copyright"
                />

                {isDnpKyoto2 && (
                  <>
                    <FormTextArea
                      controlId="detail"
                      label={intl.formatMessage({ id: "dnp_asset_detail" }) + intl.formatMessage({ id: "dnp_asset_limit_200" })}
                      name="detail"
                    />

                    <FormTextArea
                      controlId="memo"
                      label={intl.formatMessage({ id: "dnp_asset_memo" }) + intl.formatMessage({ id: "dnp_asset_limit_200" })}
                      name="memo"
                    />
                  </>
                )}

                <Row>
                  <Col sm={{ span: 2, offset: 2 }}>
                    <Button type="submit" variant="primary">
                      {intl.formatMessage({ id: "register-button" })}
                    </Button>
                  </Col>
                </Row>
              </Form>            )}
          </Formik>
        </Card.Body>
      </Card>

      <Button
        className="mt-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}
      >
        {intl.formatMessage({ id: "back-button" })}
      </Button>
    </>
  )
}

export default UpdateAssetPage
