/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react"
import { useIntl } from "react-intl"

type OwnProps = {
  isDisplayed: boolean
  onClose: () => void
  body?: string
  type: "default" | "success" | "error"
  param?: string
  resource?: string
}

const T: React.FC<OwnProps> = ({
  isDisplayed,
  onClose,
  body,
  type,
  param,
  resource
}) => {
  const intl = useIntl()

  const msg = body && intl.formatMessage(
                  { id: body, defaultMessage: "system-error" },
                  { param: param && intl.formatMessage({ id: param }), resource }
                )
  const displayMsg = msg === "system-error" ? intl.formatMessage({ id: "system-error", defaultMessage: "" }) : msg

  return (
    <div
      css={css`
        display: ${isDisplayed ? "flex" : "none"};
        align-items: center;
        margin-top: 30px;
        position: fixed;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 1;
        transition: opacity 150ms, transform 150ms;
        pointer-events: none;
      `}>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-radius: 4px;
          min-width: 334px;
          max-width: 672px;
          background-color: ${type === "success"
            ? "#32a90b"
            : type === "error"
            ? "#e74c3c"
            : "#333"};
          box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
            0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-family: sans-serif;
          font-size: 14px;
          font-weight: 600;
          color: rgba(255, 255, 255, 0.87);
          letter-spacing: 0.25px;
          line-height: 20px;
          text-align: left;
        `}>
        <div
          css={css`
            flex-grow: 1;
            padding: 14px 16px;
            margin: 0;
            pointer-events: auto;
          `}>
          {displayMsg}
        </div>
        <button
          title="button"
          onClick={onClose}
          css={css`
            flex-shrink: 0;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 36px;
            height: 36px;
            padding: 8px;
            margin: 0 8px 0 0;
            cursor: pointer;
            position: relative;
            pointer-events: auto;
            -webkit-tap-highlight-color: transparent;

            outline: none;
            background-color: transparent;
            border: none;

            font-size: 12px;
            color: rgba(255, 255, 255, 0.87);
            &:hover::before {
              opacity: 0.08;
            }
            &::before {
              content: "";
              background-color: #fff;
              border-radius: 50%;
              opacity: 0;
              position: absolute;
              transition: opacity 120ms linear;
              top: 0%;
              left: 0%;
              width: 100%;
              height: 100%;
            }
          `}>
          <svg width="1em" height="1em" viewBox="0 0 12 12">
            <path
              fill="currentColor"
              d="M11.73 1.58L7.31 6l4.42 4.42-1.06 1.06-4.42-4.42-4.42 4.42-1.06-1.06L5.19 6 .77 1.58 1.83.52l4.42 4.42L10.67.52z"
              fillRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default T
