/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React, { useState } from "react"
import DataTable, { TableProps } from "react-data-table-component"
import Pagination from "./Pagination"
import Spinner from "react-bootstrap/Spinner"
import { LoadFunction } from "../types"
import { useIntl } from "react-intl"
  
type OwnProps<T> = {
  tableProps: TableProps<T>
  //columns: TableColumn<T>[]
  //data: any
  count: number
  isLoaded: boolean
  handleLoad: LoadFunction
}

function BaseTable<T>(props: OwnProps<T>): JSX.Element {
  const intl = useIntl()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [item, setItem] = useState()
  const [order, setOrder] = useState<"desc" | "asc">("desc")

  const load = (
    page?: number,
    pageNum?: number,
    item?: string,
    order?: "desc" | "asc"
  ) => {
    props.handleLoad({
      page,
      pageNum,
      item,
      order
    })
  }

  const NoRecordComponent = <div>{intl.formatMessage({ id: "base-table.no-data-found"})}</div>

  const handleChange = (e: {selected: number }) => {
    setPage(e.selected)
    load(e.selected, rowsPerPage, item, order)
  }

  const handleSort = (e: any) => {
    if (!e.selector || typeof e.selector != "string") return

    load(page, rowsPerPage, e.selector, order === "desc" ? "asc" : "desc")
    setItem(e.selector)
    setOrder(order === "desc" ? "asc" : "desc")
  }

  const pagination = () => <Pagination 
      rowCount={props.count || 0}
      rowsPerPage={rowsPerPage}
      currentPage={page}
      onChangePage={handleChange}
    />

  return (
    <div
      css={css`
        .row-page-select {
          p {
            padding-top: 5px;
            margin-right: 10px;
          }

          select {
            background: white;
            height: calc(1.5em + 0.5rem + 2px);
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            padding-left: 0.5rem;
            font-size: 0.875rem;
          }
        }

        div:first-of-type {
          height: auto;
        }

        .rdt_TableCol {
          height: auto;
        }

        .rdt_TableCol {
          font-weight: bold;
          font-size: 14px;
        }

        .rdt_TableCol,
        .rdt_TableCell {
          font-size: 14px;
          border: 1px solid #dee2e6;
        }
      `}
    >
      {
        <div className="d-flex row-page-select mt-4">
          <p>{intl.formatMessage({ id: "base-table.entries-label"})}</p>
          <select
            title="paging"
            onChange={async e => {
              setPage(0)
              setRowsPerPage(parseInt(e.currentTarget.value))
              load(0, parseInt(e.currentTarget.value), item, order)
            }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      }
      <DataTable
        striped
        noHeader
        noDataComponent={NoRecordComponent}
        columns={props.tableProps.columns}
        data={props.tableProps.data}
        className="mt-3"
        onSort={handleSort}
        progressComponent={ProgressSpinner}
        progressPending={!props.isLoaded}
        pagination
        paginationServer
        paginationComponent={pagination}
        {...props}
      />
    </div>
  )
}

const ProgressSpinner = (
  <Spinner
    animation="border"
    role="status"
    className="mt-4"
    css={css`
      width: 30px !important;
      height: 30px !important;
    `}
  />
)

export default BaseTable
