import React, { Dispatch, SetStateAction } from "react"
import { HistoryFilter } from "../state/ducks/certs/types"
import {
  DateRangeInput,
  OnDatesChangeProps,
  FocusedInput
} from "@datepicker-react/styled"
import { ThemeProvider } from "styled-components"
import { useIntl } from "react-intl"

type OwnProps = {
  filterParams: HistoryFilter
  handleDateChange: (e: OnDatesChangeProps) => void
  focusedInput: FocusedInput
  setFocusedInput: Dispatch<SetStateAction<FocusedInput>>
}

const DatePicker: React.FC<OwnProps> = ({
  filterParams,
  handleDateChange,
  focusedInput,
  setFocusedInput
}) => {
  const intl = useIntl()

  return (
    <ThemeProvider
      theme={{
        breakpoints: ["32em", "48em", "64em"],
        reactDatepicker: {
          daySize: [36, 40],
          fontFamily: "system-ui, -apple-system",
          dateRangeZIndex: 10,
          inputLabelMargin: 0,
          inputLabelBorderRadius: 5,
          inputMinHeight: 33,
          dateRangeBorderRadius: 5
        }
      }}
    >
      <DateRangeInput
        displayFormat="yyyy/MM/dd"
        onDatesChange={handleDateChange}
        onFocusChange={setFocusedInput}
        startDate={filterParams.from}
        endDate={filterParams.to}
        focusedInput={focusedInput}
        phrases={{
          datepickerStartDatePlaceholder: intl.formatMessage({ id: "date-picker.start-date" }),
          datepickerStartDateLabel: intl.formatMessage({ id: "date-picker.start-date" }),
          datepickerEndDateLabel: intl.formatMessage({ id: "date-picker.end-date" }),
          datepickerEndDatePlaceholder: intl.formatMessage({ id: "date-picker.end-date" }),
          resetDates: intl.formatMessage({ id: "date-picker.reset" }),
          close: intl.formatMessage({ id: "date-picker.close" }),
          startDateAriaLabel: "yyyy/mm/dd",
          endDateAriaLabel: "yyyy/mm/dd",
          startDatePlaceholder: "yyyy/mm/dd",
          endDatePlaceholder: "yyyy/mm/dd"
        }}
        vertical
      />
    </ThemeProvider>
  )
}

export default DatePicker
