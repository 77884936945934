import React from "react"
import { Spinner as BSSpinner } from "react-bootstrap"

const Spinner = () => (
  <div className="d-flex justify-content-center align-items-center h-100">
    <BSSpinner animation="border" role="status" />
  </div>
)

export default Spinner
