import { reducerWithInitialState } from "typescript-fsa-reducers"
import { addSealToUserAction, fetchSealToUsersAction, updateSealToUserAction, deleteSealToUserAction, uploadSealToUserCsvAction } from "./actions"
import { SealToUsersData, SealToUsers } from "./types"

export type SealToUsersState = {
  isLoaded: boolean
  data: SealToUsersData
}

const initialState = {
  isLoaded: false,
  data: {
    sealToUsers: [] as SealToUsers,
    count: 0,
    errors: [] as string[]
  } 
}

const fetchSealToUsersStarted = (
  state: SealToUsersState,
  action: ReturnType<typeof fetchSealToUsersAction.started>
): SealToUsersState => {
  return {
    ...state,
    isLoaded: false
  }
}

const fetchSealToUsersDone = (
  state: SealToUsersState,
  action: ReturnType<typeof fetchSealToUsersAction.done>
): SealToUsersState => {
  return {
    ...state,
    isLoaded: true,
    data: action.payload.result
  }
}

const addSealToUserStarted = (
  state: SealToUsersState,
  action: ReturnType<typeof addSealToUserAction.started>
): SealToUsersState => {
  return {
    ...state,
    isLoaded: false
  }
}

const addSealToUserDone = (
  state: SealToUsersState,
  action: ReturnType<typeof addSealToUserAction.done>
): SealToUsersState => {
  const sealToUsers = [...state.data.sealToUsers, action.payload.result]
  return {
    ...state,
    isLoaded: true,
    data: {
      sealToUsers,
      count: state.data.count,
      errors: []
    }
  }
}

const addSealToUserFailed = (
  state: SealToUsersState,
  action: ReturnType<typeof addSealToUserAction.failed>
): SealToUsersState => {
  return {
    ...state,
    isLoaded: true
  }
}

const updateSealToUserStarted = (
  state: SealToUsersState,
  action: ReturnType<typeof updateSealToUserAction.started>
): SealToUsersState => {
  return {
    ...state,
    isLoaded: false
  }
}

const updateSealToUserDone = (
  state: SealToUsersState,
  action: ReturnType<typeof updateSealToUserAction.done>
): SealToUsersState => {
  const nextSealToUsers = state.data.sealToUsers.map(sealToUser => {
    return sealToUser.sealId === action.payload.result.sealId
      ? action.payload.result
      : sealToUser
  })

  return {
    ...state,
    isLoaded: true,
    data: {
      sealToUsers: nextSealToUsers,
      count: state.data.count,
      errors: []
    }
  }
}

const updateSealToUserFailed = (
  state: SealToUsersState,
  action: ReturnType<typeof updateSealToUserAction.failed>
): SealToUsersState => {
  return {
    ...state,
    isLoaded: true
  }
}

const deleteSealToUserStarted = (
  state: SealToUsersState,
  action: ReturnType<typeof deleteSealToUserAction.started>
): SealToUsersState => {
  //console.log("delete started.")
  return {
    ...state,
    isLoaded: false
  }
}

const deleteSealToUserDone = (
  state: SealToUsersState,
  action: ReturnType<typeof deleteSealToUserAction.done>
): SealToUsersState => {
  const nextSealToUsers = state.data.sealToUsers.map(sealToUser => {
    return sealToUser.sealId === action.payload.result.sealId
      ? action.payload.result
      : sealToUser
  })
  //console.log("delete done.")

  return {
    ...state,
    isLoaded: true,
    data: {
      sealToUsers: nextSealToUsers,
      count: state.data.count,
      errors: []
    }
  }
}

const deleteSealToUserFailed = (
  state: SealToUsersState,
  action: ReturnType<typeof deleteSealToUserAction.failed>
): SealToUsersState => {
  //console.log("delete failed.")
  return {
    ...state,
    isLoaded: true
  }
}

/**
 * シール-ユーザCSVアップロード開始
 * @param state ステート
 * @param action アクション
 */
const uploadSealToUserCsvStarted = (
  state: SealToUsersState,
  action: ReturnType<typeof uploadSealToUserCsvAction.started>
): SealToUsersState => {
  return {
    ...state,
    isLoaded: false
  }
}

/**
 * シール-ユーザCSVアップロード完了
 * @param state ステート
 * @param action アクション
 */
const uploadSealToUserCsvDone = (
  state: SealToUsersState,
  action: ReturnType<typeof uploadSealToUserCsvAction.done>
): SealToUsersState => {
  // console.log(action)
  return {
    ...state,
    isLoaded: true,
    data: {
      sealToUsers: [],
      count: action.payload.result.count,
      errors: action.payload.result.errors
    }
  }
}

/**
 * シール-ユーザCSVアップロード失敗
 * @param state ステート
 * @param action アクション
 */
const uploadSealToUserCsvFailed = (
  state: SealToUsersState,
  action: ReturnType<typeof  uploadSealToUserCsvAction.failed>
): SealToUsersState => {
  return {
    ...state,
    isLoaded: true
  }
}

const reducer = reducerWithInitialState(initialState)
  .caseWithAction(fetchSealToUsersAction.started, fetchSealToUsersStarted)
  .caseWithAction(fetchSealToUsersAction.done, fetchSealToUsersDone)
  .caseWithAction(addSealToUserAction.started, addSealToUserStarted)
  .caseWithAction(addSealToUserAction.done, addSealToUserDone)
  .caseWithAction(addSealToUserAction.failed, addSealToUserFailed)
  .caseWithAction(updateSealToUserAction.started, updateSealToUserStarted)
  .caseWithAction(updateSealToUserAction.done, updateSealToUserDone)
  .caseWithAction(updateSealToUserAction.failed, updateSealToUserFailed)
  .caseWithAction(deleteSealToUserAction.started, deleteSealToUserStarted)
  .caseWithAction(deleteSealToUserAction.done, deleteSealToUserDone)
  .caseWithAction(deleteSealToUserAction.failed, deleteSealToUserFailed)
  .caseWithAction(uploadSealToUserCsvAction.started, uploadSealToUserCsvStarted)
  .caseWithAction(uploadSealToUserCsvAction.done, uploadSealToUserCsvDone)
  .caseWithAction(uploadSealToUserCsvAction.failed, uploadSealToUserCsvFailed)

export default reducer
