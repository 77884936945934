import actionCreatorFactory from "typescript-fsa"

const actionCreator = actionCreatorFactory()

export const setErrorAction = actionCreator<{
  hasError: boolean
  status?: number
  message?: string
}>("SET_ERROR")

export const invalidTokenAction = actionCreator("INVALID_TOKEN")
