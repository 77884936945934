import React from "react"
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import BaseTable from "../components/BaseTable"
import { toJST } from "../util"
import { Events } from "../state/ducks/events/types"
import { useIntl } from "react-intl"
import { LoadFunction } from "../types"
import { TableColumn } from "react-data-table-component"

interface EventDataRow {
  eventName: string
  statusName: string
  created_at: string
  redeliverAt: string
  errorReason: string
  id: string
}

const EventTable: React.FC<{
  appCode: string
  events: Events
  count: number
  handleLoad: LoadFunction
  isLoaded: boolean
}> = ({ appCode, events, count, handleLoad, isLoaded }) => {
  const intl = useIntl()
  const rows = events.map(event => {
    const {
      eventType,
      status,
      requestBody,
      redeliverAt,
      errorReason,
      id
    } = event

    const { created_at } = JSON.parse(requestBody)
    const eventName = intl.formatMessage({ id: eventType })
    const statusName = intl.formatMessage({ id: status })

    return {
      eventName,
      statusName,
      created_at: toJST(created_at) || " ",
      redeliverAt: (redeliverAt && toJST(redeliverAt)) || " ",
      errorReason: errorReason || " ",
      id
    }
  })

  const button = (row: EventDataRow) => (
    <Link to={`/apps/${appCode}/events/${row.id}`} className="mr-3">
      <Button size="sm" variant="primary">
        {intl.formatMessage({ id: "detail-button" })}
      </Button>
    </Link>
  )

  const columns: TableColumn<EventDataRow>[] = [
    {
      name: intl.formatMessage({ id: "list-event.name" }),
      selector: row => row.eventName,
      sortable: true
    },
    {
      name: intl.formatMessage({ id: "list-event.status" }),
      selector: row => row.statusName,
      sortable: true
    },
    {
      name: intl.formatMessage({ id: "list-event.created-at" }),
      selector: row => row.created_at,
      sortable: true
    },
    {
      name: intl.formatMessage({ id: "list-event.redeliver-at" }),
      selector: row => row.redeliverAt,
      sortable: true
    },
    {
      name: intl.formatMessage({ id: "list-event.error-reason" }),
      selector: row => row.errorReason
    },
    {
      button: true,
      cell: (row) => button(row),
    }
  ]

  return (
    <BaseTable<EventDataRow>
      tableProps={{
        columns: columns,
        data: rows
      }}
      count={count}
      handleLoad={handleLoad}
      isLoaded={isLoaded}
    />
  )
}

export default EventTable
