import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { State } from "../state/store"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import Spinner from "../components/Spinner"
import {
  fetchSealToUsersAction,
  deleteSealToUserAction
} from "../state/ducks/seal_to_users/actions"
import { useIntl } from "react-intl"
import { fetchAppsOps } from "../state/ducks/apps/operations"
import { appSelector, isLoadedSelector } from "../state/ducks/apps/selectors"
import PrevLink from "../components/PrevLink"
import ReadOnlyTextField from "../components/ReadOnlyTextField"

const DeleteSealToUserPage: React.FC<RouteComponentProps<{
  appCode: string
  sealId: string
}>> = ({ history, match }) => {
  const appCode = match.params.appCode
  const dispatch = useDispatch()
  const intl = useIntl()
  const isLoaded = useSelector(isLoadedSelector)
  const app = useSelector(appSelector(appCode))
  const sealToUserState = useSelector((state: State) => state.sealToUsersReducer)

  const [sealId, setSealId] = useState("")
  const [userId, setUserId] = useState("")

  //const [validated] = useState(false)

  const sealToUsers = sealToUserState.data.sealToUsers.filter((sealToUser: any) => sealToUser.appCode === appCode)
  const sealToUser = sealToUserState.data.sealToUsers.find(
    (sealToUser: any) => sealToUser.sealId === match.params.sealId
  )

  useEffect(() => {
    dispatch(fetchAppsOps({}))

    if (!sealToUsers.length) {
      dispatch(fetchSealToUsersAction.started({ appCode, params: {} }))
    }

    if (!sealToUser) {
      return
    }

    setSealId(sealToUser.sealId || "")
    setUserId(sealToUser.userId || "")
  }, [appCode, sealToUser, sealToUsers.length, dispatch])

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    dispatch(
      deleteSealToUserAction.started({
        title: intl.formatMessage({ id: "seal-management.title" }),
        appCode,
        data: {
          sealId
        }
      })
    )
    // TODO: 成功後ページ遷移 and 通知
  }

  if (!isLoaded || !sealToUserState.isLoaded) {
    return <Spinner />
  }

  if (!app) {
    // TODO: 見せ方を考える
    return <h1>{intl.formatMessage({ id: "app-notfound" })}</h1>
  }

  if (!sealToUser) {
    // TODO: 見せ方を考える
//    return <h1>{intl.formatMessage({ id: "sealToUser-notfound" })}</h1>
  }

  return (
    <>
      <PrevLink to={`/apps/${appCode}/seal_to_users`} label={intl.formatMessage({ id: "detail-app.seal-management-button" })} />
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "seal-management" })}: {app?.appName}
          </h2>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Form noValidate onSubmit={submit}>
            <ReadOnlyTextField
              controlId="seal-id"
              label={intl.formatMessage({ id: "seal-id" })}
              value={sealId}
            />

            <ReadOnlyTextField
              controlId="user-id"
              label={intl.formatMessage({ id: "user-id" })}
              value={userId}
            />

            <Row>
              <Col sm={{ span: 2, offset: 2 }}>
                <Button type="submit" variant="success">
                  {intl.formatMessage({ id: "delete-button" })}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Link
        to={`/apps/${app.appCode}/seal_to_users`}>
        <Button
          className="mt-4"
          variant="outline-secondary"
//          onClick={() => history.goBack()}
        >
          {intl.formatMessage({ id: "back-button" })}
        </Button>
      </Link>
    </>
  )
}

export default DeleteSealToUserPage
