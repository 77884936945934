export type User = {
  auth0Id: string
  name: string
  email: string
  lang: LanguageType
  registeredDate: string
}

export type auth0User = {
  vendorId: string
  vendorName: string
  email: string
}

export type LanguageType = "ja" | "zh-CN" | "zh-TW"

export const isLanguageType = (lang: string): lang is LanguageType => {
  switch (lang) {
    case "ja":
      return true
    case "zh-CN":
      return true
    case "zh-TW":
      return true
    default:
      return false
  }
}
