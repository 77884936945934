import React from "react"
import { Link } from "react-router-dom"
import BaseTable from "../components/BaseTable"
import { HistoriesData } from "../state/ducks/certs/types"
import { toJST } from "../util"
import { useIntl } from "react-intl"
import { LoadFunction } from "../types"
import { TableColumn } from "react-data-table-component"

interface HistoryDataRow {
  type: string
  appName: string
  assetName: string
  assetSeq: number
  price: string | number
  registeredDate: string
  appCode: string
  assetCode: string
  certId: string
}

const CertHistoryTable: React.FC<HistoriesData & {
  handleLoad: LoadFunction
  isLoaded: boolean
}> = ({ histories, count, isLoaded, handleLoad }) => {
  const intl = useIntl()
  const rows = histories.map(history => {
    //console.log("history", history)
    const price = (history.price === 0) ? 0 : history.price ? history.price : " "
    return {
      type: history.type,
      appName: history.appName || " ",
      assetName: history.assetName,
      assetSeq: history.assetSeq,
      price: price,
      registeredDate: toJST(history.registeredDate),
      appCode: history.appCode,
      assetCode: history.assetCode,
      certId: history.certId    
    }
  })

  const certification = (row: HistoryDataRow) => (
    <Link
      to={`/apps/${row.appCode}/assets/${row.assetCode}/certs/${row.certId}`}
      className="mr-3"
    >
      {row.certId}
    </Link>
  )

  const columns: TableColumn<HistoryDataRow>[] = [
    {
      name: intl.formatMessage({ id: "type" }),
      selector: row => row.type
    },
    {
      name: intl.formatMessage({ id: "app" }),
      selector: row => row.appName,
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "asset" }),
      selector: row => row.assetName,
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "asset-sequence" }),
      selector: row => row.assetSeq,
      sortable: true,
      maxWidth: "150px"
    },
    {
      name: intl.formatMessage({ id: "amountOfMoney" }),
      selector: row => row.price,
      sortable: true,
      maxWidth: "150px"
    },
    {
      name: intl.formatMessage({ id: "date" }),
      selector: row => row.registeredDate,
      sortable: true
    },
    {
      name: intl.formatMessage({ id: "certification" }),
      cell: (row) => certification(row),
      sortable: false,
    }
  ]

  return (
    <BaseTable<HistoryDataRow>
      tableProps={{
        columns: columns,
        data: rows
      }}
      count={count}
      isLoaded={isLoaded}
      handleLoad={handleLoad}
    />
  )
}

export default CertHistoryTable
