import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { RouteComponentProps } from "react-router"
import {
  Button,
  Card,
  Row,
  Col,
  Form,
  InputGroup
} from "react-bootstrap"
import { Field, Formik } from "formik"
import * as Yup from "yup"
import * as ja from "yup-locale-ja"

import { fetchAppsOps } from "../state/ducks/apps/operations"
import { addAssetAction } from "../state/ducks/assets/actions"
import { appSelector, isLoadedSelector } from "../state/ducks/apps/selectors"
import { State } from "../state/store"

import Spinner from "../components/Spinner"
import PrevLink from "../components/PrevLink"
import FormTextField from "../components/FormTextField"
import FormTextArea from "../components/FormTextArea"
import ReadOnlyTextField from "../components/ReadOnlyTextField"
import * as Util from "../util"

Yup.setLocale(ja.suggestive)

const AddAssetPage: React.FC<RouteComponentProps<{
  appCode: string
}>> = ({
  history,
  match
}) => {
  const appCode = match.params.appCode
  const isLoaded = useSelector(isLoadedSelector)
  const app = useSelector(appSelector(appCode))
  const dispatch = useDispatch()
  const intl = useIntl()
  
  const [noLimit, setNoLimit] = useState(false)

  // For dnp_202306
  const vendorState = useSelector((state: State) => state.vendorReducer)
  const vendorId = (vendorState.isLoaded && vendorState.vendor.vendorId) as string
  const isDnpKyoto2 = Util.isDnpKyoto2(vendorId, appCode)
  //console.log(history)
  //console.log(match)
  //console.log(`vendorId=${vendorId}`)
  //console.log(`appCode=${appCode}`)
  //const [validated, setValidated] = useState(false)
  // To dnp_202306

  useEffect(() => {
    dispatch(fetchAppsOps({}))
  }, [dispatch])

  let schema = Yup.object({
    assetCode: Yup.string().required().max(20),
    assetName: Yup.string().required().max(200),
    assetDesc: Yup.string().max(1000),
    assetHash: Yup.string().max(514),
    limit: Yup.number().min(1).max(1000000),
    noLimit: Yup.boolean().required(),
    creator: Yup.string().max(200),
    copyright: Yup.string().max(200),
    detail: Yup.string().max(200),
    memo: Yup.string().max(200),
  })
  if (app?.seriesFlag) {
    schema = schema.concat(
      Yup.object({
        series: Yup.string().required().max(200),
      }),
    )
  }

  if (!isLoaded) {
    return <Spinner />
  }

  const submit = (values: any) => {
    const data = {
      assetCode: values.assetCode,
      assetName: values.assetName,
      assetDesc: values.assetDesc,
      assetHash: values.assetHash,
      limit: noLimit ? "-1" : values.limit,
      series: values.series,
      creator: values.creator,
      copyright: values.copyright,
      detail: values.detail,
      memo: values.memo,
    }

    if (app?.seriesFlag !== 1) {
      delete data.series
    }

    dispatch(
      addAssetAction.started({
        title: intl.formatMessage({ id: "add-asset.title" }),
        appCode,
        data
      })
    )
  } 

  if (!app) {
    // TODO: error message
    return (
      <div className="mt-3 d-flex justify-content-center">
        <h1>{intl.formatMessage({ id: "app-notfound" })}</h1>
      </div>
    )
  }

  // Add Formik dnp_202306
  return (
    <>
      <PrevLink to={`/apps/${appCode}/assets`} label={intl.formatMessage({ id: "list-asset.title" })} />
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "add-asset.title" })}
          </h2>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Formik
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              submit(values)
            }}
            initialValues={{
              assetCode: "",
              assetName: "",
              assetDesc: "",
              assetHash: "",
              limit: "",
              noLimit: false,
              series: "",
              creator: "",
              copyright: "",
              detail: "",
              memo: ""
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              isValid,
              errors
            }: any) => (
              <Form noValidate onSubmit={handleSubmit}>
                <ReadOnlyTextField
                  controlId="app-name"
                  label={intl.formatMessage({ id: "app-name" })}
                  value={app.appName}
                />
                <FormTextField
                  controlId="asset-code"
                  label={intl.formatMessage({ id: "asset-code" })}
                  name="assetCode"
                  required
                />

                <FormTextField
                  controlId="asset-name"
                  label={intl.formatMessage({ id: "asset-name" })}
                  name="assetName"
                  required
                />

                <FormTextArea
                  controlId="assetDesc"
                  label={intl.formatMessage({ id: "asset-description" })}
                  name="assetDesc"
                />

                <FormTextField
                  controlId="asset-hash"
                  label={intl.formatMessage({ id: "asset-hash" })}
                  name="assetHash"
                />

                <Field
                  name="limit"
                  render={({ field, form }: any) => {
                    const isValid = !form.errors[field.name]
                    const isInvalid = form.touched[field.name] && !isValid           
                    return (
                      <Form.Group as={Row}>
                        <InputGroup>
                          <div className="d-flex align-items-center w-100">
                            <Form.Label column sm={2}>
                              {intl.formatMessage({ id: "limit" })}
                              {
                                <span className="text-danger float-right mr-5">
                                  ({intl.formatMessage({ id: "required" })})
                                </span>
                              }
                            </Form.Label>
                            <Col sm={{ span: 1 }}>
                              <Form.Check
                                required
                                label={intl.formatMessage({ id: "unspecified" })}
                                name="noLimit"
                                checked={noLimit}
                                onChange={(e: any) => {
                                  //console.log(`values.noLimit=${noLimit}`)
                                  setNoLimit(!noLimit)
                                  if (noLimit) {
                                    values.limit = ""
                                  }
                                }}
                              />
                            </Col>
                            {!noLimit &&
                              <Col sm={{ span: 3 }}>
                                <Form.Control
                                  {...field}
                                  type="number"
                                  name="limit"
                                  min="0"
                                  disabled={noLimit}
                                  isValid={form.touched[field.name] && isValid}
                                  isInvalid={isInvalid}
                                  feedback={form.errors[field.name]}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {form.errors[field.name]}
                                </Form.Control.Feedback>
                              </Col>
                            }
                          </div>
                        </InputGroup>
                      </Form.Group>
                    )
                  }}
                />
                {!!app.seriesFlag && (
                  <FormTextField
                    controlId="series"
                    label={isDnpKyoto2? intl.formatMessage({ id: "dnp_asset_series" }) : intl.formatMessage({ id: "series" })}
                    name="series"
                    required
                  />
                )}

                {!isDnpKyoto2 && (
                  <FormTextField
                    controlId="creator"
                    label={intl.formatMessage({ id: "creator" })}
                    name="creator"
                  />
                )}

                <FormTextField
                  controlId="copyright"
                  label={isDnpKyoto2? intl.formatMessage({ id: "dnp_asset_copyright" }) : intl.formatMessage({ id: "copyright" })}
                  name="copyright"
                />

                {isDnpKyoto2 && (
                  <>
                    <FormTextArea
                      controlId="copyright"
                      label={intl.formatMessage({ id: "dnp_asset_detail" }) + intl.formatMessage({ id: "dnp_asset_limit_200" })}
                      name="detail"
                    />

                    <FormTextArea
                      controlId="copyright"
                      label={intl.formatMessage({ id: "dnp_asset_memo" }) + intl.formatMessage({ id: "dnp_asset_limit_200" })}
                      name="memo"
                    />
                  </>
                )}

                <Row>
                  <Col sm={{ span: 2, offset: 2 }}>
                    <Button type="submit" variant="primary">
                      {intl.formatMessage({ id: "register-button" })}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <Button
        className="my-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}
      >
        {intl.formatMessage({ id: "back-button" })}
      </Button>
    </>
  )
}

export default AddAssetPage
