import { all } from "redux-saga/effects"
import { composeWithDevTools } from "redux-devtools-extension"
import { createBrowserHistory } from "history"
import createSagaMiddleware from "redux-saga"
import { applyMiddleware, combineReducers, createStore } from "redux"
import { connectRouter, routerMiddleware } from "connected-react-router"
import { reducers, sagas } from "./ducks"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
const rootReducer = combineReducers(
  Object.assign(
    {
      router: connectRouter(history)
    },
    reducers
  )
)

const configureStore = () => {
  const persistConfig = {
    key: "root",
    storage
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer) 

  const store = createStore(
    persistedReducer,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware, routerMiddleware(history))
    )
  )

  sagaMiddleware.run(function*() {
    yield all([...sagas])
  })

  return store
}

export type State = ReturnType<typeof rootReducer>

export default configureStore
