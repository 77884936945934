import React from "react"
import { Modal as BSModal, Button } from "react-bootstrap"
import { useIntl } from "react-intl"
import CSS from "csstype"

type ErrorProps = {
  isDisplayed: boolean
  onClose: () => void
  title?: string
  body?: string
  count?: number
}

const ErrorModal: React.FC<ErrorProps> = ({ isDisplayed, onClose, title, body, count }) => {
  const intl = useIntl()

  const titleMsg = intl.formatMessage(
      { id: "seal-management.upload_result" },
      { count: count }
    )
  const style: CSS.Properties = {
    whiteSpace: "pre-line"  
  }
    
  return (
    <BSModal show={isDisplayed} onHide={onClose}>
      {title && (
        <BSModal.Header>
          <BSModal.Title>{titleMsg}</BSModal.Title>
        </BSModal.Header>
      )}

      {body && (
        <BSModal.Body>
          {intl.formatMessage({ id: "failed" })}
          {<div style={style}>{body}</div>}
        </BSModal.Body>
      )}

      <BSModal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {intl.formatMessage({ id: "close-button" })}
        </Button>
      </BSModal.Footer>
    </BSModal>
  )
}

export default ErrorModal
