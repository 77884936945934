import React, { useEffect, useState } from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { useIntl } from "react-intl"
import { RouteComponentProps } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchCertAction,
  updateSealAction
} from "../state/ducks/certs/actions"
import { Link } from "react-router-dom"
import { State } from "../state/store"
import { fetchAssetsAction } from "../state/ducks/assets/actions"
import { appSelector } from "../state/ducks/apps/selectors"
import { fetchAppsOps } from "../state/ducks/apps/operations"
import PrevLink from "../components/PrevLink"
import FormGroup from "../components/FormGroup"

const UpdateSealPage: React.FC<RouteComponentProps<{
  appCode: string
  assetCode: string
  certId: string
}>> = ({ match, history }) => {
  const { appCode, assetCode, certId } = match.params
  const dispatch = useDispatch()
  const cert = useSelector((state: State) => state.certsReducer.cert)
  const app = useSelector(appSelector(appCode))
  const assetState = useSelector((state: State) => state.assetsReducer)
  const asset = assetState.data.assets.find(asset => asset.assetCode === assetCode)
  const intl = useIntl()

  const [sealId, setSealId] = useState("")

  useEffect(() => {
    dispatch(fetchCertAction.started(certId))
    dispatch(fetchAppsOps({}))
    dispatch(fetchAssetsAction.started({ appCode, params: {} }))
  }, [certId, dispatch, appCode])

  const submit = () => {
    dispatch(updateSealAction.started({appCode, assetCode, certId, sealId}))
  }

  return (
    <>
      {cert && (
        <PrevLink to={`/apps/${cert.appCode}/assets/${cert.assetCode}/certs/${cert.certId}`} label={intl.formatMessage({ id: "certification" })} />
      )}
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "update-seal.title" })}
          </h2>
        </Col>
      </Row>
      {cert && (
        <Row className="mb-4 mt-3">
          <Col>
            <Row>
              <Col>
                <Card>
                  <Card.Header>
                    {app && (
                      <Link to={`/apps/${appCode}`}>{app.appName}</Link>
                    )}
                    <span> / </span>
                    {asset && (
                      <Link to={`/apps/${appCode}/assets/${assetCode}`}>
                        {asset.assetName}
                      </Link>
                    )}
                  </Card.Header>
                  <Card.Body className="profile">
                    <Row>
                      <Col md={2}>
                        {intl.formatMessage({ id: "cert-id" })}:
                      </Col>
                      <Col>
                        <span>{cert.certId}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        {intl.formatMessage({ id: "seal-id" })}:
                      </Col>
                      <Col>
                        <span>{cert.sealId}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        {intl.formatMessage({ id: "asset-sequence" })}:
                      </Col>
                      <Col>
                        <span>{cert.assetSeq}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        {intl.formatMessage({ id: "creator" })}:
                      </Col>
                      <Col>
                        <span>{cert.creator}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        {intl.formatMessage({ id: "copyright" })}:
                      </Col>
                      <Col>
                        <span>{cert.copyright}</span>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Card>
        <Card.Body>
          <FormGroup
            label={intl.formatMessage({ id: "update-seal.seal-id" })}
            required
            value={sealId}
            feedback={intl.formatMessage({ id: "feedback" })}
            handler={setSealId}
          />
          <Row>
            <Col sm={{ offset: 2 }}>
              <Button
                style={{ marginBottom: 10 }}
                onClick={() => {
                  submit()
                }}>
                {intl.formatMessage({ id: "update-seal.update-button" })}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row className="mt-4">
        <Col sm={{ span: 2 }}>
          <Button variant="outline-secondary" onClick={() => history.goBack()}>
            {intl.formatMessage({ id: "back-button" })}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default UpdateSealPage
