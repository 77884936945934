import React, { useState, useEffect } from "react"
import { Button, Card, Col, Row, Form } from "react-bootstrap"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { RouteComponentProps } from "react-router"
import { toJST } from "../util"
import { State } from "../state/store"
import {
  changePasswordAction,
  updateManagerAction
} from "../state/ducks/user/actions"
import Spinner from "../components/Spinner"
import LangSelect from "../components/LangSelect"
import { LanguageType } from "../state/ducks/user/types"

const ProfilePage: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const userState = useSelector((state: State) => state.userReducer)
  const [lang, setLang] = useState<LanguageType>("ja")

  useEffect(() => {
    if (!userState.isLoaded) {
      return
    }
    setLang(userState.user.lang)
  }, [userState])

  if (!userState.isLoaded) {
    return <Spinner />
  }

  const submitPassword = (e: any) => {
    dispatch(changePasswordAction(userState.user))
  }

  const submitUpdate = (e: any) => {
    dispatch(
      updateManagerAction.started({
        user: userState.user,
        lang
      })
    )
  }

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">{intl.formatMessage({ id: "profile.title" })}</h2>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Form onSubmit={submitUpdate}>
            <Row>
              <Col sm={2}>
                <h6>{intl.formatMessage({ id: "profile.username" })}</h6>
              </Col>
              <Col>
                <h4>{userState.user.name}</h4>
              </Col>
            </Row>
            <Row>
              <Col sm={2}>
                <h6>{intl.formatMessage({ id: "profile.mailaddress" })}</h6>
              </Col>
              <Col>
                <h4>{userState.user.email}</h4>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <LangSelect value={lang} handler={setLang} />
              </Col>
            </Row>
            <Row>
              <Col sm={2}>
                <h6>{intl.formatMessage({ id: "registeredDate" })}</h6>
              </Col>
              <Col>
                <h4>{toJST(userState.user.registeredDate)}</h4>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={{offset: 2}}>
                <Button variant="success" onClick={(e: any) => submitUpdate(e)}>
                  {intl.formatMessage({ id: "profile.update-user" })}
                </Button>
                <Button className="float-right" variant="outline-secondary" onClick={(e: any) => submitPassword(e)}>
                  {intl.formatMessage({ id: "profile.change-password" })}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Row className="mt-4">
        <Col sm={{ span: 2 }}>
          <Button variant="outline-secondary" onClick={() => history.goBack()}>
            {intl.formatMessage({ id: "back-button" })}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ProfilePage
