import React, { useEffect } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useIntl } from "react-intl"
import { RouteComponentProps } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import SealToUserTable from "../components/SealToUserTable"
import Spinner from "../components/Spinner"
import { fetchSealToUsersAction } from "../state/ducks/seal_to_users/actions"
import { State } from "../state/store"
import { Link } from "react-router-dom"
import { fetchAppsOps } from "../state/ducks/apps/operations"
import { appSelector, isLoadedSelector } from "../state/ducks/apps/selectors"
import { LoadFunction } from "../types"
// ErrorModalのために追加
//import ErrorModal from "../components/ErrorModal"
//import {
//  hideModalAction,
//} from "../state/ducks/notifications/actions"
import PrevLink from "../components/PrevLink"

const ListSealToUserPage: React.FC<RouteComponentProps<{ appCode: string }>> = ({
  match,
  history
}) => {
  const { appCode } = match.params
  const dispatch = useDispatch()
  const intl = useIntl()
  const isLoaded = useSelector(isLoadedSelector)
  const app = useSelector(appSelector(appCode))
  const sealToUserState = useSelector((state: State) => state.sealToUsersReducer)
  // ErrorModalのために追加
//  const notification = useSelector((state: State) => state.notificationsReducer)

  const sealToUsers = sealToUserState.data.sealToUsers.filter(
    sealToUser => sealToUser.appCode === appCode
  )

  useEffect(() => {
    dispatch(fetchAppsOps({}))
    dispatch(fetchSealToUsersAction.started({ appCode, params: {pageNum: 200} }))
  }, [dispatch, appCode])

  if (!isLoaded) {
    return <Spinner />
  }

  if (!app) {
    // TODO: 見せ方を考える
    return <h1>{intl.formatMessage({ id: "app-notfound" })}</h1>
  }

  if (!sealToUsers) {
    // TODO: 見せ方を考える
//    return <h1>{intl.formatMessage({ id: "sealToUser-notfound" })}</h1>
  }

  const handleLoad: LoadFunction = params => {
    dispatch(fetchSealToUsersAction.started({ appCode, params }))
  }

  return (
    <>
      <PrevLink to={`/apps/${appCode}`} label={app.appName} />
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "seal-management.title" })} : {app?.appName}
          </h2>
        </Col>
        <Col>
          <Link to={`/apps/${appCode}/seal_to_users/upload`}>
            <Button className="float-right" variant="success">
              <p className="d-inline-block ml-0 mb-0">
                {intl.formatMessage({ id: "seal-management.upload" })}
              </p>
            </Button>
          </Link>
        </Col>
      </Row>
      <SealToUserTable
        sealToUsers={sealToUserState.data.sealToUsers}
        count={sealToUserState.data.count}
        errors={sealToUserState.data.errors}
        isLoaded={sealToUserState.isLoaded}
        handleLoad={handleLoad}
      />
      <Row>
        <Col sm={{ span: 2 }}>
          <Button variant="outline-secondary" onClick={() => history.goBack()}>
            {intl.formatMessage({ id: "back-button" })}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ListSealToUserPage
