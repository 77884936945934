import React from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useIntl } from "react-intl"

type baseProps = {
  label: string
  value: string
  feedback?: string
  required?: boolean
  step?: number
  handler?: (s: string) => void
  readonly?: boolean
}

type forNumber = {
  type: "number"
  min?: string
}

type ownProps = baseProps | (baseProps & forNumber)

const FormGroup: React.FC<ownProps> = ({
  label,
  value,
  feedback,
  step,
  handler,
  required,
  readonly,
  ...props
}) => {
  const intl = useIntl()

  return (
    <Form.Group as={Row} controlId="formAppName">
      <Form.Label column sm={2}>
        {label}
        {required && (
          <span className="text-danger float-right mr-5">
            ({intl.formatMessage({ id: "required" })})
          </span>
        )}
      </Form.Label>
      <Col sm={5}>
        <Form.Control
          required={required}
          type={isForNumber(props) ? props.type : "text"}
          value={value}
          min={isForNumber(props) ? props.min : undefined}
          step={step}
          onChange={handler ? (e: any) => handler(e.target.value) : () => { }}
          readOnly={readonly}
        />
        <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
      </Col>
    </Form.Group>
  )
}

const isForNumber = (arg: any): arg is forNumber => arg.type === "number"

export default FormGroup