/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react"
import { Card, Col, Image, Row } from "react-bootstrap"
import { useIntl } from "react-intl"
import { Images } from "../state/ducks/images/types"
import noimage from "./noimage.png"

const imgStyle = css(`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`)

const style = (isThumbnail: boolean) =>
  css(`
    max-width: ${isThumbnail ? 400 : 600}px; height: ${
    isThumbnail ? 400 : 600
  }px;
    overflow: hidden;
  `)

type OwnProps = {
  images: Images
  isThumbnail?: boolean
}

const ImageList: React.FC<OwnProps> = ({ images, isThumbnail = false }) => {
  const intl = useIntl()
  return (
    <Row className="mt-4">
      {images.map((image, i) => (
        <Col xs={5} md={4} key={i}>
          <Card bg="light" className="pb-3" css={style(isThumbnail)}>
            <Card.Body>
              {intl.formatMessage({ id: "image" })}
              {i + 1}
            </Card.Body>
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Image src={image || noimage} css={imgStyle} />
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default ImageList
