import {
  deleteApiKeyAction,
  fetchApiKeyAction,
  fetchWebhookAction,
  issueApiKeyAction
} from "./actions"
import { reducerWithInitialState } from "typescript-fsa-reducers"

export type WebHookState =
  | {
      isLoaded: false
    }
  | {
      isLoaded: true
      apiKey: string
      webhook: {
        url: string
        secretToken: string
        eventList: {
          issued: boolean
          transferred: boolean
        }
      }
    }

const initialState = {
  isLoaded: false
} as WebHookState

const fetchApiKeyStarted = (
  state: WebHookState,
  action: ReturnType<typeof fetchApiKeyAction.started>
): WebHookState => {
  return {
    ...state,
    isLoaded: false
  }
}

const fetchApiKeyDone = (
  state: WebHookState,
  action: ReturnType<typeof fetchApiKeyAction.done>
): WebHookState => {
  return {
    ...state,
    isLoaded: true,
    apiKey: action.payload.result
  } as WebHookState
}

const issueApiKeyStarted = (
  state: WebHookState,
  action: ReturnType<typeof issueApiKeyAction.started>
): WebHookState => {
  return {
    ...state,
    isLoaded: false
  }
}

const issueApiKeyDone = (
  state: WebHookState,
  action: ReturnType<typeof issueApiKeyAction.done>
): WebHookState => {
  return {
    ...state,
    isLoaded: true,
    apiKey: action.payload.result
  } as WebHookState
}

const deleteApiKeyStarted = (
  state: WebHookState,
  action: ReturnType<typeof deleteApiKeyAction.started>
): WebHookState => {
  return {
    ...state,
    isLoaded: false
  } as WebHookState
}

const deleteApiKeyDone = (
  state: WebHookState,
  action: ReturnType<typeof deleteApiKeyAction.done>
): WebHookState => {
  return {
    ...state,
    isLoaded: true,
    apiKey: ""
  } as WebHookState
}

const fetchWebhookDone = (
  state: WebHookState,
  action: ReturnType<typeof fetchWebhookAction.done>
): WebHookState => {
  return {
    ...state,
    isLoaded: true,
    webhook: action.payload.result
  } as WebHookState
}

const reducer = reducerWithInitialState(initialState)
  .caseWithAction(fetchApiKeyAction.started, fetchApiKeyStarted)
  .caseWithAction(fetchApiKeyAction.done, fetchApiKeyDone)
  .caseWithAction(issueApiKeyAction.started, issueApiKeyStarted)
  .caseWithAction(issueApiKeyAction.done, issueApiKeyDone)
  .caseWithAction(deleteApiKeyAction.started, deleteApiKeyStarted)
  .caseWithAction(deleteApiKeyAction.done, deleteApiKeyDone)
  .caseWithAction(fetchWebhookAction.done, fetchWebhookDone)

export default reducer
