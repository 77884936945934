import React from "react"
import { ListGroup } from "react-bootstrap"
import { IntlShape } from "react-intl"
import { toJST } from "../util"

const OwnerLog = (
  intl: IntlShape,
  /*
  owners: Array<{
    originalUserId: string
    userId: string
    txId: string
    registeredDate: string
  }>
  */
  owners: Array<{
    originalUserId: string
    hashUserId: string
    price: number,
    currency: string,
    txId: string
    registeredDate: string
  }>
) => {
  return (
    <ListGroup variant="flush" className="profile">
      {owners.map((owner, i) => (
        <ListGroup.Item key={i}>
          <span className="item-label">
            {intl.formatMessage({ id: "registeredDate" })}:
          </span>{" "}
          {toJST(owner.registeredDate)},<br />
          <span className="item-label">
            {intl.formatMessage({ id: "transaction-id" })}:
          </span>{" "}
          {owner.txId},
          <br />
          <span className="item-label">
            {intl.formatMessage({ id: "user-id" })}:
          </span>{" "}
          {owner.originalUserId}
        </ListGroup.Item>
      ))}
    </ListGroup>
  )
}

export default OwnerLog
