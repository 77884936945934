import { Event, EventsData } from "./types"
import { actionCreatorFactory } from "typescript-fsa"
import { QueryParams } from "../../../types"

export type FetchEventParams = string

export type FetchEventsParams = {
  appCode: string
  eventType: string
  params: QueryParams
}

const actionCreator = actionCreatorFactory()

export const fetchEventAction = actionCreator.async<
  FetchEventParams,
  Event,
  Error
>("FETCH_EVENT")

export const fetchEventsAction = actionCreator.async<
  FetchEventsParams,
  EventsData,
  Error
>("FETCH_EVENTS")
