import React, { useEffect } from "react"
import { RouteComponentProps } from "react-router"
import { Button, Col, Row, Table } from "react-bootstrap"
import { fetchEventAction } from "../state/ducks/events/actions"
import { useDispatch, useSelector } from "react-redux"
import { State } from "../state/store"
import { useIntl } from "react-intl"
import Spinner from "../components/Spinner"
import { toJST } from "../util"
import PrevLink from "../components/PrevLink"

const DetailEvent: React.FC<RouteComponentProps<{
  appCode: string
  eventId: string
}>> = ({ match, history }) => {
  const { appCode, eventId } = match.params
  const dispatch = useDispatch()
  const eventState = useSelector((state: State) => state.eventsReducer)
  const intl = useIntl()

  useEffect(() => {
    dispatch(fetchEventAction.started(eventId))
  }, [eventId, dispatch])

  if (!eventState.event.isLoaded) {
    return <Spinner />
  }

  const event = eventState.event.data

  return (
    <>
      <PrevLink to={`/apps/${appCode}/events`} label={intl.formatMessage({ id: "detail-app.event-list" })} />
      {
        <Table striped bordered>
          <tbody>
            <tr>
              <th>{intl.formatMessage({ id: "event-id" })}</th>
              <td>{event.id}</td>
            </tr>
            <tr>
              <th>{intl.formatMessage({ id: "event-callback" })}</th>
              <td>{event.url}</td>
            </tr>
            <tr>
              <th>{intl.formatMessage({ id: "event-name" })}</th>
              <td>{intl.formatMessage({ id: event.eventType })}</td>
            </tr>
            <tr>
              <th>{intl.formatMessage({ id: "event-request-header" })}</th>
              <td>
                <pre>{`${JSON.stringify(
                  JSON.parse(event.requestHeaders),
                  null,
                  4
                )}`}</pre>
              </td>
            </tr>
            <tr>
              <th>{intl.formatMessage({ id: "event-request-body" })}</th>
              <td>
                <pre>{`${JSON.stringify(
                  JSON.parse(event.requestBody),
                  null,
                  4
                )}`}</pre>
              </td>
            </tr>
            <tr>
              <th>{intl.formatMessage({ id: "event-response-status" })}</th>
              <td>{event.responseStatus}</td>
            </tr>
            <tr>
              <th>{intl.formatMessage({ id: "event-response-header" })}</th>
              <td>
                <pre>
                  {event.responseHeaders &&
                    `${JSON.stringify(
                      JSON.parse(event.responseHeaders),
                      null,
                      4
                    )}`}
                </pre>
              </td>
            </tr>
            <tr>
              <th>{intl.formatMessage({ id: "event-response-body" })}</th>
              <td>
                <pre>
                  {event.responseBody &&
                    `${JSON.stringify(
                      JSON.parse(event.responseBody),
                      null,
                      4
                    )}`}
                </pre>
              </td>
            </tr>
            <tr>
              <th>{intl.formatMessage({ id: "event-redeliver-at" })}</th>
              <td>{event.redeliverAt && toJST(event.redeliverAt)}</td>
            </tr>
            <tr>
              <th>{intl.formatMessage({ id: "event-created-at" })}</th>
              <td>{event.deliveredAt && toJST(event.deliveredAt)}</td>
            </tr>
            <tr>
              <th>{intl.formatMessage({ id: "event-status" })}</th>
              <td>{intl.formatMessage({ id: event.status })}</td>
            </tr>
            <tr>
              <th>{intl.formatMessage({ id: "event-error-reason" })}</th>
              <td>{event.errorReason}</td>
            </tr>
            <tr>
              <th>{intl.formatMessage({ id: "registeredDate" })}</th>
              <td>{event.registeredDate && toJST(event.registeredDate)}</td>
            </tr>
            <tr>
              <th>{intl.formatMessage({ id: "updatedDate" })}</th>
              <td>{event.updatedDate && toJST(event.updatedDate)}</td>
            </tr>
          </tbody>
        </Table>
      }
      <Row>
        <Col sm={{ span: 2 }}>
          <Button variant="outline-secondary" onClick={() => history.goBack()}>
            {intl.formatMessage({ id: "back-button" })}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default DetailEvent
