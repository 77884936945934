import React from "react"
import { Nav } from "react-bootstrap"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

type OwnProps = {
  to: string 
  label: string
}

const PrevLink: React.FC<OwnProps> = ({
  to,
  label
}) => {
  return (
    <Nav className="mb-3 pl-1">
      <Link to={to}>
        <FontAwesomeIcon icon={faChevronLeft} /> {label}
      </Link>
    </Nav>
  )
}

export default PrevLink
