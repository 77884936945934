import React, { useEffect, useState } from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, RouteComponentProps } from "react-router-dom"
import { State } from "../state/store"
import { fetchAssetsAction } from "../state/ducks/assets/actions"
import { fetchCertsAction } from "../state/ducks/certs/actions"
import {
  addImageAction,
  deleteImageAction,
  fetchImagesAction
} from "../state/ducks/images/actions"
import CertTable from "../components/CertTable"
import ImageList from "../components/ImageListWithUploader"
import Spinner from "../components/Spinner"
import { toJST } from "../util"
import { useIntl } from "react-intl"
import { appSelector, isLoadedSelector } from "../state/ducks/apps/selectors"
import { fetchAppsOps } from "../state/ducks/apps/operations"
import { LoadFunction } from "../types"
import PrevLink from "../components/PrevLink"
import * as Util from "../util"

const DetailAssetPage: React.FC<RouteComponentProps<{
  appCode: string
  assetCode: string
}>> = ({ match, history }) => {
  const { appCode, assetCode } = match.params
  const isLoaded = useSelector(isLoadedSelector)
  const app = useSelector(appSelector(appCode))
  const dispatch = useDispatch()
  const intl = useIntl()
  const assetState = useSelector((state: State) => state.assetsReducer)
  const certState = useSelector((state: State) => state.certsReducer)
  const imageState = useSelector((state: State) => state.imagesReducer)
  const vendorState = useSelector((state: State) => state.vendorReducer)
  const [uploadImage, setUploadImage] = useState<Blob | null>(null)

  const vendorId = (vendorState.isLoaded && vendorState.vendor.vendorId) as string
  const assets = assetState.data.assets
  const asset = assets.find(asset => asset.assetCode === assetCode)

  const certs = certState.data.certs.filter(
    cert => cert.assetCode === assetCode
  )
  const count = certState.data.count
  const isDnpKyoto2 = Util.isDnpKyoto2(vendorId, appCode)

  useEffect(() => {
    dispatch(fetchAppsOps({}))

    if (!assets.length) {
      dispatch(fetchAssetsAction.started({ appCode, params: {} }))
    }

    dispatch(fetchCertsAction.started({ appCode, assetCode, params: {pageNum: 10} }))

    dispatch(
      fetchImagesAction.started({
        appCode,
        assetCode
      })
    )
  }, [dispatch, appCode, assetCode, assets.length])

  if (!isLoaded || !assetState.isLoaded) {
    return <Spinner />
  }

  if (!app) {
    // TODO: 見せ方を考える
    return <h1>{intl.formatMessage({ id: "app-notfound" })}</h1>
  }

  if (!asset) {
    // TODO: 見せ方を考える
    return <h1>{intl.formatMessage({ id: "asset-notfound" })}</h1>
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (!vendorId) {
      // TODO: error message
      return
    }

    if (uploadImage) {
      dispatch(
        addImageAction.started({
          appCode,
          assetCode,
          imageIndex: e.target.value,
          file: uploadImage
        })
      )
      setUploadImage(null)
    }
  }

  const handleChange = (e: any): void => {
    setUploadImage(e.target.files[0])
  }

  const handleDelete = (e: any): void => {
    e.preventDefault()

    if (imageState.images[e.target.value - 1]) {
      dispatch(
        deleteImageAction.started({
          appCode,
          assetCode,
          imageIndex: e.target.value
        })
      )
    }
  }

  const handleLoad: LoadFunction = params => {
    dispatch(fetchCertsAction.started({ appCode, assetCode, params }))
  }

  return (
    <React.Fragment>
      <PrevLink to={`/apps/${appCode}/assets`} label={intl.formatMessage({ id: "list-asset.title" })} />
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
              {asset.assetName}
          </h2>
        </Col>
        <Col>
          <Link
            to={`/apps/${appCode}/assets/${assetCode}/add_cert`}
            className="float-right"
          >
            <Button>{intl.formatMessage({ id: "issue-cert-button" })}</Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="mt-1">
                <Col md={3}>{intl.formatMessage({ id: "asset-code" })}:</Col>
                <Col>
                  <span>{asset.assetCode}</span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={3}>{intl.formatMessage({ id: "asset-hash" })}:</Col>
                <Col>
                  <span>{asset.assetHash}</span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={3}>
                  {intl.formatMessage({ id: "asset-description" })}:
                </Col>
                <Col>
                  <div>
                    <span>{asset.assetDesc}</span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={3}>
                  {intl.formatMessage({ id: "detail-asset.number-of-issues" })}:
                </Col>
                <Col>
                  <span>
                    {count} /{" "}
                    {asset.limit === -1
                      ? intl.formatMessage({ id: "detail-asset.no-limit" })
                      : asset.limit}
                  </span>
                </Col>
              </Row>
              {!!app.seriesFlag && (
                <Row className="mt-1">
                  <Col md={3}>{isDnpKyoto2? intl.formatMessage({ id: "dnp_asset_series" }) : intl.formatMessage({ id: "series" })}:</Col>
                  <Col>
                    <span>{asset.series}</span>
                  </Col>
                </Row>
              )}
              {!isDnpKyoto2 && (
                <Row className="mt-1">
                  <Col md={3}>{intl.formatMessage({ id: "creator" })}:</Col>
                  <Col>
                    <span>{asset.creator}</span>
                  </Col>
              </Row>
              )}
              <Row className="mt-1">
                <Col md={3}>{isDnpKyoto2? intl.formatMessage({ id: "dnp_asset_copyright" }) : intl.formatMessage({ id: "copyright" })}:</Col>
                <Col>
                  <span>{asset.copyright}</span>
                </Col>
              </Row>
              {isDnpKyoto2 && (
                <>
                  <Row className="mt-1">
                    <Col md={3}>
                      {intl.formatMessage({ id: "dnp_asset_detail" })}:
                    </Col>
                    <Col>
                      <span>{asset.detail}</span>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col md={3}>
                      {intl.formatMessage({ id: "dnp_asset_memo" })}:
                    </Col>
                    <Col>
                      <span>{asset.memo}</span>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="mt-1">
                <Col md={3}>
                  {intl.formatMessage({ id: "registeredDate" })}:
                </Col>
                <Col>
                  <span>{toJST(asset.registeredDate)}</span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={3}>{intl.formatMessage({ id: "updatedDate" })}:</Col>
                <Col>
                  <span>{toJST(asset.updatedDate)}</span>
                  <Link
                    key="updateAsset"
                    to={`/apps/${asset.appCode}/assets/${assetCode}/edit`}
                    className="float-right"
                  >
                    <Button size="sm" variant="info">
                      {intl.formatMessage({ id: "edit-button" })}
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {imageState.isLoaded && (
        <ImageList
          images={imageState.images}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleDelete={handleDelete}
        />
      )}

      <CertTable
        certs={certs}
        count={count}
        isLoaded={certState.isLoaded}
        handleLoad={handleLoad}
      />

      <Button
        className="my-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}
      >
        {intl.formatMessage({ id: "back-button" })}
      </Button>
    </React.Fragment>
  )
}

export default DetailAssetPage
