import React from "react"
import { useIntl } from "react-intl"
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import BaseTable from "./BaseTable"
import { SealToUsersData } from "../state/ducks/seal_to_users/types"
import { LoadFunction } from "../types"
import { TableColumn } from "react-data-table-component"

interface SealDataRow {
  sealId: string
  userId: string
  appCode: string
}

const SealToUserTable: React.FC<SealToUsersData & {
  handleLoad: LoadFunction
  isLoaded: boolean
}> = ({
  sealToUsers,
  count,
  errors,
  isLoaded,
  handleLoad,
}) => {
  const intl = useIntl()

  const rows = sealToUsers.map(sealToUser => {
    const { sealId, userId } = sealToUser

    return {
      sealId: sealId || " ",
      userId: userId || " ",
      appCode: sealToUser.appCode,
    }
  })

  const updateSealToUser = (row: SealDataRow) => (
    <Link
      key="updateSealToUser"
      to={`/apps/${row.appCode}/seal_to_users/${row.sealId}/edit`}
      className="mr-3"
    >
      <Button size="sm" variant="primary">
        {intl.formatMessage({ id: "edit-button" })}
      </Button>
    </Link>
  )

  const deleteSealToUser= (row: SealDataRow) => (
    <Link
      key="deleteSealToUser"
      to={`/apps/${row.appCode}/seal_to_users/${row.sealId}/delete`}
      className="mr-3"
    >
      <Button size="sm" variant="success">
        {intl.formatMessage({ id: "delete-button" })}
      </Button>
    </Link>
  )

  const columns : TableColumn<SealDataRow>[] = [
    {
      name: intl.formatMessage({ id: "seal-id" }),
      selector: row => row.sealId,
      sortable: true
    },
    {
      name: intl.formatMessage({ id: "user-id" }),
      selector: row => row.userId,
      sortable: true
    },
    {
      button: true,
      cell: (row) => updateSealToUser(row),
    },
    {
      button: true,
      cell: (row) => deleteSealToUser(row),
    }
  ]

  return (
    <BaseTable<SealDataRow>
      tableProps={{
        columns: columns,
        data: rows
      }}
      count={count}
      isLoaded={isLoaded}
      handleLoad={handleLoad}
    />
  )
}

export default SealToUserTable
