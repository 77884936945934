import actionCreatorFactory from "typescript-fsa"
import { Asset, AssetsData } from "./types"
import { QueryParams } from "../../../types"

export type FetchAssetsParams = {
  appCode: string
  params: QueryParams
}
export type FetchAssetsResults = AssetsData

export type AddAssetParams = {
  title: string
  appCode: string
  data: {
    assetCode: string
    assetName: string
    assetDesc: string
    assetHash: string
    limit: string
    series: string
    creator: string
    copyright: string
    detail: string
    memo: string
  }
}
export type AddAssetResult = Asset

export type UpdateAssetParams = {
  title: string
  appCode: string
  data: {
    assetCode: string
    assetName: string
    assetDesc: string
    assetHash: string
    limit: string
    series: string
    creator: string
    copyright: string
    detail: string
    memo: string
  }
}
export type UpdateAssetResult = Asset

const actionCreator = actionCreatorFactory()

export const fetchAssetsAction = actionCreator.async<
  FetchAssetsParams,
  FetchAssetsResults,
  Error
>("FETCH_ASSETS")

export const addAssetAction = actionCreator.async<
  AddAssetParams,
  AddAssetResult,
  Error
>("ADD_ASSET")

export const updateAssetAction = actionCreator.async<
  UpdateAssetParams,
  UpdateAssetResult,
  Error
>("UPDATE_ASSET")
