import { axiosWithToken } from "./../../../util"

export const fetchApiKeyUsecase = async (
  token: string,
  appCode: string
): Promise<string> => {
  const res = await axiosWithToken(token).get(
    `/apps/apiKeys?appCode=${appCode}`
  )
  return res.data.apiKey
}

export const issueApiKeyUsecase = async (
  token: string,
  appCode: string
): Promise<string> => {
  const res = await axiosWithToken(token).put(
    `/apps/apiKeys?appCode=${appCode}`
  )
  return res.data.apiKey
}

export const deleteApiKeyUsecase = async (token: string, appCode: string) => {
  await axiosWithToken(token).delete(`/apps/apiKeys?appCode=${appCode}`)
}

export const fetchWebhookUsecase = async (token: string, appCode: string) => {
  const res = await axiosWithToken(token).get(`/webhooks?appCode=${appCode}`)

  if (Object.keys(res.data).length === 0) {
    return {
      url: "",
      secretToken: "",
      eventList: {
        issued: false,
        transferred: false
      }
    }
  }
  const { url, secretToken } = res.data
  const json = JSON.parse(res.data.eventList)
  const eventList = {
    issued: json[0] ? true : false,
    transferred: json[1] ? true : false
  }

  return {
    url,
    secretToken,
    eventList
  }
}

export const registerWebhookUsecase = async (
  token: string,
  appCode: string,
  url: string,
  eventList: string[]
) => {
  const json = {
    url,
    eventList
  }
  await axiosWithToken(token).post(`/webhooks?appCode=${appCode}`, json)
}

export const updateWebhookUsecase = async (
  token: string,
  appCode: string,
  url: string,
  eventList: string[]
) => {
  const json = {
    url,
    eventList
  }
  await axiosWithToken(token).put(`/webhooks?appCode=${appCode}`, json)
}

export const sendPingUsecase = async (token: string, appCode: string) => {
  await axiosWithToken(token).post(`/webhooks/ping?appCode=${appCode}`)
}
