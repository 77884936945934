import { axiosWithToken, convertQueryParams } from "./../../../util"
import { QueryParams } from "./../../../types"
import { Apps, AppInfo } from "./types"

export const fetchAppsUsecase = async (token: string, params: QueryParams): Promise<Apps> => {
  const queryParams = convertQueryParams(params)

  // TODO: error handling
  const res = await axiosWithToken(token).get(`/apps?appCode=*&${queryParams}` )
  return res.data
}

export const fetchAppInfoUsecase = async (
  token: string,
  appCode: string
): Promise<AppInfo> => {
  const res = await axiosWithToken(token).get(`/apps?appCode=${appCode}`)

  return {
    seqFlag: !!res.data.apps[0].seqFlag,
    priceFlag: !!res.data.apps[0].priceFlag,
    sealFlag: !!res.data.apps[0].sealFlag,
    sealPrefix: res.data.apps[0].sealPrefix,
    sealToUserFlag: !!res.data.apps[0].sealToUserFlag,
    seriesFlag: !!res.data.apps[0].seriesFlag
  }
}

export const fetchAssetCountUsecase = async (
  token: string,
  appCode: string
): Promise<{count: number}> => {
  const res = await axiosWithToken(token).get(`/apps/count?appCode=${appCode}`)

  return res ? res.data.count : 0
}
