import React, { useEffect, useState } from "react"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { RouteComponentProps } from "react-router-dom"
import { State } from "../state/store"
import { fetchAppsAction } from "../state/ducks/apps/actions"
import { Apps } from "../state/ducks/apps/types"
import { Assets } from "../state/ducks/assets/types"
import { fetchAssetsAction } from "../state/ducks/assets/actions"
import {
  clearCertsAction,
  fetchCertsAction
} from "../state/ducks/certs/actions"
import CertTable from "../components/CertTable"
import Spinner from "../components/Spinner"
import { LoadFunction } from "../types"

const FindCert: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const appState = useSelector((state: State) => state.appsReducer)
  const assetState = useSelector((state: State) => state.assetsReducer)
  const certState = useSelector((state: State) => state.certsReducer)

  const [selectedAppCode, setSelectedAppCode] = useState("*")
  const [selectedAssetCode, setSelectedAssetCode] = useState("*")
  const [isTableShown, setIsTableShown] = useState(false)

  const app = appState.data.apps.find(app => app.appCode === selectedAppCode)

  useEffect(() => {
    dispatch(clearCertsAction())

    if (!appState.isLoaded) {
      // TODO: Change to use operation
      dispatch(fetchAppsAction.started({}))
    }

    if (appState.isLoaded) {
      if (selectedAppCode === "*") {
        return
      }

      if (app) {
        dispatch(
          fetchAssetsAction.started({ appCode: app.appCode, params: {pageNum: -1} })
        )
      }
    }
  }, [app, appState.isLoaded, dispatch, selectedAppCode])

  const firstFind = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(clearCertsAction())
    dispatch(
      fetchCertsAction.started({
        appCode: selectedAppCode,
        assetCode: selectedAssetCode,
        params: {pageNum: 10}
      })
    )
    setIsTableShown(true)
  }

  const handleLoad: LoadFunction = params => {
    dispatch(
      fetchCertsAction.started({
        appCode: selectedAppCode,
        assetCode: selectedAssetCode,
        params
      })
    )
  }

  if (!appState.isLoaded) {
    return <Spinner />
  }

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "find-cert.title" })}
          </h2>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Form onSubmit={firstFind}>
            <Form.Group as={Row} controlId="formAssetId">
              <Form.Label column sm={2}>
                {intl.formatMessage({ id: "app-name" })}
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="select"
                  onChange={(e: any) => {
                    setIsTableShown(false)
                    setSelectedAppCode(e.target.value)
                  }}
                >
                  <option key="all" value="*">
                    {intl.formatMessage({ id: "find-cert.all-app" })}
                  </option>
                  {AppOptionList(appState.data.apps)}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formAssetId">
              <Form.Label column sm={2}>
                {intl.formatMessage({ id: "asset-name" })}
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="select"
                  onChange={(e: any) => {
                    setIsTableShown(false)
                    setSelectedAssetCode(e.target.value)
                  }}
                >
                  <option key="all" value="*">
                    {intl.formatMessage({ id: "find-cert.all-asset" })}
                  </option>
                  {AssetOptionList(assetState.data.assets)}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm={{ span: 4, offset: 2 }}>
                <Button variant="primary" type="submit">
                  {intl.formatMessage({ id: "find-cert.find-button" })}
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>

      {isTableShown && (
        <div>
          <CertTable
            certs={certState.data.certs}
            count={certState.data.count}
            isLoaded={certState.isLoaded}
            handleLoad={handleLoad}
          />
        </div>
      )}

      <Button
        className="mt-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}
      >
        {intl.formatMessage({ id: "back-button" })}
      </Button>
    </React.Fragment>
  )
}

const AppOptionList = (apps: Apps) => {
  return (
    <React.Fragment>
      {apps.map(app => (
        <option key={app.appCode} value={app.appCode}>
          {app.appName}
        </option>
      ))}
    </React.Fragment>
  )
}

const AssetOptionList = (assets: Assets) => {
  return (
    <React.Fragment>
      {assets.map(asset => (
        <option key={asset.assetCode} value={asset.assetCode}>
          {asset.assetName}
        </option>
      ))}
    </React.Fragment>
  )
}

export default FindCert
