import React, { createContext, useContext, useEffect, useState } from "react"
import createAuth0Client from "@auth0/auth0-spa-js"
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client"
import { axiosWithToken } from "./util"
import { useDispatch } from "react-redux"
import { storeTokenAction } from "./state/ducks/auth/actions"
import { setVendorAction } from "./state/ducks/vendor/action"
import { setUserAction, setManagerAction } from "./state/ducks/user/actions"

type Auth0Options = {
  children: React.ReactNode
  domain: string
  client_id: string
  audience: string
  redirect_uri: string
}

type AuthValue = {
  isLoading: boolean
  isAuthenticated: boolean
  token: string
  auth0Client: Auth0Client | null
}

const AuthContext = createContext({} as AuthValue)

export const useAuth = () => useContext<AuthValue>(AuthContext)

export const AuthProvider: React.FC<Auth0Options> = ({
  children,
  ...options
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [token, setToken] = useState("")
  const [auth0Client, setAuth0Client] = useState<Auth0Client | null>(null)

  useEffect(() => {
    const f = async () => {
      const auth0Client = await createAuth0Client(options)
      setAuth0Client(auth0Client)

      const isAuthenticated = await auth0Client.isAuthenticated()
      if (!isAuthenticated) {
        await auth0Client.loginWithPopup()
      }
      setIsAuthenticated(true)

      const token = await auth0Client.getTokenSilently()
      setToken(token)
      dispatch(storeTokenAction({ token }))

      const auth0User = await auth0Client.getUser()
      //console.log("######### manager auth0User", auth0User)

      // TODO: change to use redux saga
      const res = await axiosWithToken(token).get(
        `/managers/${auth0User.sub.slice(6)}?appCode=*`
      )
      //console.log("######### manager res", res)

      if (!res.data) {
        //console.log("######### Start to register manager:setManagerAction")
        dispatch(
          setManagerAction({
            auth0Id: auth0User.sub.slice(6),
            vendorId: auth0User.profile.vendorId,
            vendorName: auth0User.profile.vendorName,
            email: auth0User.email
          })
        )
        return
      } else {
        // 2021/08/25 Auth0からデータを取得する。
        //res.data.name = auth0User.name
        res.data.email = auth0User.email
      }
      dispatch(setManagerAction(null))
      dispatch(setVendorAction(res.data.vendor))
      dispatch(setUserAction(res.data))

      setIsLoading(false)
    }

    f()
    // eslint-disable-next-line
  }, [])

  return (
    <AuthContext.Provider
      value={{ isLoading, isAuthenticated, auth0Client, token }}>
      {children}
    </AuthContext.Provider>
  )
}
