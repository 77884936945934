import { AppsData } from "./types"
import { State } from "../../store"
import { createSelector } from "reselect"
import { AppInfoState, AssetCountState } from "./reducer"

export const getIsLoaded = (state: State): boolean => state.appsReducer.isLoaded
export const getAssetCountState = (state: State): AssetCountState => state.appsReducer.assetCount
export const getdata = (state: State): AppsData => state.appsReducer.data
export const appCode = (appCode: string): string => appCode
export const getAppInfo = (state: State): AppInfoState =>
  state.appsReducer.appInfo

export const isLoadedSelector = createSelector(
  getIsLoaded,
  isLoaded => isLoaded
)

export const appsSelector = createSelector(getdata, apps => apps)
export const appSelector = (appCode: string) =>
  createSelector(getdata, ({apps}) => apps.find(app => app.appCode === appCode))

export const appInfoSelector = createSelector(getAppInfo, appInfo => appInfo)
