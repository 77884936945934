import { reducerWithInitialState } from "typescript-fsa-reducers"
import { Vendor } from "./type"
import { setVendorAction } from "./action"

export type VendorState =
  | {
      isLoaded: true
      vendor: Vendor
    }
  | {
      isLoaded: false
    }

const initialState = {
  isLoaded: false
} as VendorState

const setVendor = (
  state: VendorState,
  action: ReturnType<typeof setVendorAction>
): VendorState => {
  return {
    vendor: action.payload,
    isLoaded: true
  }
}

const reducer = reducerWithInitialState(initialState).caseWithAction(
  setVendorAction,
  setVendor
)

export default reducer
