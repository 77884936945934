import apps from "./apps"
import events from "./events"
import assets from "./assets"
import seals from "./seal_to_users"
import certs from "./certs"
import images from "./images"
import vendor from "./vendor"
import user from "./user"
import auth from "./auth"
import webhooks from "./webhooks"
import notifications from "./notifications"
import error from "./error"

export const reducers = {
  appsReducer: apps.reducers,
  eventsReducer: events.reducers,
  assetsReducer: assets.reducers,
  sealToUsersReducer: seals.reducers,
  certsReducer: certs.reducers,
  imagesReducer: images.reducers,
  vendorReducer: vendor.reducers,
  userReducer: user.reducers,
  authReducer: auth.reducers,
  webhookReducer: webhooks.reducers,
  notificationsReducer: notifications.reducers,
  errorReducer: error.reducers
}

export const sagas = [
  ...apps.sagas,
  ...events.sagas,
  ...assets.sagas,
  ...seals.sagas,
  ...certs.sagas,
  ...images.sagas,
  ...user.sagas,
  ...webhooks.sagas,
  ...error.sagas
]
