import actionCreatorFactory from "typescript-fsa"
import { Cert, CertsData, HistoriesData, HistoryFilter, CountTypes } from "./types"
import { QueryParams } from "../../../types"

export type FetchCertParams = string
export type FetchCertResults = Cert

export type FetchCertsParams = {
  appCode: string
  assetCode: string
  params: QueryParams
}
export type FetchCertsResults = CertsData

export type FetchCertsHistoryParams = QueryParams & HistoryFilter

export type AddCertParams = {
  vendorId: string
  appCode: string
  assetCode: string
  assetSeq: string
  userId: string
  sealId: string
  price: string
  currency: string
  info1: string
  info2: string
  info3: string
  info4: string
  seqFlag: boolean
//  sealPrefix: string
  sealToUserFlag: boolean
  priceFlag: boolean
  sealFlag: boolean
}
export type AddCertResults = Cert

export type TransferCertParams = {
  appCode: string
  assetCode: string
  certId: string
  oldOwnerId: string
  newOwnerId: string
  price: string
  currency: string
  priceFlag: boolean
}

export type UpdateSealParams = {
  sealId: string
  appCode: string
  assetCode: string
  certId: string
}

export type TransferCertResults = {}

export type FetchHistoryCountParam = {appCode: string}

export type FetchHistoryCountResults = CountTypes

const actionCreator = actionCreatorFactory()

export const fetchCertAction = actionCreator.async<
  FetchCertParams,
  FetchCertResults,
  Error
>("FETCH_CERT")

export const fetchCertsAction = actionCreator.async<
  FetchCertsParams,
  FetchCertsResults,
  Error
>("FETCH_CERTS")

export const fetchCertsHistoryAction = actionCreator.async<
  FetchCertsHistoryParams,
  HistoriesData,
  Error
>("FETCH_CERTS_HISTORY")

export const addCertAction = actionCreator.async<
  AddCertParams,
  AddCertResults,
  Error
>("ADD_CERT")

export const transferCertAction = actionCreator.async<
  TransferCertParams,
  void,
  Error
>("TRANSFER_CERT")

export const updateSealAction = actionCreator.async<
  UpdateSealParams,
  void,
  Error
>("UPDATE_SEAL")

export const downloadCSVAction = actionCreator.async<
  HistoryFilter,
  void,
  Error
>("DOWNLOAD_CSV")

export const clearCertsAction = actionCreator("CLEAR_CERTS")

export const fetchHistoryCountAction = actionCreator.async<
  FetchHistoryCountParam,
  FetchHistoryCountResults,
  Error
>("FETCH_HISTORY_COUNT")
