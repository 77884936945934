import { actionCreatorFactory } from "typescript-fsa"
import { User, LanguageType } from "./types"

const actionCreator = actionCreatorFactory()

export const setUserAction = actionCreator<User>("SET_USER")

export const changePasswordAction = actionCreator<User>("CHANGE_PASSWORD")

export type CreateManagerParams = {
  auth0Id: string
  vendorId: string
  vendorName: string
  name: string
  email: string
  lang: LanguageType
}

export type UpdateManagerParams = {
  user: User
  lang: LanguageType
}

export const setManagerAction = actionCreator<{
  auth0Id: string
  vendorId: string
  vendorName: string
  email: string
} | null>("SET_MANAGER")

export const createManagerAction = actionCreator.async<
  CreateManagerParams,
  void,
  Error
>("CREATE_MANAGER")

export const updateManagerAction = actionCreator.async<
  UpdateManagerParams,
  void,
  Error
>("UPDATE_MANAGER")
