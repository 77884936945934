import { reducerWithInitialState } from "typescript-fsa-reducers"
import { setErrorAction } from "./actions"

export type ErrorState = {
  hasError: boolean
  status: number
  message: string
}

const initialState = {
  hasError: false,
  status: 200,
  message: ""
}

const setError = (
  state: ErrorState,
  action: ReturnType<typeof setErrorAction>
): ErrorState => {
  return {
    ...state,
    hasError: action.payload.hasError,
    status: action.payload.status ?? state.status,
    message: action.payload.message ?? state.message
  }
}

const reducer = reducerWithInitialState(initialState).caseWithAction(
  setErrorAction,
  setError
)

export default reducer
