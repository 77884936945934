import React from "react"
import { ResponsiveLine } from "@nivo/line"

type OwnProps = {
  data: LineChartData
}

type LineChartData = {
  id: string
  color: string
  data: {
    x: string
    y: number
  }[]
}[]

const LineChart = ({ data }: OwnProps) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 30, bottom: 50, left: 30 }}
    xScale={{ type: "point" }}
    yScale={{
      type: "linear",
      min: "auto",
      max: "auto",
      stacked: true,
      reverse: false
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: "bottom",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: 36,
    }}
    gridYValues={Math.max(...data.flatMap(a => a.data.map(b => b.y)))}
    axisLeft={{
      orient: "left",
      tickValues: Math.max(...data.flatMap(a => a.data.map(b => b.y))),
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: -40,
    }}
    legends={
      [
        {
          anchor: "bottom-left",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 40,
          itemsSpacing: 20,
          itemDirection: "left-to-right",
          itemWidth: 100,
          itemHeight: 10,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    colors={{ scheme: "category10" }}
    pointSize={10}
    pointColor={{ theme: "background" }}
    pointBorderWidth={2}
    pointBorderColor={{ from: "serieColor" }}
    pointLabel="y"
    pointLabelYOffset={- 12}
    useMesh={true}
    enablePoints={false}
    enableGridX={false}
  />
)

export default LineChart
