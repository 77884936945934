import { actionCreatorFactory } from "typescript-fsa"

const actionCreator = actionCreatorFactory()

export const fetchApiKeyAction = actionCreator.async<string, string, Error>(
  "FETCH_API_KEY"
)

export const issueApiKeyAction = actionCreator.async<string, string, Error>(
  "ISSUE_API_KEY"
)

export const deleteApiKeyAction = actionCreator.async<string, void, Error>(
  "DELETE_API_KEY"
)

export type FetchWebhookResult = {
  url: string
  secretToken: string
  eventList: {
    issued: boolean
    transferred: boolean
  }
}

export const fetchWebhookAction = actionCreator.async<
  string,
  FetchWebhookResult,
  Error
>("FETCH_WEBHOOK")

export type RegisterWebhookParams = {
  appCode: string
  url: string
  eventList: string[]
}

export type UpdateWebhookParams = {
  appCode: string
  url: string
  eventList: string[]
}

export const registerWebhookAction = actionCreator.async<
  RegisterWebhookParams,
  void,
  Error
>("REGISTER_WEBHOOK")

export const updateWebhookAction = actionCreator.async<
  UpdateWebhookParams,
  void,
  Error
>("UPDATE_WEBHOOK")

export const sendPingAction = actionCreator.async<string, void, Error>(
  "SEND_PING"
)
