import React, { useState } from "react"
import { Col, Card, Form, Row, Button } from "react-bootstrap"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { createManagerAction } from "../state/ducks/user/actions"
import LangSelect from "../components/LangSelect"
import { LanguageType } from "../state/ducks/user/types"

type OwnProps = {
  auth0Id: string
  vendorId: string
  vendorName: string
  email: string
}

// TODO: move to component
const RegistManagerPage: React.FC<OwnProps> = ({
  auth0Id,
  vendorId,
  vendorName,
  email
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [name, setName] = useState("")
  const [lang, setLang] = useState<LanguageType>("ja")

  // TODO: Change to use handler
  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(
      createManagerAction.started({
        auth0Id: auth0Id,
        vendorId: vendorId,
        vendorName: vendorName,
        name,
        email,
        lang
      })
    )
  }

  return (
    <div>
      <Col sm={{ span: 12 }} className="page-card">
        <Card>
          <Card.Header>
            {intl.formatMessage({ id: "registermanager.title" })}
          </Card.Header>
          <Card.Body>
            <Form onSubmit={submit}>
              <Form.Group as={Row} controlId="formvendorId">
                <Form.Label column lg={2}>
                  {intl.formatMessage({ id: "vendor-id" })}
                </Form.Label>
                <Col lg={4}>
                  <Form.Control
                    required
                    type="text"
                    name="vendorId"
                    placeholder={intl.formatMessage({ id: "vendor-id" })}
                    value={vendorId}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formvendorname">
                <Form.Label column lg={2}>
                  {intl.formatMessage({ id: "vendor-name" })}
                </Form.Label>
                <Col lg={4}>
                  <Form.Control
                    required
                    type="text"
                    name="vendorName"
                    placeholder={intl.formatMessage({ id: "vendor-name" })}
                    value={vendorName}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formusername">
                <Form.Label column lg={2}>
                  {intl.formatMessage({ id: "manager-name" })}
                </Form.Label>
                <Col lg={4}>
                  <Form.Control
                    required
                    type="text"
                    name="name"
                    placeholder={intl.formatMessage({ id: "manager-name" })}
                    value={name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setName(e.currentTarget.value)
                    }
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formemail">
                <Form.Label column lg={2}>
                  {intl.formatMessage({ id: "email" })}
                </Form.Label>
                <Col lg={4}>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    value={email}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <LangSelect value={lang} handler={setLang} />
              <Form.Group as={Row}>
                <Col sm={{ offset: 2 }}>
                  <Button variant="primary" type="submit">
                    {intl.formatMessage({ id: "register-button" })}
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </div>
  )
}

export default RegistManagerPage
