import { axiosWithToken } from "./../../../util"
import auth0 from "auth0-js"

const webAuth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || ""
})

export const changePasswordUsecase = async (email: string) => {
  return new Promise<void>((resolve, reject) => {
    webAuth.changePassword(
      {
        connection: process.env.REACT_APP_AUTH0_CONNECTION || "",
        email
      },
      err => {
        if (err) {
          reject(err)
        }
        resolve()
      }
    )
  })
}

export const createManagerUsecase = async (
  token: string,
  data: {
    auth0Id: string
    vendorId: string
    vendorName: string
    name: string
    email: string
  }
) => {
  //console.log("######### Call API to registering manager:createManagerUsecase", data)
  await axiosWithToken(token).post("/managers?appCode=*", data)
}

export const updateManagerUsecase = async (
  token: string,
  data: {
    auth0Id: string
    lang: string
  }
) => {
  await axiosWithToken(token).put(`/managers/${data.auth0Id}?appCode=*`, data)
}
