import actionCreatorFactory from "typescript-fsa"
import { SealToUser, SealToUsersData } from "./types"
import { QueryParams } from "../../../types"

export type FetchSealToUsersParams = {
  appCode: string
  params: QueryParams
}
export type FetchSealToUsersResults = SealToUsersData

export type AddSealToUserParams = {
  title: string
  appCode: string
  data: {
  }
}
export type AddSealToUserResult = SealToUser

export type UpdateSealToUserParams = {
  title: string
  appCode: string
  data: {
    sealId: string
    userId: string
  }
}
export type UpdateSealToUserResult = SealToUser

export type DeleteSealToUserParams = {
  title: string
  appCode: string
  data: {
    sealId: string
  }
}
export type DeleteSealToUserResult = SealToUser

export type UploadSealToUserCsvParams = {
  appCode: string
  file: Blob
}
export type UploadSealToUserCsvResult = SealToUsersData

const actionCreator = actionCreatorFactory()

export const fetchSealToUsersAction = actionCreator.async<
  FetchSealToUsersParams,
  FetchSealToUsersResults,
  Error
>("FETCH_SEAL_TO_USER")

export const addSealToUserAction = actionCreator.async<
  AddSealToUserParams,
  AddSealToUserResult,
  Error
>("ADD_SEAL_TO_USER")

export const updateSealToUserAction = actionCreator.async<
  UpdateSealToUserParams,
  UpdateSealToUserResult,
  Error
>("UPDATE_SEAL_TO_USER")

export const deleteSealToUserAction = actionCreator.async<
  DeleteSealToUserParams,
  DeleteSealToUserResult,
  Error
>("DELETE_SEAL_TO_USER")

export const uploadSealToUserCsvAction = actionCreator.async<
  UploadSealToUserCsvParams,
  UploadSealToUserCsvResult,
  Error
>("UPLOAD_SEAL_TO_USER")
