import React from "react"
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import BaseTable from "./BaseTable"
import { AppsData } from "../state/ducks/apps/types"
import { toJST } from "../util"
import { useIntl } from "react-intl"
import { LoadFunction } from "../types"
import { TableColumn } from "react-data-table-component"
//import { PropertySignature } from "typescript"

interface AppDataRow {
  appCode: string
  appName: string
  latestActivityDate: string
  registeredDate: string
}

const AppTable: React.FC<AppsData & {
  handleLoad: LoadFunction
  isLoaded: boolean,
}> = ({ apps, count, isLoaded, handleLoad }) => {
  const intl = useIntl()
  const rows = apps.map(app => {
    const { appName, registeredDate, appCode, latestActivityDate } = app

    return {
      appCode,
      appName,
      latestActivityDate: latestActivityDate ? toJST(latestActivityDate) : "",
      registeredDate: toJST(registeredDate),
    }
  })

  const buttons = (row: AppDataRow) => (
    <>
      <Link key="updateAsset" to={`/apps/${row.appCode}`} className="mr-3">
        <Button size="sm" variant="primary">
          {intl.formatMessage({ id: "detail-button" })}
        </Button>
      </Link>
      <Link key="listAsset" to={`/apps/${row.appCode}/assets`} className="mr-3">
        <Button size="sm" variant="info">
          {intl.formatMessage({ id: "list-app.asset-list" })}
        </Button>
      </Link>
    </>
  )

  //const buttons = [updateApp, listAsset]

  const columns: TableColumn<AppDataRow>[] = [
    {
      name: intl.formatMessage({ id: "app-code" }),
      selector: row => row.appCode,
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "app-name" }),
      selector: row => row.appName,
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "latestActivityDate" }),
      selector: row => row.latestActivityDate,
      sortable: true
    },
    {
      name: intl.formatMessage({ id: "registeredDate" }),
      selector: row => row.registeredDate,
      sortable: true
    },
    {
      ignoreRowClick: true,
      cell: (row) => buttons(row),
    }
  ]

  return (
    <BaseTable<AppDataRow>
      tableProps={{
        columns: columns,
        data: rows
      }}
      count={count}
      isLoaded={isLoaded}
      handleLoad={handleLoad}
    />
  )
}

export default AppTable
