import React from "react"
import { useIntl, IntlShape } from "react-intl"
import { Col, Form, Row } from "react-bootstrap"
import { LanguageType } from "../state/ducks/user/types"

type OwnProps = {
  value: LanguageType
  handler: (s: LanguageType) => void
}

const LangSelect: React.FC<OwnProps> = ({ value, handler }) => {
  const intl = useIntl()

  return (
    <Form.Group as={Row} controlId="formlang">
      <Form.Label column lg={2}>
        {intl.formatMessage({ id: "lang" })}
      </Form.Label>
      <Col lg={4}>
        <Form.Control
          as="select"
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handler(e.currentTarget.value as LanguageType)
          }}>
          {/* TODO: Change to define variable */}
          {langOptions(["ja", "zh-CN", "zh-TW"], intl)}
        </Form.Control>
      </Col>
    </Form.Group>
  )
}

const langOptions = (langList: string[], intl: IntlShape) => {
  return (
    <React.Fragment>
      {langList.map(lang => (
        <option key={lang} value={lang}>
          {intl.formatMessage({ id: lang })}
        </option>
      ))}
    </React.Fragment>
  )
}

export default LangSelect
