import { axiosWithToken, convertQueryParams } from "./../../../util"
import { Asset, AssetsData } from "./types"
import { AxiosResponse } from "axios"
import { QueryParams } from "../../../types"

export const fetchAssetUsecase = async (
  token: string,
  appCode: string,
  assetCode: string
): Promise<Asset> => {
  // TODO: error handling
  const res = await axiosWithToken(token).get(
    `/assets/${assetCode}?appCode=${appCode}`
  )
  return {
    ...res.data,
    appCode
  }
}

export const fetchAssetsUsecase = async (
  token: string,
  appCode: string,
  params: QueryParams,
): Promise<AssetsData> => {
  // TODO: error handling
  const queryParams = convertQueryParams(params)
  const res: AxiosResponse<AssetsData> | void = await axiosWithToken(token).get(
    `/assets?appCode=${appCode}&${queryParams}`
  )

  if(!res) {
    return {
      assets: [],
      count: 0
    }
  }

  res.data.assets = res.data.assets.map((asset: Asset) => {
    return {
      ...asset,
      appCode
    }
  })
  return res.data
}

export const addAssetUsecase = async (
  token: string,
  appCode: string,
  data: {
    assetCode: string
    assetName: string
    assetDesc: string
    assetHash: string
    limit: string
    creator: string
    copyright: string
  }
) => {
  const res = await axiosWithToken(token).post(
    `/assets?appCode=${appCode}`,
    data
  )
  return res.data
}

export const updateAssetUsecase = async (
  token: string,
  appCode: string,
  data: {
    assetCode: string
    assetName: string
    assetDesc: string
    assetHash: string
    limit: string
    creator: string
    copyright: string
  }
) => {
  const res = await axiosWithToken(token).put(
    `/assets/${data.assetCode}?appCode=${appCode}`,
    data
  )
  return res.data
}
