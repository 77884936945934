import { reducerWithInitialState } from "typescript-fsa-reducers"
import { addAssetAction, fetchAssetsAction, updateAssetAction } from "./actions"
import { AssetsData, Assets } from "./types"

export type AssetsState = {
  isLoaded: boolean
  data: AssetsData
}

const initialState = {
  isLoaded: false,
  data: {
    assets: [] as Assets,
    count: 0
  } 
}

const fetchAssetsStarted = (
  state: AssetsState,
  action: ReturnType<typeof fetchAssetsAction.started>
): AssetsState => {
  return {
    ...state,
    isLoaded: false
  }
}

const fetchAssetsDone = (
  state: AssetsState,
  action: ReturnType<typeof fetchAssetsAction.done>
): AssetsState => {
  return {
    ...state,
    isLoaded: true,
    data: action.payload.result
  }
}

const addAssetStarted = (
  state: AssetsState,
  action: ReturnType<typeof addAssetAction.started>
): AssetsState => {
  return {
    ...state,
    isLoaded: false
  }
}

const addAssetDone = (
  state: AssetsState,
  action: ReturnType<typeof addAssetAction.done>
): AssetsState => {
  const assets = [...state.data.assets, action.payload.result]
  return {
    ...state,
    isLoaded: true,
    data: {
      assets,
      count: state.data.count
    }
  }
}

const addAssetFailed = (
  state: AssetsState,
  action: ReturnType<typeof addAssetAction.failed>
): AssetsState => {
  return {
    ...state,
    isLoaded: true
  }
}

const updateAssetStarted = (
  state: AssetsState,
  action: ReturnType<typeof addAssetAction.started>
): AssetsState => {
  return {
    ...state,
    isLoaded: false
  }
}

const updateAssetDone = (
  state: AssetsState,
  action: ReturnType<typeof addAssetAction.done>
): AssetsState => {
  const nextAssets = state.data.assets.map(asset => {
    return asset.assetCode === action.payload.result.assetCode
      ? action.payload.result
      : asset
  })

  return {
    ...state,
    isLoaded: true,
    data: {
      assets: nextAssets,
      count: state.data.count
    }
  }
}

const updateAssetFailed = (
  state: AssetsState,
  action: ReturnType<typeof addAssetAction.failed>
): AssetsState => {
  return {
    ...state,
    isLoaded: true
  }
}

const reducer = reducerWithInitialState(initialState)
  .caseWithAction(fetchAssetsAction.started, fetchAssetsStarted)
  .caseWithAction(fetchAssetsAction.done, fetchAssetsDone)
  .caseWithAction(addAssetAction.started, addAssetStarted)
  .caseWithAction(addAssetAction.done, addAssetDone)
  .caseWithAction(addAssetAction.failed, addAssetFailed)
  .caseWithAction(updateAssetAction.started, updateAssetStarted)
  .caseWithAction(updateAssetAction.done, updateAssetDone)
  .caseWithAction(updateAssetAction.failed, updateAssetFailed)

export default reducer
