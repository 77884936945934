import { reducerWithInitialState } from "typescript-fsa-reducers"
import { storeTokenAction } from "./actions"
import { Auth } from "./types"

export type AuthState = Auth

const initialState = {
  token: ""
}

const storeToken = (
  state: AuthState,
  action: ReturnType<typeof storeTokenAction>
) => {
  return {
    ...state,
    token: action.payload.token
  }
}

const reducer = reducerWithInitialState(initialState).caseWithAction(
  storeTokenAction,
  storeToken
)

export default reducer
