import { reducerWithInitialState } from "typescript-fsa-reducers"
import {
  displayModalAction,
  displayToastAction,
  displayErrorModalAction,
  hideModalAction,
  hideToastAction,
  setProcessingAction
} from "./actions"

export type NotificationsState = {
  isDisplayed: boolean
  isModalDisplayed: boolean
  isErrorModalDisplayed: boolean
  title: string
  body: string
  count?: number
  param?: string
  resource?: string
  type: "default" | "success" | "error"
  isProcessing: boolean
}

const initialState = {
  isDisplayed: false,
  isModalDisplayed: false,
  isErrorModalDisplayed: false,
  title: "",
  body: "",
  count: 0,
  type: "default",
  param: "",
  resource: "",
  isProcessing: false
} as NotificationsState

const displayToast = (
  state: NotificationsState,
  action: ReturnType<typeof displayToastAction>
): NotificationsState => {
  return {
    ...state,
    isDisplayed: true,
    body: action.payload.code,
    param: action.payload.param,
    resource: action.payload.resource,
    type: action.payload.type
  }
}

const hideToast = (
  state: NotificationsState,
  action: ReturnType<typeof hideToastAction>
): NotificationsState => {
  return {
    ...state,
    isDisplayed: false
  }
}

const displayModal = (
  state: NotificationsState,
  action: ReturnType<typeof displayModalAction>
): NotificationsState => {
  return {
    ...state,
    isModalDisplayed: true,
    title: action.payload.title || "",
    body: action.payload.body || ""
  }
}

const displayErrorModal = (
  state: NotificationsState,
  action: ReturnType<typeof displayErrorModalAction>
): NotificationsState => {
  return {
    ...state,
    isErrorModalDisplayed: true,
    title: action.payload.title || "",
    body: action.payload.body || "",
    count: action.payload.count || 0
  }
}

const hideModal = (
  state: NotificationsState,
  action: ReturnType<typeof hideModalAction>
): NotificationsState => {
  return {
    ...state,
    isModalDisplayed: false,
    isErrorModalDisplayed: false
  }
}

const setProcessing = (
  state: NotificationsState,
  action: ReturnType<typeof setProcessingAction>
): NotificationsState => {
  return {
    ...state,
    isProcessing: action.payload
  }
}

const reducer = reducerWithInitialState(initialState)
  .caseWithAction(displayToastAction, displayToast)
  .caseWithAction(hideToastAction, hideToast)
  .caseWithAction(displayModalAction, displayModal)
  .caseWithAction(hideModalAction, hideModal)
  .caseWithAction(setProcessingAction, setProcessing)
  .caseWithAction(displayErrorModalAction, displayErrorModal)

export default reducer
