/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React from "react"
import ReactPaginate from "react-paginate"
import { useIntl } from "react-intl"

type OwnProps = {
  rowsPerPage: number
  rowCount: number
  onChangePage: (e: { selected: number }) => void
  currentPage: number
}

const Pagination: React.FC<OwnProps> = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage
}) => {
  const intl = useIntl()
  const pageCount =
    rowCount % rowsPerPage === 0
      ? rowCount / rowsPerPage
      : Math.floor(rowCount / rowsPerPage) + 1
  // fromが全レコード数より多くなる計算になってしまうときはfrom=1にする
  const from = currentPage * rowsPerPage > rowCount ? 1 : currentPage * rowsPerPage || 1
  const to =
    (currentPage + 1) * rowsPerPage > rowCount
      ? rowCount
      : currentPage * rowsPerPage + rowsPerPage

  const handleChangePage = (e: { selected: number }) => onChangePage(e)

  return (
    <div
      className="mt-4 d-flex justify-content-center"
      css={css`
        .display-info {
          position: absolute;
          margin-top: 5px;
          left: 15px;
        }
      `}
    >
      <div className="display-info">
        {intl.formatMessage({ id: "base-table.info-label" })} {from} ~ {to}{" "}
        {intl.formatMessage({ id: "base-table.info-all-label" })} {rowCount}{" "}
        {intl.formatMessage({ id: "base-table.info-count-label" })}
      </div>
      <ReactPaginate
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        // fromが全レコード数より多くなる計算になってしまうときpage=1にする
        forcePage={currentPage * rowsPerPage > rowCount ? 0 : currentPage}
        onPageChange={handleChangePage}
        containerClassName="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        activeClassName="active"
        previousLabel={intl.formatMessage({ id: "base-table.pagination-prev-label" })}
        nextLabel={intl.formatMessage({ id: "base-table.pagination-next-label" })}
        previousClassName="page-item"
        nextClassName="page-item"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        disabledClassName="disabled"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
      />
    </div>
  )
}

export default Pagination
