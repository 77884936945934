import actionCreatorFactory from "typescript-fsa"
import { AppInfo, AppsData } from "./types"
import { QueryParams } from "../../../types"

type FetchAppsResults = AppsData

const actionCreator = actionCreatorFactory()

export const fetchAppsAction = actionCreator.async<
  QueryParams,
  FetchAppsResults,
  Error
>("FETCH_APPS")

export type FetchAppInfoParams = {
  appCode: string
}
type FetchAppInfoResults = AppInfo

export const fetchAppInfoAction = actionCreator.async<
  FetchAppInfoParams,
  FetchAppInfoResults,
  Error
>("FETCH_APP_INFO")

export type FetchAssetCountParams = {appCode: string}
type FetchAssetCountResults = {count: number}

export const fetchAssetCountAction = actionCreator.async<
  FetchAssetCountParams,
  FetchAssetCountResults,
  Error
>("FETCH_ASSET_COUNT")
