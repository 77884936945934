import React from "react"
import { IntlProvider } from "react-intl"
import { useSelector } from "react-redux"
import locale_ja from "../locales/ja.json"
import locale_zh_cn from "../locales/zh-cn.json"
import locale_zh_tw from "../locales/zh-tw.json"
import { userSelector } from "../state/ducks/user/selectors"
import { LanguageType, isLanguageType } from "../state/ducks/user/types"

const messages = {
  ja: locale_ja,
  "zh-CN": locale_zh_cn,
  "zh-TW": locale_zh_tw
}
interface Props {
  children: React.ReactNode;
}

const I18nProvider: React.FC<Props> = (props) => {
  const user = useSelector(userSelector)
  const defaultLanguage = isLanguageType(window.navigator.language)
    ? window.navigator.language
    : "ja"
  const lang: LanguageType = user ? user.lang : defaultLanguage

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      {props.children}
    </IntlProvider>
  )
}

export default I18nProvider
