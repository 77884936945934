/** @jsxImportSource @emotion/react */
import "bootstrap/dist/css/bootstrap.min.css"
import { ConnectedRouter } from "connected-react-router"
import { Global } from "@emotion/react"
import { css } from "@emotion/react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { AuthProvider } from "./Auth"
import configureStore, { history } from "./state/store"
import I18nProvider from "./containers/provider"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"

const globalStyles = css`
  html {
    font-size: 14px;
  }

  html,
  body,
  #root {
    height: 100vh;
  }

  .row {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .page-card {
    margin-top: 30px;
  }

  .card-header > .card-title {
    display: inline-block;
    padding: 0.375rem 0;
    margin-bottom: 0;
  }
`

//console.log("PUBLIC_URL", process.env.PUBLIC_URL)
//console.log("REACT_APP_AUTH0_CLIENT_ID", process.env.REACT_APP_AUTH0_CLIENT_ID)

const store = configureStore()

const persistor = persistStore(store)
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
)
root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AuthProvider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
        client_id={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE || ""}
        redirect_uri={process.env.REACT_APP_AUTH0_REDIRECT_URI || ""}>
        <Global styles={globalStyles} />
        <I18nProvider>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </I18nProvider>
      </AuthProvider>
    </ConnectedRouter>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
