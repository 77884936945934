import { axiosWithToken } from "./../../../util"
import { Image, Images } from "./types"
import { Buffer } from "buffer"

export const fetchImagesUsecase = async (
  token: string,
  appCode: string,
  assetCode: string
): Promise<Images> => {
  const a = [1, 2, 3]
  const images: Promise<Images> = Promise.all(
    a.map(
      async (i: number): Promise<Image> => {
        try {
          const res = await axiosWithToken(token).get(
            `/assets/images/${assetCode}/${i}?appCode=${appCode}`,
            {
              responseType: "arraybuffer",
              headers: {
                "Content-Type": "image/*"
              }
            }
          )

          const buf = Buffer.from(res.data)
          return arraybufToImage(buf)
        } catch (e) {
          return ""
        }
      }
    )
  )
  return images
}

export const addImageUsecase = async (
  token: string,
  vendorId: string,
  appCode: string,
  assetCode: string,
  imageIndex: string,
  file: Blob
) => {
  const formData = new FormData()

  formData.append("vendorId", vendorId)
  formData.append("appCode", appCode)
  formData.append("assetCode", assetCode)
  formData.append("imageIndex", imageIndex)
  formData.append("file", file)

  await axiosWithToken(token).post(
    `/assets/images?appCode=${appCode}`,
    formData,
    {
      headers: {
        "content-type": "multipart/form-data"
      }
    }
  )
}

export const deleteImageUsecase = async (
  token: string,
  vendorId: string,
  appCode: string,
  assetCode: string,
  imageIndex: string
) => {
  const config = {
    data: {
      vendorId,
      appCode,
      assetCode,
      imageIndex
    }
  }
  await axiosWithToken(token).delete(
    `/assets/images?appCode=${appCode}`,
    config
  )
}

const arraybufToImage = (buf: Buffer) => {
  let binary = ""
  for (let i in buf) {
    binary += String.fromCharCode(buf[i])
  }
  return "data:image/jpeg;base64," + window.btoa(binary)
}
