import { reducerWithInitialState } from "typescript-fsa-reducers"
import { fetchAppsAction, fetchAppInfoAction, fetchAssetCountAction } from "./actions"
import { AppInfo, AppsData } from "./types"

export type AppsState = {
  isLoaded: boolean
  data: AppsData
  appInfo: AppInfoState
  assetCount: AssetCountState
}

export type AssetCountState = {
  isLoaded: boolean
  count: number
}

export type AppInfoState =
  | {
      isLoaded: false
    }
  | {
      isLoaded: true
      data: AppInfo
    }

const initialState: AppsState = {
  isLoaded: false,
  data: {
    apps: [],
    count: 0
  },
  appInfo: { isLoaded: false },
  assetCount: {
    isLoaded: false,
    count: 0
  }
}

const fetchAppsStarted = (state: AppsState, _: unknown): AppsState => {
  return {
    ...state,
    isLoaded: false,
    data: {
      apps: [],
      count: 0
    }
  }
}

const fetchAppsDone = (
  state: AppsState,
  action: ReturnType<typeof fetchAppsAction.done>
): AppsState => {
  return {
    ...state,
    isLoaded: true,
    data: action.payload.result
  }
}

const fetchAppInfoStarted = (state: AppsState, _: unknown): AppsState => {
  return {
    ...state,
    appInfo: {
      isLoaded: false
    }
  }
}

const fetchAppInfoDone = (
  state: AppsState,
  action: ReturnType<typeof fetchAppInfoAction.done>
): AppsState => {
  return {
    ...state,
    appInfo: {
      isLoaded: true,
      data: action.payload.result
    }
  }
}

const fetchAssetCountStarted = (  
  state: AppsState,
  action: ReturnType<typeof fetchAssetCountAction.started>
): AppsState => {
  return {
    ...state,
    assetCount: {
      isLoaded: false,
      count: 0
    }
  }
}

const fetchAssetCountDone = (  
  state: AppsState,
  action: ReturnType<typeof fetchAssetCountAction.done>
): AppsState => {
  return {
    ...state,
    assetCount: {
      isLoaded: true,
      count: action.payload.result.count
    }
  }
}

const reducer = reducerWithInitialState(initialState)
  .caseWithAction(fetchAppsAction.started, fetchAppsStarted)
  .caseWithAction(fetchAppsAction.done, fetchAppsDone)
  .caseWithAction(fetchAppInfoAction.started, fetchAppInfoStarted)
  .caseWithAction(fetchAppInfoAction.done, fetchAppInfoDone)
  .caseWithAction(fetchAssetCountAction.started, fetchAssetCountStarted)
  .caseWithAction(fetchAssetCountAction.done, fetchAssetCountDone)

export default reducer
