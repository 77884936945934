import { User } from "./types"
import { State } from "../../store"
import { createSelector } from "reselect"

export const getIsLoaded = (state: State): boolean => state.userReducer.isLoaded
export const isLoadedSelector = createSelector(
  getIsLoaded,
  isLoaded => isLoaded
)

export const user = (state: State): User | null =>
  state.userReducer.isLoaded ? state.userReducer.user : null
export const userSelector = createSelector(user, user => user)

const isSet = (state: State): boolean => state.userReducer.isSet
export const isSetSelector = createSelector(isSet, manager => manager)

const isManager = (state: State): any | null =>
  state.userReducer.isSet ? state.userReducer.manager : null
export const managerSelector = createSelector(isManager, manager => manager)
