import React, { useEffect } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useIntl } from "react-intl"
import { RouteComponentProps } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import AssetTable from "../components/AssetTable"
import Spinner from "../components/Spinner"
import { fetchAssetsAction } from "../state/ducks/assets/actions"
import { State } from "../state/store"
import { Link } from "react-router-dom"
import { fetchAppsOps } from "../state/ducks/apps/operations"
import { appSelector, isLoadedSelector } from "../state/ducks/apps/selectors"
import { LoadFunction } from "../types"
import PrevLink from "../components/PrevLink"

const ListAssetPage: React.FC<RouteComponentProps<{ appCode: string }>> = ({
  match,
  history
}) => {
  const { appCode } = match.params
  const dispatch = useDispatch()
  const intl = useIntl()
  const isLoaded = useSelector(isLoadedSelector)
  const app = useSelector(appSelector(appCode))
  const assetState = useSelector((state: State) => state.assetsReducer)

  const assets = assetState.data.assets.filter(
    asset => asset.appCode === appCode
  )

  useEffect(() => {
    dispatch(fetchAppsOps({}))
    dispatch(fetchAssetsAction.started({ appCode, params: {pageNum: 10} }))
  }, [dispatch, appCode])

  if (!isLoaded) {
    return <Spinner />
  }

  if (!app) {
    // TODO: 見せ方を考える
    return <h1>{intl.formatMessage({ id: "app-notfound" })}</h1>
  }

  if (!assets) {
    // TODO: 見せ方を考える
    return <h1>{intl.formatMessage({ id: "asset-notfound" })}</h1>
  }

  const handleLoad: LoadFunction = params => {
    dispatch(fetchAssetsAction.started({ appCode, params }))
  }

  return (
    <>
      <PrevLink to={`/apps/${appCode}`} label={app.appName} />
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "list-asset.title" })}
          </h2>
        </Col>
        <Col>
          <Link to={`/apps/${appCode}/add_asset`}>
            <Button className="float-right">
              <p className="d-inline-block ml-0 mb-0">
                {intl.formatMessage({ id: "list-asset.add-asset" })}
              </p>
            </Button>
          </Link>
        </Col>
      </Row>
      <AssetTable
        assets={assetState.data.assets}
        count={assetState.data.count}
        isLoaded={assetState.isLoaded}
        handleLoad={handleLoad}
      />
      <Row>
        <Col sm={{ span: 2 }}>
          <Button variant="outline-secondary" onClick={() => history.goBack()}>
            {intl.formatMessage({ id: "back-button" })}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ListAssetPage
