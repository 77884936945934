/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React from "react"
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap"
import { useIntl } from "react-intl"
import { Images } from "../state/ducks/images/types"
import noimage from "./noimage.png"

const style = (isThumbnail: boolean) =>
  css(`
    max-width: ${isThumbnail ? 400 : 600}px;
    max-height: ${isThumbnail ? 400 : 600}px;
    padding: 0;
  `)

type OwnProps = {
  images: Images
  isThumbnail?: boolean
  handleSubmit: (e: React.FormEvent<Element>) => void
  handleChange: (e: any) => void
  handleDelete: (e: any) => void
}

const ImageList: React.FC<OwnProps> = ({
  images,
  isThumbnail = false,
  handleSubmit,
  handleChange,
  handleDelete
}) => {
  const intl = useIntl()
  return (
    <Row className="mt-4">
      {images.map((image, i) => (
        <Col xs={5} md={4} key={i}>
          <Card bg="light" className="pb-3" css={style(isThumbnail)}>
            <Image
              src={image || noimage}
              css={css`
                height: 400px;
                object-fit: scale-down;
              `}
            />
            <Card.Body>
              <Card.Title>
                {intl.formatMessage({ id: "image" })}
                {i + 1}
              </Card.Title>
              <Form>
                <Form.Group controlId="formFile1">
                  <Form.Control
                    type="file"
                    name="images"
                    onChange={handleChange}
                    accept="image/*"
                  />
                </Form.Group>
                <Row>
                  <Col xs={16} md={7}>
                    <Button
                      variant="primary"
                      value={i + 1}
                      onClick={handleSubmit}>
                      {intl.formatMessage({ id: "upload-button" })}
                    </Button>
                  </Col>
                  <Col xs={{ span: 12, offset: 1 }} md={{ span: 4, offset: 1 }}>
                    <Button
                      variant="danger"
                      value={i + 1}
                      onClick={handleDelete}>
                      {intl.formatMessage({ id: "delete-button" })}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default ImageList
