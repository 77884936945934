import { fetchEventUsecase, fetchEventsUsecase } from "./usecases"
import { call, put, select, takeEvery } from "redux-saga/effects"
import { Action } from "typescript-fsa"
import {
  FetchEventParams,
  FetchEventsParams,
  fetchEventAction,
  fetchEventsAction
} from "./actions"
import { EventsData } from "./types"
import { invalidTokenAction } from "../error/actions"

function* fetchEventSaga(action: Action<FetchEventParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)

    const event = yield call(fetchEventUsecase, token, action.payload)

    yield put(
      fetchEventAction.done({
        params: action.payload,
        result: event
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(fetchEventAction.failed(e))
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

function* fetchEventsSaga(action: Action<FetchEventsParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)

    const { appCode, eventType, params } = action.payload
    const events: EventsData = yield call(
      fetchEventsUsecase,
      token,
      appCode,
      eventType,
      params
    )

    yield put(
      fetchEventsAction.done({
        params: action.payload,
        result: events
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(fetchEventsAction.failed(e))
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

const sagas = [
  takeEvery(fetchEventAction.started, fetchEventSaga),
  takeEvery(fetchEventsAction.started, fetchEventsSaga)
]

export default sagas
