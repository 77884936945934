import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { RouteComponentProps, Link } from "react-router-dom"
import { Row, Button, Col, Card, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { historyCountSelector } from "../state/ducks/certs/selectors"
import { fetchHistoryCountAction } from "../state/ducks/certs/actions"
import { getAssetCountState, appSelector } from "../state/ducks/apps/selectors"
import { fetchAssetCountAction } from "../state/ducks/apps/actions"
import { useIntl, IntlShape } from "react-intl"
import { fetchAppsOps } from "../state/ducks/apps/operations"
import { getMonthRangeStr } from "../util"
import LineChart from "../components/LineChart"
import BarChart from "../components/BarChart"

type CountResponse = {
  issue?: number
  transfer?: number
  activate?: number
}

type MonthRangeStr = {
  from: string
  to: string
}

const DisplayCount = (count: number | undefined, label: string) => (
  <Col>
    <div>
      <h2 className="text-center">{count ?? 0}</h2>
    </div>
    <div className="text-center font-weight-bold text-nowrap">{label}</div>
  </Col>
)

const DisplayHistoryCount = (counts: CountResponse, intl: IntlShape) => (
  <React.Fragment>
    {DisplayCount(
      counts.issue,
      intl.formatMessage({ id: "detail-app.issue-label" })
    )}
    {DisplayCount(
      counts.transfer,
      intl.formatMessage({ id: "detail-app.transfer-label" })
    )}
  </React.Fragment>
)

const DisplayHistoryCountForSealNumber = (counts: CountResponse, intl: IntlShape) => (
  <React.Fragment>
    {DisplayCount(
      counts.issue,
      intl.formatMessage({ id: "detail-app.issue-label" })
    )}
    {DisplayCount(
      counts.transfer,
      intl.formatMessage({ id: "detail-app.transfer-label" })
    )}
    {DisplayCount(
      counts.activate,
      intl.formatMessage({ id: "detail-app.activate-label" })
    )}
  </React.Fragment>
)

const Dashboard: React.FC<RouteComponentProps<{ appCode: string }>> = ({
  match
}) => {
  const { appCode } = match.params
  const dispatch = useDispatch()
  const historyCount = useSelector(historyCountSelector)
  const assetCount = useSelector(getAssetCountState)
  const app = useSelector(appSelector(appCode))
  const [monthRange, setMonthRange] = useState<MonthRangeStr>()
  const intl = useIntl()

  const localized = {
    activate: intl.formatMessage({ id: "detail-app.activate-label" }),
    transfer: intl.formatMessage({ id: "detail-app.transfer-label" }),
    issue: intl.formatMessage({ id: "detail-app.issue-label" })
  }

  useEffect(() => {
    setMonthRange(getMonthRangeStr(new Date()))
    dispatch(fetchAppsOps({}))
    dispatch(fetchHistoryCountAction.started({ appCode }))
    dispatch(fetchAssetCountAction.started({ appCode }))
  }, [appCode, dispatch])

  const h = Object.entries(historyCount?.counts.lastWeekCount ?? {}).map(([key, value]) => {
    const date = dayjs(key).format("MM/DD")

    return [
      {
        id: "transfer",
        color: "blue",
        data: {
          x: date,
          y: value?.transfer ?? 0
        }
      },
      {
        id: "issue",
        color: "blue",
        data: {
          x: date,
          y: value?.issue ?? 0
        }
      },
      {
        id: "activate",
        color: "blue",
        data: {
          x: date,
          y: value?.activate ?? 0
        }
      },
    ]
  })

  const data = (app?.sealFlag ? [{
    id: localized["activate"],
    color: "blue",
    data: h.flat().filter(a => a.id === "activate").map(a => a.data)
  }] : []).concat([{
    id: localized["transfer"],
    color: "blue",
    data: h.flat().filter(a => a.id === "transfer").map(a => a.data)
  },
  {
    id: localized["issue"],
    color: "blue",
    data: h.flat().filter(a => a.id === "issue").map(a => a.data)
  }])

  const barData = Object.entries(historyCount?.counts.lastSixMonthCount ?? {}).map(([key, value]) => {
    return {
      [localized["activate"]]: value?.activate ?? 0,
      [localized["transfer"]]: value?.transfer ?? 0,
      [localized["issue"]]: value?.issue ?? 0,
      date: dayjs(key).format("YY/MM")
    } as any
  })

  // console.log(historyCount)
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between mx-0 my-3">
        <div>
          <h2>
            {intl.formatMessage({ id: "detail-app.title" })}: {app?.appName}
          </h2>
        </div>
        <div>
          {!!app?.sealToUserFlag && (
            <Link to={`/apps/${appCode}/seal_to_users`} className="mr-3">
              <Button variant="success">
                {intl.formatMessage({ id: "detail-app.seal-management-button" })}
              </Button>
            </Link>
          )}
          <Link to={`/apps/${appCode}/system_setting`}>
            <Button>
              {intl.formatMessage({ id: "detail-app.system-setting-button" })}
            </Button>
          </Link>
        </div>
      </div>
      <Row>
        <Col md="4" className="mt-2">
          <Card className="px-4 pt-4">
            <Card.Title className="font-weight-bold">
              {intl.formatMessage({ id: "detail-app.certs-this-month" })}
            </Card.Title>
            <Card.Body>
              <Row className="justify-content-center">
                {historyCount.isLoaded && assetCount.isLoaded ? (
                  app?.sealFlag ?
                    DisplayHistoryCountForSealNumber(historyCount.counts.thisMonthCount, intl) :
                    DisplayHistoryCount(historyCount.counts.thisMonthCount, intl)
                ) : (
                    <Spinner animation="border" />
                  )}
              </Row>
              <Row className="mt-4 justify-content-center">
                <Link to={`/history?appCode=${appCode}&from=${monthRange?.from}&to=${monthRange?.to}`} className="w-100">
                  <Button className="w-100" variant="success">
                    {intl.formatMessage({
                      id: "detail-app.transaction-history"
                    })}
                  </Button>
                </Link>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4" className="mt-2">
          <Card className="px-4 pt-4">
            <Card.Title className="font-weight-bold">
              {intl.formatMessage({ id: "detail-app.certs-all-time" })}
            </Card.Title>
            <Card.Body>
              <Row className="justify-content-center">
                {historyCount.isLoaded && assetCount.isLoaded ? (
                  app?.sealFlag ?
                    DisplayHistoryCountForSealNumber(historyCount.counts.allHistoriesCounts, intl) :
                    DisplayHistoryCount(historyCount.counts.allHistoriesCounts, intl)
                ) : (
                    <Spinner animation="border" />
                  )}
              </Row>
              <Row className="mt-4 justify-content-center">
                <Link to={`/history?appCode=${appCode}`} className="w-100">
                  <Button className="w-100" variant="success">
                    {intl.formatMessage({
                      id: "detail-app.transaction-history"
                    })}
                  </Button>
                </Link>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4" className="mt-2">
          <Card className="px-4 pt-4">
            <Card.Title className="font-weight-bold">
              {intl.formatMessage({ id: "detail-app.asset" })}
            </Card.Title>
            <Card.Body>
              <Row className="justify-content-center">
                {historyCount.isLoaded && assetCount.isLoaded ? (
                  DisplayCount(
                    assetCount.count,
                    intl.formatMessage({ id: "detail-app.asset-count-label" })
                  )
                ) : (
                    <Spinner animation="border" />
                  )}
              </Row>
              <Row className="mt-4 justify-content-center">
                <Link to={`/apps/${appCode}/assets`} className="w-100">
                  <Button className="w-100" variant="success">
                    {intl.formatMessage({ id: "detail-app.asset-list" })}
                  </Button>
                </Link>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6} xs={12} className="mt-3">
          <Card>
            <Card.Body>
              <div className="font-weight-bold">{intl.formatMessage({ id: "dashboard.daily_certs" })}</div>
              <div style={{ maxWidth: "100%", height: "300px" }}>
                <LineChart data={data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} xs={12} className="mt-3">
          <Card>
            <Card.Body>
              <div className="font-weight-bold">{intl.formatMessage({ id: "dashboard.monthly_certs" })}</div>
              <div style={{ maxWidth: "100%", height: "300px" }}>
                <BarChart data={barData} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Dashboard
