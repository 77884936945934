import React, { useEffect } from "react"
import { RouteComponentProps } from "react-router"
import { Button, Card, Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import ImageList from "../components/ImageList"
import OwnerLog from "../components/OwnerLog"
import { fetchCertAction } from "../state/ducks/certs/actions"
import { State } from "../state/store"
import { toJST } from "../util"
import { fetchImagesAction } from "../state/ducks/images/actions"
import { fetchAssetsAction } from "../state/ducks/assets/actions"
import { Link } from "react-router-dom"
import { useIntl } from "react-intl"
import Spinner from "../components/Spinner"
import { appSelector, isLoadedSelector } from "../state/ducks/apps/selectors"
import { fetchAppsOps } from "../state/ducks/apps/operations"
import PrevLink from "../components/PrevLink"
import * as Util from "../util"

const DetailCert: React.FC<RouteComponentProps<{
  appCode: string
  assetCode: string
  certId: string
}>> = ({ match, history }) => {
  const { appCode, assetCode, certId } = match.params
  const dispatch = useDispatch()
  const isLoaded = useSelector(isLoadedSelector)
  const app = useSelector(appSelector(appCode))
  const intl = useIntl()
  const assetState = useSelector((state: State) => state.assetsReducer)
  const cert = useSelector((state: State) => state.certsReducer.cert)
  const imageState = useSelector((state: State) => state.imagesReducer)

  const asset = assetState.data.assets.find(
    asset => asset.assetCode === assetCode
  )
  const vendorState = useSelector((state: State) => state.vendorReducer)
  const vendorId = (vendorState.isLoaded && vendorState.vendor.vendorId) as string
  const isDnpKyoto2 = Util.isDnpKyoto2(vendorId, appCode)

  useEffect(() => {
    dispatch(fetchCertAction.started(certId))

    dispatch(
      fetchImagesAction.started({
        appCode,
        assetCode
      })
    )

    dispatch(fetchAppsOps({}))
    dispatch(fetchAssetsAction.started({ appCode, params: {} }))
  }, [dispatch, certId, appCode, assetCode])

  if (!(isLoaded && assetState.isLoaded)) {
    return <Spinner />
  }
  console.log("DetailCert cert", cert)

  return (
    <>
      <PrevLink to={`/apps/${appCode}/assets/${assetCode}`} label={asset ? asset.assetName : ""} />
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "detail-cert.title" })}
          </h2>
        </Col>
        <Col>
          <Link
            to={`/apps/${appCode}/assets/${assetCode}/certs/${certId}/transfer`}
            className="float-right"
          >
            <Button className="btn-info">
              {intl.formatMessage({ id: "detail-cert.transfer-cert" })}
            </Button>
          </Link>
          {cert && cert.sealId && (
            <Link
              to={`/apps/${appCode}/assets/${assetCode}/certs/${certId}/seal_update`}
              className="float-right mr-4"
            >
              <Button className="btn-success">
                {intl.formatMessage({ id: "detail-cert.update-seal" })}
              </Button>
            </Link>
          )}
        </Col>
      </Row>
      {cert && (
        <Row className="mb-4">
          <Col>
            <Row>
              <Col sm={8}>
                <Card>
                  <Card.Header>
                    {intl.formatMessage({ id: "detail-cert.cert" })}
                  </Card.Header>
                  <Card.Body className="profile">
                    <Row>
                      <Col md={3}>{intl.formatMessage({ id: "cert-id" })}:</Col>
                      <Col>
                        <span>{cert.certId}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        {intl.formatMessage({ id: "transaction-id" })}:
                      </Col>
                      <Col>
                        <span>{cert.txId}</span>
                      </Col>
                    </Row>
                    {cert.sealId && (
                      <Row>
                        <Col md={3}>
                          {intl.formatMessage({ id: "seal-id" })}:
                        </Col>
                        <Col>
                          <span>{cert.sealId}</span>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col md={3}>{intl.formatMessage({ id: "hashed-user-id" })}:</Col>
                      <Col>
                        <span>{cert.userId}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>{intl.formatMessage({ id: "user-id" })}:</Col>
                      <Col>
                        <span>{cert.originalUserId}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>{intl.formatMessage({ id: "price" })}:</Col>
                      <Col>
                        <span>{cert.price}</span>
                        {cert.currency && (
                          <span>
                            {intl.formatMessage({ id: cert.currency })}
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>{intl.formatMessage({ id: "issuer" })}:</Col>
                      <Col>
                        <span>
                          {cert.issuer ||
                            intl.formatMessage({ id: "settings-not-found" })}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        {intl.formatMessage({ id: "registeredDate" })}:
                      </Col>
                      <Col>
                        <span>{toJST(cert.registeredDate)}</span>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4} className="mt-4 mt-sm-0">
                <Card>
                  <Card.Header>
                    {app && <Link to={`/apps/${appCode}`}>{app.appName}</Link>}
                    <span> / </span>
                    {asset && (
                      <Link to={`/apps/${appCode}/assets/${assetCode}`}>
                        {asset.assetName}
                      </Link>
                    )}
                  </Card.Header>
                  <Card.Body className="profile">
                    <Row>
                      <Col md={4}>
                        {intl.formatMessage({ id: "asset-sequence" })}:
                      </Col>
                      <Col>
                        <span>{cert.assetSeq}</span>
                      </Col>
                    </Row>
                    {!!app?.seriesFlag && (
                      <Row>
                        <Col md={4}>
                          {isDnpKyoto2? intl.formatMessage({ id: "dnp_asset_series" }) : intl.formatMessage({ id: "series" })}:
                        </Col>
                        <Col>
                          <span>{cert.series}</span>
                        </Col>
                      </Row>
                    )}
                    {!isDnpKyoto2 && (
                      <Row>
                        <Col md={4}>{intl.formatMessage({ id: "creator" })}:</Col>
                        <Col>
                          <span>{cert.creator}</span>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col md={4}>
                        {isDnpKyoto2? intl.formatMessage({ id: "dnp_asset_copyright" }) : intl.formatMessage({ id: "copyright" })}:
                      </Col>
                      <Col>
                        <span>{cert.copyright}</span>
                      </Col>
                    </Row>
                    {isDnpKyoto2 && (
                      <>
                        <Row>
                          <Col md={4}>{intl.formatMessage({ id: "dnp_cert_info1" })}:</Col>
                          <Col>
                            <span>{cert.info1}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>{intl.formatMessage({ id: "dnp_cert_info2" })}:</Col>
                          <Col>
                            <span>{cert.info2}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>{intl.formatMessage({ id: "dnp_cert_info3" })}:</Col>
                          <Col>
                            <span>{cert.info3}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>{intl.formatMessage({ id: "dnp_cert_info4" })}:</Col>
                          <Col>
                            <span>{cert.info4}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>{intl.formatMessage({ id: "dnp_asset_memo" })}:</Col>
                          <Col>
                            <div style={{whiteSpace: "pre-line"}}>{cert.memo}</div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="mt-4">
                <Card>
                  <Card.Header>
                    {intl.formatMessage({ id: "detail-cert.ownerlog" })}
                  </Card.Header>
                  {OwnerLog(intl, cert.ownerLog)}
                </Card>
              </Col>
            </Row>
            {imageState.isLoaded && <ImageList images={imageState.images} />}
          </Col>
        </Row>
      )}
      <Button
        className="my-3"
        variant="outline-secondary"
        onClick={() => history.goBack()}
      >
        {intl.formatMessage({ id: "back-button" })}
      </Button>
    </>
  )
}

export default DetailCert
