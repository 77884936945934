/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React from "react"
import { Container } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Route, Switch } from "react-router-dom"
import Header from "./components/Header"
import Toast from "./components/Toast"
import Modal from "./components/Modal"
import ErrorModal from "./components/ErrorModal"
import ListAppPage from "./pages/ListApp"
import ListAssetPage from "./pages/ListAsset"
import AddAssetPage from "./pages/AddAsset"
import UpdateAssetPage from "./pages/UpdateAsset"
import DetailAssetPage from "./pages/DetailAsset"
import FindCertPage from "./pages/FindCert"
import DetailCertPage from "./pages/DetailCert"
import TransferCertPage from "./pages/TransferCert"
import AddCertPage from "./pages/AddCert"
import ListEventPage from "./pages/ListEvent"
import DetailEventPage from "./pages/DetailEvent"
import DetailAppPage from "./pages/DetailApp"
import UpdateSealPage from "./pages/UpdateSeal"
import ProfilePage from "./pages/Profile"
import RegistManagerPage from "./pages/RegistManager"
import HistoryPage from "./pages/History"
import ListSealToUserPage from "./pages/ListSealToUser"
import UpdateSealToUserPage from "./pages/UpdateSealToUser"
import DeleteSealToUserPage from "./pages/DeleteSealToUser"
import UploadSealToUserPage from "./pages/UploadSealToUser"
import { useAuth } from "./Auth"
import { State } from "./state/store"
import {
  hideModalAction,
  hideToastAction
} from "./state/ducks/notifications/actions"
import Spinner from "./components/Spinner"
import { useIntl } from "react-intl"
import { managerSelector, isSetSelector } from "./state/ducks/user/selectors"
import DashboardPage from "./pages/Dashboard"

const App: React.FC = () => {
  const intl = useIntl()
  const { isAuthenticated } = useAuth()
  const dispatch = useDispatch()
  const token = useSelector((state: State) => state.authReducer.token)
  const notification = useSelector((state: State) => state.notificationsReducer)
  const errorState = useSelector((state: State) => state.errorReducer)
  const isSet = useSelector(isSetSelector)
  const manager = useSelector(managerSelector)
  const onClose: () => void = () => dispatch(hideToastAction())

  if (!(isAuthenticated && token && isSet)) {
    return <Spinner />
  }

  /*
  */
  if (manager) {
    //console.log("######### Call to register manager:", manager)
    return <RegistManagerPage {...manager} />
  }

  return (
    <React.Fragment>
      <Header />
      {notification.isErrorModalDisplayed && (
        <ErrorModal
          isDisplayed={notification.isErrorModalDisplayed}
          onClose={() => dispatch(hideModalAction())}
          title={notification.title}
          body={notification.body}
          count={notification.count}
        />
      )}
      {notification.isModalDisplayed && (
        <Modal
          isDisplayed={notification.isModalDisplayed}
          onClose={() => dispatch(hideModalAction())}
          title={notification.title}
          body={notification.body}
        />
      )}
      {notification.isDisplayed && (
        <Toast
          isDisplayed={notification.isDisplayed}
          onClose={onClose}
          body={notification.body}
          type={notification.type}
          param={notification.param}
          resource={notification.resource}
        />
      )}
      <Container
        fluid
        className="px-3"
        css={css`
          height: calc(100% - (56px + 21px));
        `}>
        {errorState.hasError && (
          <div>
            <h2>{intl.formatMessage({ id: "error-page-1" })}</h2>
            <h3>{intl.formatMessage({ id: "error-page-2" })}</h3>
            <h3>{intl.formatMessage({ id: "error-page-3" })}</h3>
          </div>
        )}
        {!errorState.hasError && (
          <Switch>
            <Route exact path="/" component={ListAppPage} />
            <Route exact path="/apps/:appCode" component={DashboardPage} />
            <Route exact path="/apps/:appCode/system_setting" component={DetailAppPage} />
            <Route
              exact
              path="/apps/:appCode/events"
              component={ListEventPage}
            />
            <Route
              exact
              path="/apps/:appCode/events/:eventId"
              component={DetailEventPage}
            />
            <Route
              exact
              path="/apps/:appCode/assets"
              component={ListAssetPage}
            />
            <Route
              exact
              path="/apps/:appCode/add_asset"
              component={AddAssetPage}
            />
            <Route
              exact
              path="/apps/:appCode/assets/:assetCode"
              component={DetailAssetPage}
            />
            <Route
              exact
              path="/apps/:appCode/assets/:assetCode/edit"
              component={UpdateAssetPage}
            />
            <Route exact path="/profile" component={ProfilePage} />
            <Route
              exact
              path="/apps/:appCode/assets/:assetCode/add_cert"
              component={AddCertPage}
            />
            <Route
              exact
              path="/apps/:appCode/assets/:assetCode/certs/:certId"
              component={DetailCertPage}
            />
            <Route
              exact
              path="/apps/:appCode/assets/:assetCode/certs/:certId/transfer"
              component={TransferCertPage}
            />
            <Route
              exact
              path="/apps/:appCode/assets/:assetCode/certs/:certId/seal_update"
              component={UpdateSealPage}
            />
            <Route exact path="/find_cert" component={FindCertPage} />
            <Route exact path="/register" component={RegistManagerPage} />
            <Route exact path="/history" component={HistoryPage} />
            <Route
              exact
              path="/apps/:appCode/seal_to_users"
              component={ListSealToUserPage}
            />
            <Route
              exact
              path="/apps/:appCode/seal_to_users/:sealId/edit"
              component={UpdateSealToUserPage}
            />
            <Route
              exact
              path="/apps/:appCode/seal_to_users/:sealId/delete"
              component={DeleteSealToUserPage}
            />
            <Route
              exact
              path="/apps/:appCode/seal_to_users/upload"
              component={UploadSealToUserPage}
            />
          </Switch>
        )}
      </Container>
    </React.Fragment>
  )
}

export default App
