import { Action } from "typescript-fsa"
import { setErrorAction, invalidTokenAction } from "./../error/actions"
import { call, put, select, takeEvery } from "redux-saga/effects"
import {
  fetchAppsAction,
  FetchAppInfoParams,
  fetchAppInfoAction,
  FetchAssetCountParams,
  fetchAssetCountAction
} from "./actions"
import { AppInfo, AppsData } from "./types"
import { fetchAppsUsecase, fetchAppInfoUsecase, fetchAssetCountUsecase } from "./usecase"
import { QueryParams } from "../../../types"

function* fetchAppsSaga(action: Action<QueryParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    const apps: AppsData = yield call(fetchAppsUsecase, token, action.payload)
    yield put(
      fetchAppsAction.done({
        result: apps,
        params: {}
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(fetchAppsAction.failed(e))
    if (e.response.data.status > 499) {
      yield put(setErrorAction({ hasError: true }))
    }
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

function* fetchAppInfoSaga(action: Action<FetchAppInfoParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    const appInfo: AppInfo = yield call(
      fetchAppInfoUsecase,
      token,
      action.payload.appCode
    )
    yield put(
      fetchAppInfoAction.done({
        params: action.payload,
        result: appInfo
      })
    )
  } catch (e) {
    // TODO: error handling
    console.log(e)
    yield put(fetchAppInfoAction.failed(e))
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

function* fetchAssetCountSaga(action: Action<FetchAssetCountParams>){
  try {
    const token: string = yield select(state => state.authReducer.token)
    const assetCount = yield call(fetchAssetCountUsecase, token, action.payload.appCode)

    yield put(fetchAssetCountAction.done({
      params: action.payload,
      result: {
        count: assetCount
      }
    }))
  } catch (e) {
    // TODO: error handling
    yield put(fetchAssetCountAction.failed(e))
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

const sagas = [
  takeEvery(fetchAppsAction.started, fetchAppsSaga),
  takeEvery(fetchAppInfoAction.started, fetchAppInfoSaga),
  takeEvery(fetchAssetCountAction.started, fetchAssetCountSaga)
]

export default sagas
