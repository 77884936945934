import React, { useEffect } from "react"
import { RouteComponentProps } from "react-router"
import { Button, Col, Row } from "react-bootstrap"
import { fetchEventsAction } from "../state/ducks/events/actions"
import { useDispatch, useSelector } from "react-redux"
import { State } from "../state/store"
import EventTable from "../components/EventTable"
import { useIntl } from "react-intl"
import { LoadFunction } from "../types"
import PrevLink from "../components/PrevLink"

const ListEvent: React.FC<RouteComponentProps<{ appCode: string }>> = ({
  match,
  history
}) => {
  const { appCode } = match.params
  const dispatch = useDispatch()
  const intl = useIntl()
  const eventState = useSelector((state: State) => state.eventsReducer)

  useEffect(() => {
    dispatch(
      fetchEventsAction.started({
        appCode,
        eventType: "all",
        params: { pageNum: 10 }
      })
    )
  }, [appCode, dispatch])

  const handleLoad: LoadFunction = (params) => {
    dispatch(
      fetchEventsAction.started({
        appCode,
        eventType: "all",
        params
      })
    )
  }

  return (
    <>
      <PrevLink to={`/apps/${appCode}/system_setting`} label={intl.formatMessage({ id: "detail-app.system-setting-button" })} />
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "list-event.title" })}
          </h2>
        </Col>
      </Row>
      <EventTable
        appCode={appCode}
        events={eventState.events.data.events}
        count={eventState.events.data.count}
        handleLoad={handleLoad}
        isLoaded={eventState.events.isLoaded}
      />
      <Row>
        <Col sm={{ span: 2 }}>
          <Button variant="outline-secondary" onClick={() => history.goBack()}>
            {intl.formatMessage({ id: "back-button" })}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ListEvent
