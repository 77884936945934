import { HistoriesData } from "./types"
import { State } from "./../../store"
import { CertsState } from "./reducer"

export const certsStateSelector = (state: State): CertsState =>
  state.certsReducer

export const historySelector = (state: State): HistoriesData =>
  state.certsReducer.history.isLoaded 
    ? state.certsReducer.history.data 
    : {histories: [], count: 0} 

export const historyIsLoadedSelector = (state: State): boolean =>
  state.certsReducer.history.isLoaded

export const historyCountSelector = (state: State) => state.certsReducer.historyCounts
