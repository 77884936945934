/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React, { useEffect, useState } from "react"
import {
  fetchCertsHistoryAction,
  downloadCSVAction
} from "../state/ducks/certs/actions"
import { HistoryFilter } from "../state/ducks/certs/types"
import { useDispatch, useSelector } from "react-redux"
import {
  historySelector,
  historyIsLoadedSelector
} from "../state/ducks/certs/selectors"
import CertHistoryTable from "../components/CertHistoryTable"
import { Row, Col, Form, Button } from "react-bootstrap"
import { useIntl } from "react-intl"
import { LoadFunction } from "../types"
import DatePicker from "../components/DatePicker"
import { OnDatesChangeProps, FocusedInput } from "@datepicker-react/styled"
import { fetchAssetsAction } from "../state/ducks/assets/actions"
import { fetchAppsAction } from "../state/ducks/apps/actions"
import { State } from "../state/store"
import { App } from "../state/ducks/apps/types"
import { Asset } from "../state/ducks/assets/types"
import { RouteComponentProps } from "react-router"
import { marshalQueryParams } from "../util"

const MAX_CSV_EXPORT_COUNT = 20000

const AppList = (apps: App[]) => {
  return (
    <React.Fragment>
      {apps.map(app => (
        <option key={app.appCode} value={app.appCode}>
          {app.appName}
        </option>
      ))}
    </React.Fragment>
  )
}

const AssetList = (assets: Asset[]) => {
  return (
    <React.Fragment>
      {assets.map(asset => (
        <option key={asset.assetCode} value={asset.assetCode}>
          {asset.assetName}
        </option>
      ))}
    </React.Fragment>
  )
}

const History: React.FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch()
  const histories = useSelector(historySelector)
  const isLoaded = useSelector(historyIsLoadedSelector)
  const [filterParams, setFilterParams] = useState({} as HistoryFilter)
  const [focusedInput, setFocusedInput] = useState<FocusedInput>(null)
  const appState = useSelector((state: State) => state.appsReducer)
  const assetState = useSelector((state: State) => state.assetsReducer)
  const intl = useIntl()
  
  useEffect(() => {
    const initialParams = marshalQueryParams(location.search)
    setFilterParams(initialParams)
    dispatch(fetchAppsAction.started({ pageNum: -1 }))
    dispatch(fetchCertsHistoryAction.started({ pageNum: 10, ...initialParams }))
    initialParams.appCode && dispatch(fetchAssetsAction.started({appCode: initialParams.appCode, params: { pageNum: -1 }}))
  }, [dispatch, location.search])
  
  const isExportDisabled = histories.count === 0 || histories.count > MAX_CSV_EXPORT_COUNT
  
  const handleLoad: LoadFunction = params => {
    dispatch(fetchCertsHistoryAction.started({ ...params, ...filterParams }))
  }
  const handleDownLoad = async () => {
    dispatch(downloadCSVAction.started(filterParams))
  }

  const handleDateChange = (e: OnDatesChangeProps) => {
    setFocusedInput(e.focusedInput)
    setFilterParams({
      ...filterParams,
      from: e.startDate,
      to: e.endDate
    })
  }

  const handleAppChange = (e: any) => {
    e.persist()

    setFilterParams({
      ...filterParams,
      appCode: e.target.value,
      assetCode: ""
    })

    if (e.target.value !== "*") {
      dispatch(
        fetchAssetsAction.started({
          appCode: e.target.value,
          params: { pageNum: -1 }
        })
      )
    }
  }

  const handleChange = async (e: any) => {
    e.persist()

    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value
    })
  }

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleLoad({ pageNum: 10 })
  }

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "transaction-history" })}
          </h2>
        </Col>
      </Row>
      <Row>
        <Form onSubmit={submit} className="ml-3">
          <Row
            className="my-4 p-0"
            css={css`
              max-width: 1024px;
              .filter-option {
                padding: 0px;
                margin: 0px;
                margin-left: 15px;

                select {
                  width: 120px;
                  height: 35px;
                }

                .col {
                  width: 120px;
                }
              }

              .date-picker-col {
                width: 100%;
                margin-left: 15px;
                padding: 0px;
                label {
                  height: 35px;
                }

                input {
                  width: 190px;
                  padding: 0px;
                  padding-left: 40px;
                  border-radius: 5px;
                }
              }
              .col {
                max-width: fit-content;
              }
            `}
          >
            <Col className="ml-3 filter-option">
              <Form.Control
                as="select"
                name="type"
                onChange={handleChange}
                aria-label="type-filter"
                id="type-filter"
              >
                <option key="all" value="*">
                  {intl.formatMessage({ id: "history-filter.all-type" })}
                </option>
                <option key="issue" value="issue">
                  {intl.formatMessage({ id: "history-filter.issue" })}
                </option>
                <option key="transfer" value="transfer">
                  {intl.formatMessage({ id: "history-filter.transfer" })}
                </option>
                <option key="update_seal" value="update_seal">
                  {intl.formatMessage({ id: "history-filter.activate" })}
                </option>
              </Form.Control>
            </Col>
            <Col className="filter-option">
              <Form.Control
                as="select"
                name="appCode"
                value={filterParams.appCode}
                onChange={handleAppChange}
                aria-label="app-filter"
                id="app-filter"
              >
                <option key="all" value="*">
                  {intl.formatMessage({ id: "history-filter.all-apps" })}
                </option>
                {AppList(appState.data.apps)}
              </Form.Control>
            </Col>
            <Col className="filter-option">
              <Form.Control
                as="select"
                name="assetCode"
                disabled={!filterParams.appCode}
                onChange={handleChange}
                aria-label="asset-filter"
                id="asset-filter"
              >
                <option key="all" value="*">
                  {intl.formatMessage({ id: "history-filter.all-assets" })}
                </option>
                {AssetList(assetState.data.assets)}
              </Form.Control>
            </Col>
            <Col className="date-picker-col">
              <DatePicker
                filterParams={filterParams}
                handleDateChange={handleDateChange}
                focusedInput={focusedInput}
                setFocusedInput={setFocusedInput}
              />
            </Col>
            <Col>
              <Button
                className="submit-button"
                type="submit"
                disabled={!isLoaded}
              >
                {intl.formatMessage({ id: "history-filter.submit-button" })}
              </Button>
            </Col>
          </Row>
        </Form>
        <Col className="d-flex justify-content-end align-items-center">
          <Button
            variant="outline-secondary"
            onClick={handleDownLoad}
            disabled={isExportDisabled}
          >
            {intl.formatMessage({ id: "history.csv-export-button" })}
          </Button>
        </Col>
      </Row>
      <CertHistoryTable
        histories={histories.histories}
        count={histories.count}
        handleLoad={handleLoad}
        isLoaded={isLoaded}
      />
    </React.Fragment>
  )
}

export default History
