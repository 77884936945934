import { push } from "connected-react-router"
import { call, put, select, takeEvery } from "redux-saga/effects"
import { SealToUser, SealToUsersData } from "./types"
import {
//  addSealToUserUsecase,
  fetchSealToUsersUsecase,
  updateSealToUserUsecase,
  deleteSealToUserUsecase,
  uploadSealToUserCsvUsecase
} from "./usecase"
import { Action } from "typescript-fsa"
import {
//  AddSealToUserParams,
  FetchSealToUsersParams,
  UpdateSealToUserParams,
  DeleteSealToUserParams,
  UploadSealToUserCsvParams,
//  addSealToUserAction,
  fetchSealToUsersAction,
  updateSealToUserAction,
  deleteSealToUserAction,
  uploadSealToUserCsvAction
} from "./actions"
import { displayToastAction } from "../notifications/actions"
import { displayErrorModalAction } from "../notifications/actions"

import { invalidTokenAction } from "../error/actions"

function* fetchSealToUsersSaga(action: Action<FetchSealToUsersParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    const sealToUsers: SealToUsersData = yield call(fetchSealToUsersUsecase, token, action.payload.appCode, action.payload.params)

    yield put(
      fetchSealToUsersAction.done({
        params: action.payload,
        result: sealToUsers
      })
    )
  } catch (e) {
    yield put(fetchSealToUsersAction.failed(e))
    if (e.response.data.code === "invalid_token") {
      yield put(invalidTokenAction())
    }
  }
}

/**
 * シール-ユーザデータの更新
 * @param action アクション
 */
function* updateSealToUserSaga(action: Action<UpdateSealToUserParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    // call で非同期処理を呼び出し、終了まで待つ
    const sealToUser: SealToUser = yield call(
      updateSealToUserUsecase,
      token,
      action.payload.appCode,
      action.payload.data
    )

    // put で次の Action をdispatchする
    yield put(
      updateSealToUserAction.done({
        params: action.payload,
        result: {
          ...sealToUser,
//          appCode: action.payload.appCode
        }
      })
    )
    // 一覧へ戻る
    yield put(push(`/apps/${action.payload.appCode}/seal_to_users`))
    yield put(
      displayToastAction({
        type: "success",
        code: "saved"
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(updateSealToUserAction.failed(e))
    yield put(
      displayToastAction({
        type: "error",
        code: `api-error.${e.response.data.code}${
          e.response.data.subCode ? "_" + e.response.data.subCode : ""
        }`,
        param: e.response.data.param,
        resource: e.response.data.resource
      })
    )
  }
}

/**
 * シール-ユーザデータの削除
 * @param action アクション
 */
function* deleteSealToUserSaga(action: Action<DeleteSealToUserParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    const sealToUser: SealToUser = yield call(
      deleteSealToUserUsecase,
      token,
      action.payload.appCode,
      action.payload.data
    )

    yield put(
      deleteSealToUserAction.done({
        params: action.payload,
        result: {
          ...sealToUser,
//          appCode: action.payload.appCode
        }
      })
    )
    // 一覧表示へ戻る
    yield put(push(`/apps/${action.payload.appCode}/seal_to_users`))
    yield put(
      displayToastAction({
        type: "success",
        code: "deleted"
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(deleteSealToUserAction.failed(e))
    yield put(
      displayToastAction({
        type: "error",
        code: `api-error.${e.response.data.code}${
          e.response.data.subCode ? "_" + e.response.data.subCode : ""
        }`,
        param: e.response.data.param,
        resource: e.response.data.resource
      })
    )
  }
}

/**
 * シール-ユーザデータのCSVアップロード
 * @param action アクション
 */
function* uploadSealToUserCsvSaga(action: Action<UploadSealToUserCsvParams>) {
  try {
    // stateから値を取り出す
    const token: string = yield select(state => state.authReducer.token)
    const vendorId: string = yield select(
      state => state.vendorReducer.vendor.vendorId
    )

    const { appCode, file } = action.payload
    // call で非同期処理を呼び出し、終了まで待つ
    const sealToUsers = yield call(
      uploadSealToUserCsvUsecase,
      token,
      vendorId,
      appCode,
      file
    )
    
    // console.log(sealToUsers)
    // put で次の Action をdispatchする
    yield put(
      uploadSealToUserCsvAction.done({
        params: action.payload,
        result: sealToUsers
      })
    )
    // 一覧表示へ戻る
    yield put(push(`/apps/${action.payload.appCode}/seal_to_users`))

    const errorStr = sealToUsers.errors.join("\n")
    if (sealToUsers.errors.length > 0) {
      // 登録失敗があるとき
      yield put(
        displayErrorModalAction({
          title: "seal-management.upload_result",
          body: errorStr,
          count: sealToUsers.count
        })
      )
    } else {
      // すべて成功したとき
      yield put(
        displayErrorModalAction({
          title: "seal-management.upload_result",
          count: sealToUsers.count
        })
      )
    }
    // TODO DB登録
  } catch (e) {
    // TODO: error handling
    yield put(uploadSealToUserCsvAction.failed(e))
    yield put(
      displayToastAction({
        type: "error",
        code: `api-error.${e.response.data.code}${
          e.response.data.subCode ? "_" + e.response.data.subCode : ""
        }`,
        param: e.response.data.param,
        resource: e.response.data.resource
      })
    )
  }
}

/*
function* addSealToUserSaga(action: Action<AddSealToUserParams>) {
  try {
    const token: string = yield select(state => state.authReducer.token)
    const sealToUser: SealToUser = yield call(
      addSealToUserUsecase,
      token,
      action.payload.appCode,
      action.payload.data
    )

    yield put(
      addSealToUserAction.done({
        params: action.payload,
        result: {
          ...sealToUser,
//          appCode: action.payload.appCode
        }
      })
    )
    yield put(push(`/apps/${action.payload.appCode}/sealToUsers`))
    yield put(
      displayToastAction({
        type: "success",
        code: "saved"
      })
    )
  } catch (e) {
    // TODO: error handling
    yield put(addSealToUserAction.failed(e))
    yield put(
      displayToastAction({
        type: "error",
        code: `api-error.${e.response.data.code}${
          e.response.data.subCode ? "_" + e.response.data.subCode : ""
        }`,
        param: e.response.data.param,
        resource: e.response.data.resource
      })
    )
  }
}
*/

const sagas = [
  takeEvery(fetchSealToUsersAction.started, fetchSealToUsersSaga),
//  takeEvery(addSealToUserAction.started, addSealToUserSaga),
  takeEvery(updateSealToUserAction.started, updateSealToUserSaga),
  takeEvery(deleteSealToUserAction.started, deleteSealToUserSaga),
  takeEvery(uploadSealToUserCsvAction.started, uploadSealToUserCsvSaga)
]

export default sagas
