import { axiosWithToken, convertQueryParams } from "../../../util"
import { SealToUser, SealToUsersData } from "./types"
import { AxiosResponse } from "axios"
import { QueryParams } from "../../../types"

export const fetchSealToUserUsecase = async (
  token: string,
  appCode: string,
  sealId: string
): Promise<SealToUser> => {
  // TODO: error handling
  const res = await axiosWithToken(token).get(
    `/sealToUsers/${sealId}?appCode=${appCode}`
  )
  return {
    ...res.data,
    appCode
  }
}

export const fetchSealToUsersUsecase = async (
  token: string,
  appCode: string,
  params: QueryParams,
): Promise<SealToUsersData> => {
  // TODO: error handling
  const queryParams = convertQueryParams(params)
  const res: AxiosResponse<SealToUsersData> | void = await axiosWithToken(token).get(
    `/sealToUsers?appCode=${appCode}&${queryParams}`
  )

  if(!res) {
    return {
      sealToUsers: [],
      count: 0,
      errors: []
    }
  }

  res.data.sealToUsers = res.data.sealToUsers.map((sealToUser: SealToUser) => {
    return {
      ...sealToUser,
      appCode
    }
  })
  return res.data
}

export const addSealToUserUsecase = async (
  token: string,
  appCode: string,
  data: {
  }
) => {
  const res = await axiosWithToken(token).post(
    `/sealToUsers/csv?appCode=${appCode}`,
    data
  )
  return res.data
}

export const updateSealToUserUsecase = async (
  token: string,
  appCode: string,
  data: {
    sealId: string
    userId: string
  }
) => {
  const res = await axiosWithToken(token).put(
    `/sealToUsers/${data.sealId}?appCode=${appCode}`,
    data
  )
  return res.data
}

export const deleteSealToUserUsecase = async (
  token: string,
  appCode: string,
  data: {
    sealId: string
  }
) => {
  const res = await axiosWithToken(token).delete(
    `/sealToUsers/${data.sealId}?appCode=${appCode}`
  )
  return res.data
}

/**
 * シール-ユーザデータをCSVとしてアップロードする。
 * @param token トークン
 * @param vendorId ベンダーID
 * @param appCode アプリコード
 * @param file アップロードするファイル
 */
export const uploadSealToUserCsvUsecase = async (
  token: string,
  vendorId: string,
  appCode: string,
  file: Blob
) => {
  const formData = new FormData()

  formData.append("vendorId", vendorId)
  formData.append("appCode", appCode)
  formData.append("file", file)

  // APIを呼び出す
  const res = await axiosWithToken(token).post(
    `/sealToUsers/csv?appCode=${appCode}`,
    formData,
    {
      headers: {
        "content-type": "multipart/form-data"
      }
    }
  )
  //console.log(res)
  return res.data
}