import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { State } from "../state/store"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import {
  uploadSealToUserCsvAction
} from "../state/ducks/seal_to_users/actions"
import { useIntl } from "react-intl"
import { appSelector } from "../state/ducks/apps/selectors"
import { fetchAppsOps } from "../state/ducks/apps/operations"
import PrevLink from "../components/PrevLink"

// シール-ユーザデータCSVのアップロード
const UploadSealToUserPage: React.FC<RouteComponentProps<{
  appCode: string
}>> = ({ match, history }) => {
  const { appCode } = match.params
  const app = useSelector(appSelector(appCode))

  const dispatch = useDispatch()
  const intl = useIntl()
  const sealToUserState = useSelector((state: State) => state.sealToUsersReducer)

//  const [errors, setErrors] = useState([] as string[])
//  const [count, setCount] = useState(0)
//  const [isTableShown, setIsTableShown] = useState(false)

  const [uploadCsv, setUploadCsv] = useState<Blob | null>(null)

  /*
  useEffect() は、クラスコンポーネントの以下メソッドが持つ役割をまとめたもの。
  componentDidMount
  componentDidUpdate
  componentWillUnmount
  第１引数(関数指定)
    componentDidMount componentDidUpdate で行っていた処理を指定する。
    関数の戻り値( return )の指定は任意。
    戻り値( return )で指定した処理が、コンポーネントの終了時に実行される。
    ( componentWillUnmount に相当 )
  第２引数(配列指定)
  第１引数で指定した関数の実行タイミングを調整
  指定しない場合、初回レンダー時 と 毎回の更新時 に実行される。
  空配列で指定した場合( [] )
  初回レンダー時 のみ実行される。
  特定stateを指定した場合( e.g. [state1, state3] )
  初回レンダー時 と 指定stateの更新時 に実行される。
 */
  useEffect(() => {
//    console.log(sealToUserState)
    dispatch(fetchAppsOps({}))
//    setErrors(sealToUserState.data.errors || [])
//    setCount(sealToUserState.data.count || 0)

  }, [appCode, sealToUserState, dispatch])

  if (!app) {
    // TODO: 見せ方を考える
    return <h1>{intl.formatMessage({ id: "app-notfound" })}</h1>
  }

  const handleSubmit = (e: React.FormEvent<Element>) => {
    e.preventDefault()

    if (uploadCsv) {
      dispatch(
        uploadSealToUserCsvAction.started({
          appCode,
          file: uploadCsv
        })
      )
    }
//    setIsTableShown(true)
  }

  const handleChange = (e: any): void => {
    setUploadCsv(e.target.files[0])
  }

  return (
    <React.Fragment>
      <PrevLink to={`/apps/${appCode}/seal_to_users`} label={intl.formatMessage({ id: "detail-app.seal-management-button" })} />
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "seal-management" })}: {app?.appName}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
            <Form>
                <Form.Group controlId="formFile1">
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={handleChange}
                    accept="text/csv"
                  />
                </Form.Group>
                <Row>
                  <Col xs={16} md={7}>
                    <Button
                      variant="success"
                      onClick={handleSubmit}>
                      {intl.formatMessage({ id: "upload-button" })}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Button
        className="my-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}
      >
        {intl.formatMessage({ id: "back-button" })}
      </Button>
    </React.Fragment>
  )
}

export default UploadSealToUserPage
