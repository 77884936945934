import { axiosWithToken, convertQueryParams } from "./../../../util"
import { Event, EventsData } from "./types"
import { QueryParams } from "../../../types"
import { AxiosResponse } from "axios"

export const fetchEventUsecase = async (
  token: string,
  id: string
): Promise<Event> => {
  // TODO: error handling
  const res = await axiosWithToken(token).get(
    `/webhooks/requests/${id}?appCode=*`
  )

  return res.data
}

export const fetchEventsUsecase = async (
  token: string,
  appCode: string,
  eventType: string,
  params: QueryParams
): Promise<EventsData> => {
  // TODO: error handling
  const queryParams = convertQueryParams(params)
  const res: AxiosResponse<EventsData> | void = await axiosWithToken(token).get(
    `/webhooks/requests?appCode=${appCode}&eventType=${eventType}&${queryParams}`
  )

  if(!res) {
    return {
      events: [],
      count: 0
    }
  }

  return res.data
}
