import { reducerWithInitialState } from "typescript-fsa-reducers"
import { fetchEventAction, fetchEventsAction } from "./actions"
import { Event, EventsData, Events } from "./types"

export type EventsState = {
  event: {
    isLoaded: boolean
    data: Event
  }
  events: {
    isLoaded: boolean
    data: EventsData
  }
}

const initialState = {
  event: { 
    isLoaded: false,
    data: {}
   },
  events: { 
    isLoaded: false,
    data: {
      events: [] as Events,
      count: 0
    }
  }
} as EventsState

const fetchEventStarted = (
  state: EventsState,
  action: ReturnType<typeof fetchEventAction.started>
): EventsState => {
  return {
    ...state,
    event: { ...state.event ,isLoaded: false }
  }
}

const fetchEventDone = (
  state: EventsState,
  action: ReturnType<typeof fetchEventAction.done>
): EventsState => {
  return {
    ...state,
    event: {
      isLoaded: true,
      data: action.payload.result
    }
  }
}

const fetchEventsStarted = (
  state: EventsState,
  action: ReturnType<typeof fetchEventsAction.started>
): EventsState => {
  return {
    ...state,
    events: { ...state.events ,isLoaded: false }
  }
}

const fetchEventsDone = (
  state: EventsState,
  action: ReturnType<typeof fetchEventsAction.done>
): EventsState => {
  return {
    ...state,
    events: {
      isLoaded: true,
      data: action.payload.result
    }
  }
}

const reducer = reducerWithInitialState(initialState)
  .caseWithAction(fetchEventsAction.started, fetchEventsStarted)
  .caseWithAction(fetchEventsAction.done, fetchEventsDone)
  .caseWithAction(fetchEventAction.started, fetchEventStarted)
  .caseWithAction(fetchEventAction.done, fetchEventDone)

export default reducer
